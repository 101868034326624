import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

const BASE_URL = '/DipteVoceFissa'

class API {
    @call({ url: `${BASE_URL}/GetDipteVoceFissa` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponsePayload<DipteVoceFissa>> | null> {
        return null
    }
}

export default {
    get: API.get,
}
