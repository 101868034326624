import { ProfiliMenuLista } from '@/views/Paghe/Admin/ProfiliMenu/Lista'
import { ProfiliMenuDettaglio, Childs } from '@/views/Paghe/Admin/ProfiliMenu/Dettaglio'

const tabProsDef: TabProps = {
    GROUP: 'profiliMenu',
    ICON: 'fa-regular fa-user-shield',
    TITLE: 'Profili menù',
    CODVOCEMENU: '900005',
}

export const ROUTES_PROFILI_MENU = Object.freeze({
    LISTA: {
        // TITLE: 'Elenco profili menù',
        NAME: 'ProfiliMenuLista',
        PATH: 'profiliMenu',
        TAB: { ...tabProsDef },
    },
    DETTAGLIO: {
        NAME: 'ProfiliMenuDettaglio',
        PATH: 'profiliMenu/:id/:mod',
    },
    GENERALE: {
        LABEL: 'Profilo',
        NAME: 'ProfiloMenu',
        PATH: 'profilo',
        TAB: { ...tabProsDef, TITLE: 'Definizione profilo' },
    },
})

export default [
    {
        path: ROUTES_PROFILI_MENU.LISTA.PATH,
        name: ROUTES_PROFILI_MENU.LISTA.NAME,
        component: ProfiliMenuLista,
        meta: {
            title: ROUTES_PROFILI_MENU.LISTA.TITLE,
            permissions: {
                codPermesso: 'AMMINISTRAZIONE',
                codVoceMenu: tabProsDef.CODVOCEMENU,
            },
            tabGroup: tabProsDef.GROUP,
        },
    },
    {
        path: ROUTES_PROFILI_MENU.DETTAGLIO.PATH,
        name: ROUTES_PROFILI_MENU.DETTAGLIO.NAME,
        props: true,
        component: ProfiliMenuDettaglio,
        meta: {
            title: ROUTES_PROFILI_MENU.DETTAGLIO.TITLE,
            permissions: {
                codPermesso: 'AMMINISTRAZIONE',
                codVoceMenu: null,
            },
            tabGroup: tabProsDef.GROUP,
        },
        children: [
            {
                label: ROUTES_PROFILI_MENU.GENERALE.LABEL,
                path: ROUTES_PROFILI_MENU.GENERALE.PATH,
                name: ROUTES_PROFILI_MENU.GENERALE.NAME,
                tab: ROUTES_PROFILI_MENU.GENERALE.TAB,
                component: Childs.ProfiloMenu,
                meta: {
                    group: ROUTES_PROFILI_MENU.DETTAGLIO.NAME,
                    permissions: {
                        codPermesso: 'AMMINISTRAZIONE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabProsDef.GROUP,
                },
                orderWizard: 0,
                props: true,
            },
        ],
    },
]
