import { ref, reactive, Ref, shallowRef, computed, shallowReactive, ShallowReactive } from 'vue'

import { useGlobalStore } from '@/stores'

import moment from 'moment'
import dateUty, { toMoment } from '@/scripts/services/date'
import { pagheAPI } from '@/api'
import { get } from 'lodash'
import { useUtils } from '@/composables'
import { pagheRoutes } from '@/routes'
import { useRoute } from 'vue-router'

function baseRisultatiDettaglioState(isCollaboratore: boolean = false) {
    const utils = useUtils()
    const globalStore = useGlobalStore()
    const route = useRoute()

    const basePeriodoDal = dateUty
        .toMoment(`01/${globalStore.state.periodoElab?.year}`)
        ?.startOf('month')
    const basePeriodoAl = dateUty
        .toMoment(`${globalStore.state.periodoElab?.month}/${globalStore.state.periodoElab?.year}`)
        ?.endOf('month')

    const activeChildName: Ref<string | null> = shallowRef(null) //Nome del dettaglio attivo
    const dataLookup = reactive(new Map()) //Dati delle lookup (ref poiche con shallowRef non aggiorna le lookup)

    const state = reactive({
        data: null,
        routeList: isCollaboratore
            ? pagheRoutes.risultati.dettaglioCo.SETTINGS.COLLABORATORE_LISTA
            : pagheRoutes.risultati.dettaglioDp.SETTINGS.DIPENDENTE_LISTA,
    })

    const datiDitta = shallowRef()

    const dataHeader = reactive({
        title: null,
        subtitle: null,
        //codQualifica: null,
        //codPosizione: null,
    })

    const selectedPeriod: Ref<string | undefined> = ref()
    initSelectedPeriod()

    //TODO DA SEMPLIFICARE (TOGLIERE L'ANNIDAZIONE SE POSSIBILE)
    const dataChild = shallowReactive({
        name: null, //Nome del dettaglio a cui appartengono i dati in dataChild
        origin: null, //Dati da ripristinare al cambio TAB
        extraData: null,
        codMultiCc: 'TOTALE', //per tutte le pagine dei risultati
    })

    // Periodo dal = 01/anno di PeriodoElaborazione.DataPeriodoElaborazione
    // Periodo al = mese/anno di PeriodoElaborazione.DataPeriodoElaborazione.
    // Dettaglio multi cantiere/centro di costo / fondo = FALSE
    // Considera precedenti rapporti, da preimpostare a FALSE
    const parametri: ShallowReactive<StateParametri> = shallowReactive({
        cantiereCC: false,
        periodoDal: dateUty.toISO(basePeriodoDal),
        periodoAl: dateUty.toISO(basePeriodoAl),
        precRapporti: false,
    })

    const isNote = ref(false) //abilitazione - Note

    const isDiario = computed(() => {
        return diarioStudio.value && diarioDitta.value
    })

    const diarioStudio = reactive({ downloaded: false, value: false })
    const diarioDitta = reactive({ value: false, downloaded: false }) //varia in base alla variazione dei parametri

    //DATI GRIGLIA
    const _grid = ref({}) //Dati della griglia da ripristinare al cambio tab (utilizzato dalla composable baseTableEdit ma puo essere usato anche senza)
    const localTab = ref(0) //Indica il subTab attivo nella pagina

    //#region GETTERs
    const getPeriodo = computed(() => {
        return globalStore.state.periodoElab || dateUty.toObject(moment())
    })

    const dataChildFilled = computed(() => {
        return !!dataChild.origin && dataChild.name === activeChildName.value
    })

    const getNoteContext = computed(() => ({
        entityId: state.data?.id,
        entityType: 'DIPTE',
        isNote,
        title: `${isCollaboratore ? 'collaboratore' : 'dipendente'}: ${state.data!.codice} - ${state.data!.cognome} ${state.data!.nome}`,
        vCodDes: isCollaboratore ? 'TAG-ALERT-COLL' : 'TAG-ALERT-DIPTE',
    }))

    const getPeriodi = computed(() => {
        const startDate = toMoment(parametri.periodoDal)
        const endDate = toMoment(parametri.periodoAl)

        const months: Array<string> = []

        if (!startDate || !endDate) return months

        while (!startDate.isAfter(endDate, 'month')) {
            months.push(startDate.endOf('month').startOf('day').format('YYYY-MM-DD[T]HH:mm:ss'))
            startDate.add(1, 'month')
        }

        return months
    })

    const getSelectedPeriod = computed(() => selectedPeriod.value)
    //#endregion

    //#region ACTIONs - FUNZIONI

    function setSelectedPeriod(value: string | undefined) {
        if (selectedPeriod.value != value) selectedPeriod.value = value
    }

    function initSelectedPeriod() {
        if (route.meta.group === 'DipendenteRisultatiDettaglio' && route.params) {
            selectedPeriod.value = `${route.params.month}/${route.params.year} - ME`
        }
    }

    async function getData(props: DipendenteProps) {
        getAbilitazioneDiario(props)

        const periodoDiRiferimento = {
            year: Number(props.year),
            month: Number(props.month),
            day: Number(props.day),
        }

        const params: DiptePayload = {
            requireHasFutureValidity: false,
            periodoDiRiferimento,
            filter: `['id', '=', '${props.id}']`,
            select: JSON.stringify([
                'id',
                'codice',
                'cognome',
                'matricola',
                'nome',
                'codiceDitta',
                'containsDipteNoteCalc',
                'ditta.codiceDitta',
                'ditta.dittaGenerale.tipoDitta',
                //'ditta.dataPeriodoElaborazione',
                //'ditta.dittaUPOpzione.codMultiCantiereCCosto',
                'dipteUnitaProduttiva.dittaUP.unitaProduttiva',
                'dipteUnitaProduttiva.dittaUP.codiceDitta',
                'dipteUnitaProduttiva.dittaUP.dittaUPRagioneSociale.codice',
                'dipteUnitaProduttiva.dittaUP.dittaUPRagioneSociale.ragioneSociale',
                'dipteUnitaProduttiva.dittaUP.dittaUPRagioneSociale.denominazioneAggiuntiva',
                'dipteUnitaProduttiva.dittaUP.dittaUPTabellaAttivaList.*', //per i dati figlia o padre
                //'dipteUnitaProduttiva.dittaUP.dataPeriodoElaborazione',
                //'dipteUnitaProduttiva.dittaUP.dittaUPOpzione.codMultiCantiereCCosto', //puo essere del padre
            ]),
        }

        try {
            const response = await pagheAPI.anagrafiche.dipendenti.dipte.get(params)
            const { responseStatus, data } = response.data
            if (responseStatus.isSuccessful && data?.length) {
                const codice = data?.[0].codice ? data[0].codice : ''
                const cognome = data?.[0].cognome ? data[0].cognome : ''
                const nome = data?.[0].nome ? data[0].nome : ''

                //NON DOVREBBERO CAMBIARE CON LA NAVIGAZIONE IN ORIZZONTALE E VERTICALE
                datiDitta.value = {
                    ditta: data[0].ditta,
                    dittaUP: data[0].dipteUnitaProduttiva.dittaUP,
                }

                //TODO: getter che prende da state.data oppure no ?
                dataHeader.title = [codice, `${cognome} ${nome}`]
                dataHeader.subtitle = utils.ragSocialeFromData(
                    data[0].dipteUnitaProduttiva.dittaUP,
                    true,
                )
                isNote.value = !!data?.[0]?.containsDipteNoteCalc
                state.data = data?.[0]
            }
        } catch (err) {
            console.error('Errore durante la chiamata: ', err)
        }
    }

    // function getCodMultiCantiereCCosto(sede: boolean = false): number | null {
    //     let result = null
    //     if (sede) {
    //         result = get(datiDitta.value, 'ditta.dittaUPOpzione.codMultiCantiereCCosto', null)
    //     } else {
    //         result = get(
    //             datiDitta.value,
    //             'dittaUP.dittaUPOpzione.codMultiCantiereCCosto',
    //             get(datiDitta.value, 'ditta.dittaUPOpzione.codMultiCantiereCCosto', null),
    //         )
    //     }

    //     return result ? +result : null
    // }

    const getTipoDitta = computed(() => {
        return state?.data?.ditta?.dittaGenerale?.tipoDitta
    })

    function resetActiveChildName() {
        //TODO: mettere nome prima pagina del elenco
        activeChildName.value = isCollaboratore
            ? pagheRoutes.risultati.dettaglioCo.SETTINGS.COLLABORATORE_RISULTATI.NAME
            : pagheRoutes.risultati.dettaglioDp.SETTINGS.DIPENDENTE_RISULTATI.NAME
    }

    function resetBaseData(
        resetLookup: boolean = true,
        oNavigation: boolean = false,
        vNavigation: boolean = false,
    ) {
        dataChild.name = null
        dataChild.origin = null
        if (resetLookup) {
            dataLookup.clear()
        }
        if (!oNavigation) {
            //operazioni da fare solo se non si sta navigando
            localTab.value = 0
            dataChild.extraData = null //solo se non sto navigando
            if (!vNavigation) {
                initParametri() //in navigazione non vanno resettati
            }
        }
        if (!vNavigation) {
            //DIVERSO DIPTE, IN TUTTI I CASI TRANNE IN VERTICALE
            dataChild.codMultiCc = 'TOTALE'
        }
    }

    function resetPageData(
        resetLookup: boolean = true,
        oNavigation: boolean = false,
        vNavigation: boolean = false,
    ) {
        resetBaseData(resetLookup, oNavigation, vNavigation)
        _grid.value = {}
    }

    function initParametri() {
        parametri.cantiereCC = false
        parametri.periodoDal = dateUty.toISO(basePeriodoDal)
        parametri.periodoAl = dateUty.toISO(basePeriodoAl)
        parametri.precRapporti = false
        initSelectedPeriod()
    }

    function getPeriodoFromParametri(toSave: boolean = false): PeriodoDiRiferimento | null {
        if (parametri.periodoDal && parametri.periodoAl) {
            const periodoDal = dateUty.toObject(parametri.periodoDal)
            const periodoAl = dateUty.toObject(parametri.periodoAl)

            if (toSave && periodoAl != null) {
                return {
                    ...periodoAl,
                }
            }

            return {
                ...periodoDal,
                yearTo: periodoAl?.year,
                monthTo: periodoAl?.month,
                dayTo: periodoAl?.day,
            }
        }

        return null
    }

    /**
     * In base al periodoAl specificato nei parametri effettua la chiamata per prendere il valore di isDiario relativo alla ditta.
     * @param props pros della pagina
     * @returns
     */
    async function getDiarioDitta(props: DipendenteProps) {
        if (!parametri.periodoAl) return

        try {
            const periodoDiRiferimento = dateUty.toObject(parametri.periodoAl)
            //chiamata per aggiornare il valore del diario

            const select = ['id', 'dittaUPOpzione.isDiario']

            if (+props.dittaPadreId > 0) {
                select.push('padre.id', 'padre.dittaUPOpzione.isDiario')
            }

            const params: DittaPayload = {
                requireHasFutureValidity: false,
                periodoDiRiferimento,
                filter: `['id', '=', '${props.dittaId}']`,
                select: JSON.stringify(select),
            }

            const response = await pagheAPI.anagrafiche.ditte.ditta.get(params)
            const { responseStatus, data } = response?.data
            if (responseStatus.isSuccessful) {
                diarioDitta.downloaded = true

                diarioDitta.value =
                    +props.dittaPadreId > 0
                        ? get(
                              data[0],
                              'dittaUPOpzione.isDiario',
                              get(data[0], 'padre.dittaUPOpzione.isDiario', false),
                          )
                        : get(data[0], 'dittaUPOpzione.isDiario', false)
            }
        } catch (err) {
            console.error('Errore durante la chiamata: ', err)
        }
    }
    async function getDiarioStudio(props: any) {
        try {
            const periodoDiRiferimento = dateUty.toObject(parametri.periodoAl)
            // const periodoDiRiferimento = {
            //     year: Number(props.year),
            //     month: Number(props.month),
            //     day: Number(props.day),
            // }

            //FLAG PER GESTIONE DIARIO DELL STUDIO
            const paramsStudio = {
                periodoDiRiferimento,
                include: "['studioGeneraleList']",
                select: "['studioGeneraleList.isDiarioDatiElaborazione','id']",
            }
            const responseStudio = await pagheAPI.anagrafiche.studio.get(paramsStudio)

            if (responseStudio?.data?.responseStatus?.isSuccessful) {
                diarioStudio.downloaded = true
                diarioStudio.value =
                    responseStudio.data.data[0]?.studioGeneraleList[0]?.isDiarioDatiElaborazione ??
                    false
            }
        } catch (error) {}
    }
    async function getAbilitazioneDiario(props: DipendenteProps, forceDownload: boolean = false) {
        if (!parametri.periodoAl) return

        if (!diarioStudio.downloaded || forceDownload) {
            await getDiarioStudio(props)
        }
        //Se lo studio non ha abilitato il diario non faccio la chiamata per il diario della Ditta
        if (!diarioStudio.value || (!forceDownload && diarioDitta.downloaded)) return

        getDiarioDitta(props)
    }
    //#endregion

    return {
        activeChildName,
        dataChildFilled,
        dataChild,
        dataHeader,
        dataLookup,
        getAbilitazioneDiario,
        getData,
        getPeriodo,
        getPeriodoFromParametri,
        getPeriodi,
        getSelectedPeriod,
        getTipoDitta,
        _grid,
        //getCodMultiCantiereCCosto,
        initSelectedPeriod,
        isDiario,
        isNote,
        localTab,
        getNoteContext,
        parametri,
        state,
        resetActiveChildName,
        resetBaseData,
        resetPageData,
        setSelectedPeriod,
    }
}

export default baseRisultatiDettaglioState
