import { http } from '@/scripts/lib/http'
import { useGlobalStore } from '@/stores'

const BASE_URL = '/LogAttivita'

const payload: any = {
    requireTotalCount: false,
    requireHasFutureValidity: false,
}

export default class APILogAttivita {
    static async get(params?: any) {
        const globalStore = useGlobalStore()
        return http.post(`${BASE_URL}/GetLogAttività`, {
            ...payload,
            ...{
                periodoDiRiferimento: globalStore.state.periodoElab,
            },
            ...params,
        })
    }

    static async create(params?: any) {
        const globalStore = useGlobalStore()
        const date = new Date()
        return http.post(`${BASE_URL}/CreateLogAttività`, {
            ...payload,
            ...{
                periodoDiRiferimento: globalStore.state.periodoElab,
            },
            ...params,
        })
    }
}
