export const endpoints = {
    GetIntermediari: '/Intermediario/GetIntermediari',
    GetIntermediariUniemen: '/IntermediarioUniemen/GetIntermediariUniemen',
    CreateIntermediarioUniemen: '/IntermediarioUniemen/CreateIntermediarioUniemen',
    UpdateIntermediarioUniemen: '/IntermediarioUniemen/UpdateIntermediarioUniemen',
    CreateIntermediario: '/Intermediario/CreateIntermediario',
    UpdateIntermediario: '/Intermediario/UpdateIntermediario',
    DeleteIntermediario: '/Intermediario/DeleteIntermediario',
    GetComuni: '/Comune/GetComuni',
    GetVCodDes: '/VCodDes/GetVCodDes',
    GetVRegioneStatuto: '/VRegioneStatuto/GetVRegioneStatuto',
    GetIntermediariFiscale: '/IntermediarioFiscale/GetIntermediariFiscale',
    CreateIntermediarioFiscale: '/IntermediarioFiscale/CreateIntermediarioFiscale',
    UpdateIntermediarioFiscale: '/IntermediarioFiscale/UpdateIntermediarioFiscale',
    GetVSedeInps: '/VSedeInps/GetVSedeInps',
    GetIntermediariInailLul: '/IntermediarioInailLul/GetIntermediariInailLul',
    CreateIntermediarioInailLul: '/IntermediarioInailLul/CreateIntermediarioInailLul',
    UpdateIntermediarioInailLul: '/IntermediarioInailLul/UpdateIntermediarioInailLul',
    DeleteIntermediarioInailLul: '/IntermediarioInailLul/DeleteIntermediarioInailLul',
    GetBanche: '/Banca/GetBanche',
    GetVoci: '/Voce/GetVoci',
    UpdateVoce: '/Voce/UpdateVoce',
    CreateDitta: '/Ditta/CreateDitta',
    UpdateDitta: '/Ditta/UpdateDitta',
    GetDitte: '/Ditta/GetDitte',
    GetDipendenti: '/Dipte/GetDipendenti',
    GetIntermediarioComunicazioni: '/IntermediarioComunicazione/GetIntermediarioComunicazioni',
    GetDitteUPStatus: '/DittaUPStatus/GetDitteUPStatus',
    GetPeriodoElaborazione: '/PeriodoElaborazione/GetPeriodoElaborazione',
    DeleteDitta: '/Ditta/DeleteDitta',
    GetVMessaggio: '/VMessaggio/GetVMessaggio',
    GetVMessaggi: '/VMessaggio/GetVMessaggi',
    GetDiarioCodice: '/DiarioCodice/GetDiarioCodice',
    GetProfile: '/Profile/GetProfile',
    GetCasseEdile: '/CassaEdile/GetCasseEdile',
    SaveDittaInpsEnti: '/Ditta/SaveDittaInpsEnti',
    GetEnti: '/Ente/GetEnti',
    GetDitteBanca: '/DittaBanca/GetDitteBanca',
    SaveDittaCentroCosto: '/Ditta/SaveDittaCentroCosto',
    GetDipendentiCollocamento: '/DipteCollocamento/GetDipendentiCollocamento',
    UpdateDipendentiCollocamento: '/DipteCollocamento/UpdateDipteCollocamento',
    CreateDipendentiCollocamento: '/DipteCollocamento/CreateDipteCollocamento',
    DeleteDipte: '/Dipte/DeleteDipte',
    GetVCodiceUnilav: '/VCodiceUnilav/GetVCodiceUnilav',
    SaveDipteAnagrafica: '/DipteAnagrafica/SaveDipteAnagrafica',
    GetDipendentiAnagrafica: '/DipteAnagrafica/GetDipendentiAnagrafica',
    GetDitteUPAttivita: '/DittaUPAttivita/GetDitteUPAttivita',
    GetDittaUPEnti: '/DittaUPEnte/GetDittaUPEnti',
    UpdateDittaSostImposta: '/Ditta/UpdateDittaSostituto',
    CreateDittaSostImposta: '/Ditta/CreateDittaSostituto',
}
