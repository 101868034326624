import { defineStore } from 'pinia'
import { ref, reactive, Ref, shallowRef, computed, shallowReactive, ShallowReactive } from 'vue'

import { useGlobalStore } from '@/stores'

import moment from 'moment'
import dateUty, { toMoment } from '@/scripts/services/date'
import { pagheAPI } from '@/api'
import { get } from 'lodash'

import { pagheRoutes } from '@/routes'
import { useRoute } from 'vue-router'
import { ComputedRef } from 'vue'

import { isTableUPActive } from '@paghe/views/anagrafiche/ditte/scripts'
import { NomeTabellaAttiva } from '@paghe/entities/ditte'
import type { NoteProps } from '@/components/Note.vue'

export const storeName = 'RisultatiDittaDettaglioStore'
interface RisultatiDittaDettaglioStore {
    activeChildName: Ref<string | null>
    dataChildFilled: ComputedRef<boolean>
    dataChild: ShallowReactive<{
        name: null | string
        origin: null | any
        extraData: null | any
        codMultiCc: string
    }>
    dataHeader: {
        title: null | any
        subtitle: null | any
    }
    dataLookup: Map<any, any>
    getAbilitazioneDiario: (props: DipendenteProps, forceDownload?: boolean) => Promise<void>
    getData: (props: DipendenteProps) => Promise<void>
    getPeriodo: ComputedRef<PeriodoDiRiferimento>
    getPeriodoFromParametri: (toSave?: boolean) => PeriodoDiRiferimento | null
    getPeriodi: ComputedRef<string[]>
    getSelectedPeriod: ComputedRef<string | undefined>
    getTipoDitta: ComputedRef<string | undefined>
    _grid: Ref<any>
    initSelectedPeriod: () => void
    isDiario: ComputedRef<boolean>
    isNote: Ref<boolean>
    localTab: Ref<number>
    parametri: ShallowReactive<IStateParametri>
    state: {
        data: null | any
        routeList: string
    }
    resetActiveChildName: () => void
    resetBaseData: (resetLookup?: boolean, oNavigation?: boolean, vNavigation?: boolean) => void
    resetPageData: (resetLookup?: boolean, oNavigation?: boolean, vNavigation?: boolean) => void
    setSelectedPeriod: (value: string | undefined) => void
}
export default defineStore(storeName, (): RisultatiDittaDettaglioStore => {
    const globalStore = useGlobalStore()
    const route = useRoute()

    const basePeriodoDal = dateUty
        .toMoment(`01/${globalStore.state.periodoElab?.year}`)
        ?.startOf('month')
    const basePeriodoAl = dateUty
        .toMoment(`${globalStore.state.periodoElab?.month}/${globalStore.state.periodoElab?.year}`)
        ?.endOf('month')

    const activeChildName: Ref<string | null> = shallowRef(null) //Nome del dettaglio attivo
    const dataLookup = reactive(new Map()) //Dati delle lookup (ref poiche con shallowRef non aggiorna le lookup)

    const state = reactive({
        data: null,
        routeList: pagheRoutes.risultati.elencoDitte.SETTINGS.DITTE_LISTA,
    })

    const datiDitta = shallowRef()

    const dataHeader = reactive({
        title: null,
        subtitle: null,
    })

    const selectedPeriod: Ref<string | undefined> = ref()
    initSelectedPeriod()

    //TODO DA SEMPLIFICARE (TOGLIERE L'ANNIDAZIONE SE POSSIBILE)
    const dataChild = shallowReactive({
        name: null, //Nome del dettaglio a cui appartengono i dati in dataChild
        origin: null, //Dati da ripristinare al cambio TAB
        extraData: null,
        codMultiCc: 'TOTALE', //per tutte le pagine dei risultati
    })

    // Periodo dal = 01/anno di PeriodoElaborazione.DataPeriodoElaborazione
    // Periodo al = mese/anno di PeriodoElaborazione.DataPeriodoElaborazione.
    // Dettaglio multi cantiere/centro di costo / fondo = FALSE
    // Considera precedenti rapporti, da preimpostare a FALSE
    const parametri: ShallowReactive<IStateParametri> = shallowReactive({
        cantiereCC: false,
        periodoDal: basePeriodoDal ? dateUty.toISO(basePeriodoDal) : null,
        periodoAl: basePeriodoAl ? dateUty.toISO(basePeriodoAl) : null,
        precRapporti: false,
    })

    const isNote = ref(false) //abilitazione - Note

    const isDiario = computed(() => {
        return diarioStudio.value && diarioDitta.value
    })

    const diarioStudio = reactive({ downloaded: false, value: false })
    const diarioDitta = reactive({ value: false, downloaded: false }) //varia in base alla variazione dei parametri

    //DATI GRIGLIA
    //const _grid = ref({}) //Dati della griglia da ripristinare al cambio tab (utilizzato dalla composable baseTableEdit ma puo essere usato anche senza)
    const localTab = ref(0) //Indica il subTab attivo nella pagina

    //#region GETTERs
    const getPeriodo = computed(() => {
        return globalStore.state.periodoElab || dateUty.toObject(moment())
    })

    const dataChildFilled = computed(() => {
        return !!dataChild.origin && dataChild.name === activeChildName.value
    })

    const getNoteContext: ComputedRef<NoteProps> = computed(() => ({
        entityId: state.data?.id as number,
        entityType: 'DITTA',
        isNote,
        title: `ditta: ${dataHeader.title?.[0]} - ${dataHeader.title?.[1]}`,
        vCodDes: 'TAG-ALERT-DITTA',
    }))

    const getPeriodi = computed(() => {
        const startDate = toMoment(parametri.periodoDal)
        const endDate = toMoment(parametri.periodoAl)

        const months: Array<string> = []

        if (!startDate || !endDate) return months

        while (!startDate.isAfter(endDate, 'month')) {
            months.push(startDate.endOf('month').startOf('day').format('YYYY-MM-DD[T]HH:mm:ss'))
            startDate.add(1, 'month')
        }

        return months
    })

    const getSelectedPeriod = computed(() => selectedPeriod.value)
    //#endregion

    //#region ACTIONs - FUNZIONI

    function setSelectedPeriod(value: string | undefined) {
        if (selectedPeriod.value != value) selectedPeriod.value = value
    }

    function initSelectedPeriod() {
        if (
            route.meta.group === pagheRoutes.risultati.elencoDitte.SETTINGS.DITTA_DETTAGLIO.NAME &&
            route.params
        )
            selectedPeriod.value = `${route.params.month}/${route.params.year} - ME`
    }

    async function getData(props: DitteProps) {
        getAbilitazioneDiario(props)

        const params: DittaPayload = {
            requireHasFutureValidity: false,
            orderByKey: true,
            periodoDiRiferimento: dateUty.toObject(parametri.periodoAl), //getPeriodoFromParametri(), //{ year: Number(props.year), month: Number(props.month) },
            filter: `['id', '=', '${props.id}']`,
            select: JSON.stringify([
                'id',
                'idPadre',
                'codiceDitta',
                'containsDitteUPNoteCalc',
                'dataPeriodoElaborazione',
                'dittaUPOpzione.isDiario',
                'dittaUPRagioneSociale.ragioneSociale',
                'dittaUPRagioneSociale.denominazioneAggiuntiva',
                'dittaUPStatusList.id',
                'dittaUPStatusList.dtimeElaborato',
                'dittaUPStatusList.flagElab',
                'dittaUPTabellaAttivaList.nomeTabella',
                'padre.dataPeriodoElaborazione',
                'padre.dittaUPOpzione.isDiario',
                'unitaProduttiva',
            ]),
        }

        //state.data = null //TODO VALUTARE REINIT OBJECT CON VALORI DI DEFAULT PER EVITARE PROBLEMI NELLE VIEW CHE USANO I DATI NEL TEMPLATE

        try {
            const response = await pagheAPI.anagrafiche.ditte.ditta.get(params)
            if (
                !response ||
                !response?.data?.responseStatus.isSuccessful ||
                !response.data?.data?.[0]
            )
                return
            const data = response.data.data[0]
            dataHeader.title =
                String(props?.isAllDitta) === '1'
                    ? [
                          data?.codiceDitta,
                          data?.dittaUPRagioneSociale?.ragioneSociale,
                          data?.dittaUPRagioneSociale?.denominazioneAggiuntiva,
                      ]
                    : [
                          data?.codiceDitta,
                          data?.unitaProduttiva,
                          data?.dittaUPRagioneSociale?.ragioneSociale,
                          data?.dittaUPRagioneSociale?.denominazioneAggiuntiva,
                      ]

            if (+props?.idPadre > 0 && data?.dittaUPTabellaAttivaList) {
                const isOpzione = isTableUPActive(
                    data?.dittaUPTabellaAttivaList,
                    NomeTabellaAttiva.dUPOpzione,
                )
                diarioDitta.value = isOpzione
                    ? get(data, 'dittaUPOpzione.isDiario', false)
                    : get(data?.padre, 'dittaUPOpzione.isDiario', false)
            } else {
                diarioDitta.value = get(data, 'dittaUPOpzione.isDiario', false)
            }

            isNote.value = !!data?.containsDitteUPNoteCalc
            state.data = data
        } catch (err) {
            console.error('Errore durante la chiamata: ', err)
        }
    }

    const getTipoDitta = computed(() => {
        return state?.data?.ditta?.dittaGenerale?.tipoDitta
    })

    function resetActiveChildName() {
        activeChildName.value = pagheRoutes.risultati.elencoDitte.SETTINGS.DITTA_RISULTATI.NAME
    }

    function resetBaseData(
        resetLookup: boolean = true,
        oNavigation: boolean = false,
        vNavigation: boolean = false,
    ) {
        dataChild.name = null
        dataChild.origin = null
        if (resetLookup) {
            dataLookup.clear()
        }
        if (!oNavigation) {
            //operazioni da fare solo se non si sta navigando
            localTab.value = 0
            dataChild.extraData = null //solo se non sto navigando
            if (!vNavigation) {
                initParametri() //in navigazione non vanno resettati
            }
        }
        if (!vNavigation) {
            //DIVERSO DIPTE, IN TUTTI I CASI TRANNE IN VERTICALE
            dataChild.codMultiCc = 'TOTALE'
        }
    }

    function resetPageData(
        resetLookup: boolean = true,
        oNavigation: boolean = false,
        vNavigation: boolean = false,
    ) {
        resetBaseData(resetLookup, oNavigation, vNavigation)
        //_grid.value = {}
    }

    function initParametri() {
        parametri.cantiereCC = false
        parametri.periodoDal = basePeriodoDal ? dateUty.toISO(basePeriodoDal) : null
        parametri.periodoAl = basePeriodoAl ? dateUty.toISO(basePeriodoAl) : null
        parametri.precRapporti = false
        initSelectedPeriod()
    }

    function getPeriodoFromParametri(toSave: boolean = false): PeriodoDiRiferimento | null {
        if (parametri.periodoDal && parametri.periodoAl) {
            const periodoDal = dateUty.toObject(parametri.periodoDal)
            const periodoAl = dateUty.toObject(parametri.periodoAl)

            if (toSave && periodoAl != null) {
                return {
                    ...periodoAl,
                }
            }

            return {
                ...periodoDal,
                yearTo: parseInt(periodoAl?.year as string),
                monthTo: parseInt(periodoAl?.month as string),
                dayTo: parseInt(periodoAl?.day as string),
            }
        }

        return null
    }

    async function getDiarioStudio(props: any) {
        try {
            const periodoDiRiferimento = dateUty.toObject(parametri.periodoAl)

            //FLAG PER GESTIONE DIARIO DELL STUDIO
            const paramsStudio = {
                periodoDiRiferimento,
                include: "['studioGeneraleList']",
                select: "['studioGeneraleList.isDiarioDatiElaborazione','id']",
            }
            const responseStudio = await pagheAPI.anagrafiche.studio.get(paramsStudio)

            if (responseStudio?.data?.responseStatus?.isSuccessful) {
                diarioStudio.downloaded = true
                diarioStudio.value =
                    responseStudio.data.data[0]?.studioGeneraleList[0]?.isDiarioDatiElaborazione ??
                    false
            }
        } catch (error) {}
    }
    async function getAbilitazioneDiario(props: DipendenteProps, forceDownload: boolean = false) {
        if (!parametri.periodoAl) return

        if (!diarioStudio.downloaded || forceDownload) {
            await getDiarioStudio(props)
        }
    }
    //#endregion

    return {
        activeChildName,
        dataChildFilled,
        dataChild,
        dataHeader,
        dataLookup,
        getAbilitazioneDiario,
        getData,
        getPeriodo,
        getPeriodoFromParametri,
        getPeriodi,
        getSelectedPeriod,
        getTipoDitta,
        //_grid,
        initSelectedPeriod,
        isDiario,
        isNote,
        localTab,
        getNoteContext,
        parametri,
        state,
        resetActiveChildName,
        resetBaseData,
        resetPageData,
        setSelectedPeriod,
    }
})
