<template>
    <div
        v-if="showHmenu"
        class="hmenu-header"
    >
        <!-- loader icon font -->
        <i
            v-if="!isMenuLoaded"
            class="fa-solid fa-circle-notch fa-spin me-3"
        ></i>
        <!-- Hamburger menu -->

        <v-row justify="center">
            <v-dialog
                v-model="dialog"
                fullscreen
                :scrim="false"
                transition="dialog-bottom-transition"
                aria-labelledby="mainNav"
                aria-modal="true"
                tabindex="-1"
            >
                <template #activator="{ props }">
                    <v-btn
                        v-if="isMenuLoaded"
                        variant="plain"
                        density="compact"
                        :class="isActive ? 'enabled' : 'avoid-clicks'"
                        class="ps-0 hmainmenu"
                        append-icon="fa-solid fa-bars"
                        v-bind="props"
                        tabindex="0"
                        aria-label="Apri menu principale"
                    >
                    </v-btn>
                </template>
                <v-card>
                    <!-- MODAL: placeholder per componente modale -->
                    <FullWidthModal
                        ref="refFullWidthModal"
                        @close-hmenu-modal="dialog = false"
                    ></FullWidthModal>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import FullWidthModal from '@/components/navigation/menu/FullWidthModal.vue'
import { storeToRefs } from 'pinia'
import { useStorePermissions } from '@/scripts/stores/Permissions/pagePermission.js'
import { useGlobalStore } from '@/stores'

export default {
    name: 'HamburgerMenu',
    components: {
        FullWidthModal,
    },
    props: {
        showHmenu: {
            /* true = hmenu visibile, false = hmenu NON visibile */
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const { menu } = storeToRefs(useStorePermissions())
        const globalStore = useGlobalStore()

        return {
            menu,
            globalStore,
        }
    },
    data() {
        return {
            showModal: false, // show/hide del menu in finestra modale

            dialog: false,
            notifications: false,
            sound: true,
            widgets: false,
            items: [
                { text: 'Real-Time', icon: 'mdi-clock' },
                { text: 'Audience', icon: 'mdi-account' },
                { text: 'Conversions', icon: 'mdi-flag' },
            ],
            tab: null,
            isDarkTheme: false,
        }
    },
    computed: {
        isMenuLoaded() {
            return this.menu && this.menu.length > 0
        },
        isActive() {
            return !this.globalStore.isLoading
        },
    },
    watch: {},
    created() {},
    mounted() {
        // this.addHandler()
        // check if dark theme
        this.checkIfDarkTheme()
    },
    unmounted: function () {
        // this.removeHanlder()
    },
    methods: {
        showHmenuModal() {
            this.showModal = true
        },
        // // close modal menu pressing ESC key
        // addHandler: function () {
        //     window.addEventListener('keydown', this.eventListenerAction)
        // },
        // removeHandler: function () {
        //     window.removeEventListener('keydown', this.eventListenerAction)
        // },
        // eventListenerAction: function (event) {
        //     const lowerKey = event.key.toLowerCase()

        //     if (lowerKey === 'escape') {
        //         this.showModal = false
        //     }
        // },
        checkIfDarkTheme() {
            if (
                localStorage.getItem('dark_theme') == true ||
                localStorage.getItem('dark_theme')?.toString() == 'true'
            ) {
                this.isDarkTheme = true
            } else {
                this.isDarkTheme = false
            }
        },
    },
}
</script>

<style scoped lang="scss" src="@/styles/components/navigation/menu/HamburgerMenu.scss"></style>

<style>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
    transition: transform 0.2s ease-in-out;
}
.avoid-clicks {
    opacity: 0.5;
    pointer-events: none;
}
</style>
