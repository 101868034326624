import { defineStore } from 'pinia'
import { Ref, ShallowReactive, ref, shallowReactive } from 'vue'

import { STORES_NAMES } from '@/constants'
import { useDynamicStore } from '@/composables/dynamic-store'
import { useBaseTable } from '@/composables'

const useExportDittaStore = defineStore(
    'ExportDittaStore',
    () => {
        const baseTable = useBaseTable()

        const elencoDipendentiOptions = {
            ...baseTable.baseTableOptions(),
            escludiCessati: 2,
            filtroLavoratori: [],
            selectedKeys: true,
            sortBy: [
                { key: 'codiceDitta', order: 'asc' },
                { key: 'codiceUP', order: 'asc' },
                { key: 'codiceDipendente', order: 'asc' },
            ],
        }

        const elencoDitteOptions = {
            ...baseTable.baseTableOptions(),
            soloElaborate: false,
            filtroDitteUP: false,
            sortBy: [
                { key: 'codiceDitta', order: 'asc' },
                { key: 'unitaProduttiva', order: 'asc' },
            ],
            tipoDitta: [1, 2],
        }

        const elencoExportsOptions = {
            ...baseTable.baseTableOptions(),
            sortBy: [{ key: 'codice', order: 'asc' }],
            tipoFiltro: [1, 2],
        }

        const baseSelectedExport = {
            analizza: { title: 'Totali standard ', value: null },
            codice: null,
            codCCosto: null,
            codQualifica: null,
            descrizione: null,
            isElaboraAltri: false,
            isElaboraColl: false,
            isElaboraDipte: true,
            periodoAl: null,
            /* periodoAlCol: null, */
            periodoDal: null,
            /*  periodoDalCol: null, */
            tipoTotale: null,
        }

        const activeChildName: Ref<string | null> = ref(null)
        const currentPage: Ref<number> = ref(1)
        const elencoDipendenti: ShallowReactive<any> = shallowReactive({
            ...elencoDipendentiOptions,
        })

        const elencoDitte: ShallowReactive<any> = shallowReactive({ ...elencoDitteOptions })
        const elencoExports: ShallowReactive<any> = shallowReactive({ ...elencoExportsOptions })
        const idDittePadre: Ref<Map<number, number>> = ref(new Map())
        const selectedExport: ShallowReactive<any> = shallowReactive({ ...baseSelectedExport })
        const dataExport: ShallowReactive<any> = shallowReactive([])
        const dataDitte: ShallowReactive<any> = shallowReactive([])

        function init() {
            Object.assign(elencoDipendenti, elencoDipendentiOptions)
            Object.assign(elencoDitte, elencoDitteOptions)
            Object.assign(elencoExports, elencoExportsOptions)
            Object.assign(selectedExport, baseSelectedExport)
        }

        return {
            /*  ...useDynamicStore(), */
            activeChildName,
            currentPage,
            elencoDipendenti,
            elencoDitte,
            elencoExports,
            idDittePadre,
            init,
            selectedExport,
            dataExport,
            dataDitte,
        }
    },
    {
        persist: false,
    },
)

export default useExportDittaStore
