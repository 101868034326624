import * as alterazioni from './alterazioni'

const AltriOneri = () => import('./AltriOneri.vue')
const Anagrafica = () => import('./Anagrafica.vue')
const AppaltiECommesse = () => import('./AppaltiECommesse.vue')
const Banche = () => import('./Banche.vue')
const CampiPersonalizzati = () => import('./CampiPersonalizzati.vue')
const CausaliAssenteismo = () => import('./CausaliAssenteismo.vue')
const CassaEdile = () => import('./CassaEdile.vue')
const CentriCosto = () => import('./CentriCosto.vue')
const DatiInps = () => import('./DatiInps.vue')
const DatiGestione = () => import('./DatiGestione.vue')
const Diario = () => import('./Diario.vue')
const DomandaCIG = () => import('./DomandaCIG.vue')
const FondiDmag = () => import('./FondiDmag.vue')
const Formule = () => import('./Formule.vue')
const GruppoAlterazione = () => import('./GruppoAlterazione.vue')
const ImpCedolino = () => import('./ImpCedolino.vue')
const Opzioni = () => import('./Opzioni.vue')
const OpzioniStampa = () => import('./OpzioniStampa.vue')
const Organico = () => import('./Organico.vue')
const PosizioniInail = () => import('./PosizioniInail.vue')
const RappresentateLegale = () => import('./RappresentateLegale.vue')
const Scadenziario = () => import('./Scadenziario.vue')
const SostImposta = () => import('./SostImposta.vue')
const Storico = () => import('./Storico.vue')
const UnitaProduttiveINPS = () => import('./UnitaProduttiveINPS.vue')

export {
    alterazioni,
    AltriOneri,
    Anagrafica,
    AppaltiECommesse,
    Banche,
    CampiPersonalizzati,
    CausaliAssenteismo,
    CassaEdile,
    CentriCosto,
    DatiInps,
    DatiGestione,
    Diario,
    DomandaCIG,
    FondiDmag,
    Formule,
    GruppoAlterazione,
    ImpCedolino,
    Opzioni,
    OpzioniStampa,
    Organico,
    PosizioniInail,
    RappresentateLegale,
    Scadenziario,
    SostImposta,
    Storico,
    UnitaProduttiveINPS,
}
