<template>
    <BaseDialog
        icon="book"
        show
        title="Gestione Diario"
        type="info"
        @cancel="discardHandler"
    >
        <template #body>
            <v-container class="mb-0 pb-0">
                <v-row class="m-1 input-dialog-box">
                    <v-col
                        cols="12"
                        md="6"
                        class=""
                    >
                        <BaseInputLookup
                            v-model="data.codice"
                            class=""
                            label="Codice Diario"
                            :items="dataLookup"
                        ></BaseInputLookup>
                    </v-col>
                    <v-col
                        cols="12"
                        md="6"
                        class=""
                    >
                        <BaseInputText
                            ref="inputFile"
                            input-file
                            label="File da allegare al diario"
                            class="custom-file"
                            @change="onChangeHandler"
                        />
                    </v-col>
                    <v-col cols="12">
                        <BaseInputTextArea
                            v-model="data.testoNota"
                            class=""
                            label="Nota Diario"
                        ></BaseInputTextArea>
                    </v-col>
                </v-row>
            </v-container>
        </template>
        <template #bodyFooterRight>
            <v-btn
                class="button-annulla"
                variant="text"
                @click="discardHandler"
            >
                annulla
            </v-btn>
            <v-btn
                class="button-salva ml-3"
                ___disabled="data.codice == null"
                ___class="{ disabled: false }"
                @click="saveHandler"
            >
                Salva
            </v-btn>
        </template>
    </BaseDialog>
</template>

<script lang="ts" setup>
import _ from 'lodash'
import BaseDialog from '@/components/base//BaseDialog.vue'
import BaseInputLookup from '@/components/base/BaseInputLookup.vue'
import BaseInputText from '@/components/base/BaseInputText.vue'
import BaseInputTextArea from '@/components/base/BaseInputTextArea.vue'

import InputFile from '@/components/input_v2/InputFile.vue'
import { ref, computed, onMounted, watch } from 'vue'
import { APIDiarioCodice } from '@/api'

//US - #15194
//nome della prop dello store dinamico dove salvare i dati della lookup
const DATA_LOOKUP_NAME = 'dataLookupDiario'
const DIARIO_LOG_NAME = 'diarioLog'

//19/09/23 - le pagine Socio e sommnDistac non hanno la gestione Diario

export interface DiarioModalProps {
    params: {
        pageName: string
        periodo: { periodoDiRiferimento: { year: number; month: number } }
        type: 'ditta' | 'dipendente' | 'collaboratore'
    }
}

const props = defineProps<DiarioModalProps>()

const emit = defineEmits(['reject', 'resolve'])

const data = ref({
    idDipte: null,
    idDitta: null,
    codTipoNota: null,
    codice: null,
    testoNota: null,
    fileDaAllegare: null,
})

let dataDiario: any = ref()

onMounted(async () => {
    sessionStorage.removeItem('diarioLog')
    populateData()
})

const truncateFilename = (filename: string, maxLength: number) => {
    const extension = filename.split('.').pop()
    const baseName = filename.slice(0, -extension.length - 1)

    if (baseName.length > maxLength) {
        return baseName.slice(0, maxLength - 3) + '___' + '.' + extension
    }
    return filename
}

const onChangeHandler = (value: any) => {
    const fileName = value.currentValue?.[0]?.name || null
    data.value.fileDaAllegare = fileName
        ? ((data.value.fileDaAllegare as any) = truncateFilename(fileName, 100))
        : fileName
}

const samePeriod = (obj1: { [x: string]: any }, obj2: { [x: string]: any }) => {
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    // Confronta la lunghezza delle chiavi
    if (keys1.length !== keys2.length) {
        return false
    }

    // Confronta le singole proprietà
    return keys1.every(key => obj1[key] === obj2[key])
}
//per popolare i codici del Diario
const populateData = async () => {
    //due modi: 1-dati salvati nello store, 2- dati salvati nell API con gestione del periodo
    let result = null
    let periodo = null
    const storedSessionData = sessionStorage.getItem(props.params.pageName || DATA_LOOKUP_NAME)

    if (storedSessionData) {
        result = JSON.parse(storedSessionData)
        dataDiario.value = result?.dataDiario
        periodo = result?.periodo
    }

    //se null faccio o il periodo e' differente faccio la chiamata,
    if (
        !dataDiario.value ||
        !samePeriod(periodo.periodoDiRiferimento, props.params.periodo.periodoDiRiferimento)
    ) {
        result = await APIDiarioCodice.get({ ...props.params.periodo })
        const data = {
            dataDiario: result?.data?.data,
            periodo: props.params.periodo,
        }
        sessionStorage.setItem(props.params.pageName || DATA_LOOKUP_NAME, JSON.stringify(data))
        dataDiario.value = result?.data?.data
    }
}

//se TRUE bisogna salvare la nota a BE
const saveNotaDiario = computed(() => {
    let result =
        data.value.codice?.length > 0 ||
        data.value.testoNota?.length > 0 ||
        data.value.fileDaAllegare?.length > 0
    return result
})

const resetData = () => {
    data.value.codTipoNota = null
    data.value.codice = null
    data.value.testoNota = null
    data.value.fileDaAllegare = null
}

//gestione del Payload in uscita
const saveHandler = () => {
    //preparazione del Payload che verra aggiunto nelle chiamate delle singole pagine della ditta
    let diarioLog = null

    if (saveNotaDiario.value) {
        diarioLog = { codTipoNota: 'M' }

        //Aggiungo al payload solamente le proprieta' che mi interessano
        if (data.value.codice?.length > 0) {
            diarioLog = { ...diarioLog, ...{ codice: data.value.codice } }
        }

        if (data.value.testoNota?.length > 0) {
            diarioLog = { ...diarioLog, ...{ testoNota: data.value.testoNota } }
        }

        if (data.value.fileDaAllegare?.length > 0) {
            diarioLog = {
                ...diarioLog,
                ...{
                    fileDaAllegare: data.value.fileDaAllegare,
                },
            }
        }
    }
    sessionStorage.setItem(DIARIO_LOG_NAME, JSON.stringify(diarioLog))

    emit('resolve', { status: true, diarioLog: diarioLog })
    resetData()
}

const discardHandler = () => {
    resetData()
    emit('resolve', { status: false, diarioLog: null })
}

const dataLookup = computed(() => {
    switch (props.params.type) {
        case 'ditta':
            return dataDiario.value?.filter((item: any) => item.isDitta)
        case 'dipendente':
            return dataDiario.value?.filter((item: any) => item.isDipte)
        case 'collaboratore':
            return dataDiario.value?.filter((item: any) => item.isCollaboratore)
        default:
            return []
    }
})
</script>

<style lang="scss" scoped>
.button-annulla {
    background: white;
    border-radius: 4px;
    font: normal normal 14px Roboto;
    padding: 0 1em;
    letter-spacing: 1.25px;
    color: #707070;
    text-transform: uppercase;
    height: 35px;
    box-shadow: 0px 1px 5px #00000029 !important;
}
.button-salva {
    border-radius: 4px;
    background-color: #009ef7;
    color: white;
    font: normal normal 14px Roboto;
    padding: 0 1em;
    letter-spacing: 1.25px;

    text-transform: uppercase;
    height: 35px;
}
.height-card {
    height: 70%;
}

.v-card {
    overflow: initial !important;
}

/* .v-col {
    padding: 6x !important;
}

.v-input--horizontal .v-input__prepend {
    margin-inline-end: 6px;
}

body .custom-file .v-field__input {
    display: initial !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
} */
</style>
