<template>
    <div
        v-if="periodo"
        class="mixin-periodo d-flex align-center"
        tabindex="0"
    >
        <v-chip
            v-if="gestione && ['ftr', 'pss'].includes(gestione)"
            :class="`mixin-periodo-gestione--${gestione} mr-2`"
            density="comfortable"
            label
            size="small"
            variant="flat"
            :text="gestioneStr"
        />
        <div
            :aria-label="`Periodo di riferimento: ${periodoStr}`"
            :class="{ active: noGestioneCorrente }"
            class="mixin-periodo-periodo d-flex align-center"
        >
            <Datepicker
                auto-apply
                close-on-scroll
                :disabled="!noGestioneCorrente"
                :dark="globalStore.state.darkTheme"
                locale="it-IT"
                max-date="9999-12-31"
                min-date="1900-01-01"
                :model-value="utyDate.toISO(periodo as any)"
                :year-range="[1900, 9999]"
                model-type="yyyy-MM-dd'T'HH:mm:ss"
                month-picker
                teleport
                text-input
                @update:model-value="change"
            >
                <template #trigger>
                    <v-icon
                        :icon="`fas fa-calendar-${noGestioneCorrente ? 'star' : 'day'}`"
                        size="small"
                        :style="{
                            height: '21px',
                            pointerEvents: noGestioneCorrente ? 'auto' : 'none',
                        }"
                        @click="noGestioneCorrente ? () => {} : null"
                    />
                </template>
            </Datepicker>
            <span class="ml-1">{{ periodoStr }}</span>
            <v-tooltip
                activator="parent"
                location="top"
                role="tooltip"
                :text="text"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { get } from 'lodash'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import { useGlobalStore } from '@/stores'
import utyDate from '@/scripts/services/date'

export interface PeriodoProps {
    gestione?: Gestione
    gestioneOff?: boolean
    periodo?:
        | string
        | Date
        | { year: string | number; month: string | number; day?: string | number | undefined }
    text?: string
}

const props = withDefaults(defineProps<PeriodoProps>(), {
    gestione: undefined,
    gestioneOn: false,
    periodo: undefined,
    text: 'Periodo di riferimento',
})

const emit = defineEmits(['change'])

const globalStore = useGlobalStore()

const gestioneStr = computed(() =>
    get({ ftr: 'FUTURO', pss: 'PASSATO' }, props.gestione!, undefined),
)

const noGestioneCorrente = computed(
    () => props.gestione && ['ftr', 'pss'].includes(props.gestione) && !props.gestioneOff,
)

const periodoStr = computed(() => (props.periodo ? utyDate.format(props.periodo, 'monthYear') : ''))

function change(value: { currentValue: any; oldValue: any }) {
    emit('change', value)
}
</script>

<style lang="scss">
@import '@/styles/global/vars';
.mixin-periodo {
    &-gestione {
        &--ftr {
            background-color: $red2;
            color: white;
        }
        &--pss {
            background-color: $green-darken-3;
            color: white;
        }
    }
    &-periodo {
        &.active {
            color: var(--color-mixin-periodo-active);
        }
        .dp__main {
            .dp__outer_menu_wrap {
                border: 1px solid var(--box-shadow-color);
                box-shadow: 3px 3px 5px -1px var(--box-shadow-color);
                margin-left: 1px;
                .dp__arrow_top {
                    left: calc(97% - 3px);
                    border-top: 1px solid var(--box-shadow-color);
                    border-right: 1px solid var(--box-shadow-color);
                }
            }
        }
    }
}
</style>
