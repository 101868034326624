import { ref, Ref, reactive, computed, shallowReactive, ComputedRef } from 'vue'
import { defineStore } from 'pinia'

import { useDynamicStore } from '@/composables/dynamic-store'
import { pagheAPI } from '@/api'

import { pagheRoutes } from '@/routes'
import { useGlobalStore } from '@/stores'
import { isTableUPActive } from '@paghe/views/anagrafiche/ditte/scripts'
import type { NoteProps } from '@/components/Note.vue'
import { NomeTabellaAttiva } from '@paghe/entities/ditte'

export const storeName: string = 'ditteDettaglioStore'

export default defineStore(
    storeName,
    (): DitteDettaglioStore => {
        const activeChildName: Ref<string | null> = ref(null)
        const dataLookup = shallowReactive(new Map())
        const dynamicStore = useDynamicStore() //utilizzato per il salvataggio di dati dinamici (es. dati di una griglia editabile particolare in una pagina)
        const globalStore = useGlobalStore()
        const headerDatePadre = ref({
            inizio: null,
            fine: null,
            validitaFutura: false,
        })
        const headerDate = ref({
            inizio: null,
            fine: null,
            validitaFutura: false,
        })
        const id = ref(-1)
        const isUpNote = ref(false)
        const localTab = ref(0)
        //const originData = shallowReactive({})
        const state: DitteStoreState = reactive({
            data: {
                codiceDitta: '',
                unitaProduttiva: '',
                dittaUPRagioneSociale: {
                    ragioneSociale: '',
                    denominazioneAggiuntiva: '',
                },
            },
            dataChild: {
                childName: null,
                origin: null,
                originPadre: null,
                form: null,
            },
            isDittaElaborata: false,
            lastIdx: -1, //index dell'ultimo elemento visualizzato ---> utilizzato per determinare il periodo e di conseguenza se riscaricare i dati delle lookup
            localTab: 0,
            props: null,
            routeList: pagheRoutes.anagrafiche.ditte.SETTINGS.DITTE_ELENCO,
        })

        //#region GETTERs
        const dataChildFilled = computed(() => {
            return (
                state.dataChild &&
                state.dataChild.origin &&
                state.dataChild.childName === activeChildName.value
            )
        })
        const getPeriodo = computed(() => {
            return { periodoDiRiferimento: { year: state.props!.year, month: state.props!.month } }
        })
        const getTitle = computed(() => {
            return [
                state?.data?.codiceDitta,
                state?.data?.unitaProduttiva,
                state?.data?.dittaUPRagioneSociale?.ragioneSociale,
                state?.data?.dittaUPRagioneSociale?.denominazioneAggiuntiva,
            ]
        })

        const isDittaElaborata = computed(() => {
            let result = false

            if (state.data?.dittaUPStatusList) {
                let elemento = state.data.dittaUPStatusList.find((element: any) => {
                    return element.flagElab === 'S' && element.dtimeElaborato != null
                })
                if (elemento) {
                    result = true
                }
            }

            return result
        })
        const isDiario = computed(() => {
            const isFiliale = !!state?.data?.idPadre
            if (isFiliale && state?.data?.dittaUPTabellaAttivaList) {
                const isOpzione = isTableUPActive(
                    state?.data?.dittaUPTabellaAttivaList,
                    NomeTabellaAttiva.dUPOpzione,
                )
                return isOpzione
                    ? state.data?.dittaUPOpzione?.isDiario
                    : state.data?.padre?.dittaUPOpzione?.isDiario
            }
            return state.data?.dittaUPOpzione?.isDiario
        })

        const noteContext: ComputedRef<NoteProps> = computed(() => ({
            entityId: state.data!.id as number,
            entityType: 'DITTA',
            isNote: isUpNote,
            title: `ditta: ${state.data!.codiceDitta} - ${ragSociale.value}`,
            vCodDes: 'TAG-ALERT-DITTA',
        }))
        const ragSociale = computed(
            () =>
                state.data?.dittaUPRagioneSociale?.denominazioneAggiuntiva ||
                state.data?.dittaUPRagioneSociale?.ragioneSociale ||
                '',
        )
        //#endregion

        //#region ACTIONs
        async function deleteDitta(): Promise<any> {
            if (state?.data?.id && state.data.id > 0) {
                globalStore.loadingSet()
                //caso in cui la ditta non e' stata creata quindi sono nella prima pagina del wizard
                const payload = {
                    periodoDiRiferimento: {
                        year: Number(state.props!.year),
                        month: Number(state.props!.month),
                    },
                    id: state.data.id,
                    isNuovaValidita: false,
                }

                const result = await pagheAPI.anagrafiche.ditte.ditta.delete(payload)
                globalStore.loadingUnset()
                //todo ricontrollare
                return result != null
            }

            return true
        }
        async function deleteInsertEntity(): Promise<any> {
            if (state?.data?.id && state.data.id > 0) {
                globalStore.loadingSet()
                //caso in cui la ditta non e' stata creata quindi sono nella prima pagina del wizard
                const payload = {
                    periodoDiRiferimento: {
                        year: Number(state.props!.year),
                        month: Number(state.props!.month),
                    },
                    id: state.data.id,
                    isNuovaValidita: false,
                }

                const result = await pagheAPI.anagrafiche.ditte.ditta.delete(payload)
                globalStore.loadingUnset()
                //todo ricontrollare
                return result != null
            }

            return true
        }

        async function getData(props: DitteProps) {
            const params: DittaPayload = {
                requireHasFutureValidity: false,
                orderByKey: true,
                periodoDiRiferimento: { year: Number(props.year), month: Number(props.month) },
                include:
                    "['dittaUPRagioneSociale', 'dittaUPStatusList','dittaSostituto', 'dittaUPOpzione','dittaUPTabellaAttivaList']",
                filter: `['id', '=', '${props.id}']`,
                select: JSON.stringify([
                    'id',
                    'idPadre',
                    'padre.dittaUPOpzione.isDiario',
                    'codiceDitta',
                    'unitaProduttiva',
                    'containsDitteUPNoteCalc',
                    'dittaUPGestione.idDitta',
                    'dittaUPGestione.codContratto',
                    'dittaUPGestione.codSuddContratto',
                    'dittaUPGestione.codSuddTerritorio',
                    'dataPeriodoElaborazione',
                    'padre.dataPeriodoElaborazione',
                    'padre.dittaUPGestione.idDitta',
                    'padre.dittaUPGestione.codContratto',
                    'padre.dittaUPGestione.codSuddContratto',
                    'padre.dittaUPGestione.codSuddTerritorio',
                    'dittaUPRagioneSociale.ragioneSociale',
                    'dittaUPRagioneSociale.denominazioneAggiuntiva',
                    'dittaUPStatusList.id',
                    'dittaUPStatusList.dtimeElaborato',
                    'dittaUPStatusList.flagElab',
                    'dittaUPTabellaAttivaList.nomeTabella',
                    'dittaUPOpzione.isDiario',
                    'ViewDittaPresenzaAlterazioniList.alterazione',
                    'ViewDittaPresenzaAlterazioniList.gruppo',
                ]),
            }

            //state.data = null //TODO VALUTARE REINIT OBJECT CON VALORI DI DEFAULT PER EVITARE PROBLEMI NELLE VIEW CHE USANO I DATI NEL TEMPLATE

            try {
                const response = await pagheAPI.anagrafiche.ditte.ditta.get(params)
                if (
                    !response ||
                    !response?.data?.responseStatus.isSuccessful ||
                    !response.data?.data?.[0]
                )
                    return

                state.data = response.data.data[0]
                isUpNote.value = !!state.data?.containsDitteUPNoteCalc
            } catch (err) {
                console.error('Errore durante la chiamata: ', err)
            }
        }

        function resetActiveChildName() {
            activeChildName.value = pagheRoutes.anagrafiche.ditte.SETTINGS.DITTA_ANAGRAFICA.NAME
        }

        //esegue il reset del data header, padre e attuale
        function resetDataHeader() {
            headerDate.value = { inizio: null, fine: null, validitaFutura: false }
            headerDatePadre.value = { inizio: null, fine: null, validitaFutura: false }
        }

        function resetBaseData(resetLookup: boolean = true, navigation: boolean = false) {
            state.dataChild.childName = null
            if (resetLookup) {
                dataLookup.clear() //PER NUIOVA GESTIONE LOOKUP
            }

            state.dataChild.origin = null //fix per datalookup uguali non scatta il watch
            resetDataHeader()

            if (!navigation) {
                //operazioni da fare solo se non si sta navigando tra le ditte
                localTab.value = 0
            }
            id.value = -1
        }

        function resetPageData(resetLookup: boolean = true, navigation: boolean = false) {
            resetBaseData(resetLookup, navigation)
            //reset dati aggiuntivi
            state.dataChild.origin = null
            state.dataChild.originPadre = null //VERIFICARE SE SERVE
            state.dataChild.form = null //VERIFICARE SE SERVE
            if (state.dataChild.changeMap && state.dataChild.changeMap.clear)
                state?.dataChild?.changeMap?.clear() //vedere se metterlo qui o in resetBaseData
            state.dataChild._grid = null
        }

        function resetStateData() {
            //reset stateData
            state.data = {
                codiceDitta: '',
                unitaProduttiva: '',
                dittaUPRagioneSociale: {
                    ragioneSociale: '',
                    denominazioneAggiuntiva: '',
                },
            }
        }

        function setData(newData: any) {
            if (newData) {
                state.data = { ...newData }
            } else {
                console.log('Elemento non trovato')
            }
        }

        function setDataHeader(entity: any = null) {
            if (entity && headerDate) {
                headerDate.value.inizio = entity?.dataInizio
                headerDate.value.fine = entity?.dataFine
            }
        }

        function setProps(props: any) {
            if (state.props != null) {
                Object.assign(state.props, props)
            } else {
                state.props = { ...props }
            }
        }

        //#endregion

        return {
            ...dynamicStore,
            activeChildName,
            dataChildFilled,
            dataLookup,
            deleteDitta,
            deleteInsertEntity,
            getData,
            getPeriodo,
            getTitle,
            headerDate,
            headerDatePadre,
            id,
            isDiario,
            isDittaElaborata,
            isUpNote,
            localTab,
            noteContext,
            //originData,
            resetStateData,
            resetActiveChildName,
            resetPageData,
            resetBaseData,
            state,
            setData,
            setDataHeader,
            setProps,
        }
    },
    {
        persist: false,
    },
)
