import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

const BASE_URL_ANAGRAFICA = '/DipteAnagrafica'

class API {
    @call({ url: `${BASE_URL_ANAGRAFICA}/GetDipendentiAnagrafica` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DipteAnagrafica>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_ANAGRAFICA}/SaveDipteAnagrafica` })
    static async save(
        payload: SaveDipteAnagraficaPayload,
    ): Promise<AxiosResponse<BasicResponsePayload<DipteAnagrafica>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
