import pagheViews from '@paghe/views'

const tabPropsDefault: TabProps = {
    CODVOCEMENU: '000178',
    GROUP: 'exportDitte',
    ICON: 'fa-regular fa-file-export',
    TITLE: 'Export ditte',
}

const SETTINGS = Object.freeze({
    DETTAGLIO: {
        NAME: 'ExportDitte',
        PATH: 'export/ditte',
        TAB: { ...tabPropsDefault },
        TITLE: 'Paghe - Export ditte',
    },
    RISULTATI: {
        NAME: 'Risultati',
        PATH: 'risultati',
        TAB: { ...tabPropsDefault },
        TITLE: '',
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            component: pagheViews.exports.ditte.Dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: 'RII_DITTA',
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            children: [
                {
                    path: SETTINGS.RISULTATI.PATH,
                    name: SETTINGS.RISULTATI.NAME,
                    component: pagheViews.exports.ditte.childs.Export,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: 'RII_DITTA',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                },
            ],
        },
    ],
}
