import { computed, ref, Ref } from 'vue'
import { defineStore } from 'pinia'
import { useGlobalStore } from '@/stores'
import dateUty from '@/scripts/services/date'

interface Store {
    actualIndex: Ref<number>
    filtro: Ref<string>
    selectedKeys: Ref<boolean>
    sortBy: Ref<Array<{ key: string; order: string }>>
    filtroDitteGestite: Ref<boolean>
    filtroDitteTipo: Ref<Array<number>>
    filtroDitteUP: Ref<boolean>
    data: Ref<null | any>
    dataList: Ref<null | any[]>
    findDittaById(id: number): null | any
}

export const storeName = 'risultatiDitteListStore'

export default defineStore(storeName, () => {
    const actualIndex = ref(0)
    const globalStore = useGlobalStore()

    const filtro = ref('')
    const selectedKeys = ref(true)
    const sortBy = ref([
        { key: 'codiceDitta', order: 'asc' },
        { key: 'unitaProduttiva', order: 'asc' },
    ])
    const filtroDitteGestite = ref(true)
    const filtroDitteTipo = ref([1, 2])
    const filtroDitteUP = ref(false)
    const data = ref(null)
    const dataList = ref(null)

    // function findDittaById(id: number) {
    //     if (id < 1) return null

    //     const ditta = dataList.value.find((item: any) => item.id == id)

    //     return ditta ? ditta : null
    // }

    /**
     * Funzione che permette di ottenere il periodi di elaborazione di una ditta in base all'id.
     * La ditta e' una di quelle scaricate dall'elenco ditte.
     * @param id Id della ditta di cui si vuole conoscere il periodo di elaborazione
     * @param isIndex
     * @returns periodo di elaborazione della ditta
     */
    function getPeriodoDitta(id: number) {
        const idElement = id
        const element = dataList.value?.find((item: any) => item.id == idElement)
        const periodoElab = element?.dataPeriodoElaborazione
        let periodo = globalStore.state.periodoElab //periodo di base se non viene trovato per la ditta
        if (periodoElab) {
            periodo = dateUty.toObject(periodoElab) //MomentJS.GetPeriodoRiferimentoFromIso(periodoElab) //periodo specifico
        } else {
            //potrebbe trattarsi di una figlia, in tal caso vado a prendere il periodo di elaborazione del padre
            if (element.idPadre != null && element.idPadre > 0) {
                periodo = getPeriodoDitta(element.idPadre)
            }
        }
        return periodo
    }

    /**
     * Restituisce i parametri di navigazione relativi a una data Ditta.
     * @param id id della Ditta dalla quale ottenere il periodo
     * @param isIndex indica se l'id passato e' l'indice della lista di elementi (selectedKeys) selezionati nell'elenco
     * @returns
     */
    function getPropsNavigation(id: number) {
        const element = dataList.value?.find?.((item: any) => item.id == selectedKeys.value[id])
        return { ...getPeriodoDitta(+element?.id), id: +element?.id, idPadre: +element?.idPadre }
    }

    /**
     * Controlla se la ditta da cui si proviene (durante la navigazione) ha lo stesso periodo della ditta che si vuole visualizzare.
     * @returns true se il periodo e' il medesimo, altrimenti false.
     */
    function samePeriod(): boolean {
        return true
    }

    const getFiltroUP = computed(() => {
        return filtroDitteUP.value
    })

    const navLength = computed(() =>
        Array.isArray(selectedKeys.value) ? selectedKeys.value.length : 0,
    )

    return {
        actualIndex,
        data,
        dataList,
        filtroDitteGestite,
        filtroDitteTipo,
        filtroDitteUP,
        sortBy,
        selectedKeys,
        filtro,
        getPeriodoDitta,
        getPropsNavigation,
        getFiltroUP,
        navLength,
        samePeriod,
    }
})
