import { AxiosResponse } from 'axios'

import { call } from '@/scripts/lib/http'

const BASE_URL = '/Export'

const defaultPayload: BasePayload = {
    isNuovaValidita: false,
    inserimentoIsAlwaysNuovaValidita: false,
}

class API {
    @call({ url: `${BASE_URL}/GetExport`, defaultPayload })
    static async get(
        payload: GetExportPayload,
    ): Promise<AxiosResponse<BasicResponseData<GetExportPayload>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveDefinizioneExportGenerale`, defaultPayload })
    static async saveGenerale(
        payload: SaveExportGeneralePayload,
    ): Promise<AxiosResponse<BasicResponseData<SaveExportGeneralePayload>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/GetParametriExport`, method: 'post' })
    static getParametri(payload: Payload): any {}

    @call({ url: `${BASE_URL}/SaveExportDato`, defaultPayload })
    static async saveDato(
        payload: SaveExportDatoPayload,
    ): Promise<AxiosResponse<BasicResponseData<SaveExportDatoPayload>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveExportFiltro`, defaultPayload })
    static async saveFiltro(
        payload: SaveExportFiltroPayload,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveExportFiltroPayload>> | null> {
        return null
    }
}

export default {
    get: API.get,
    getParametri: API.getParametri,
    saveDato: API.saveDato,
    saveFiltro: API.saveFiltro,
    saveGenerale: API.saveGenerale,
}
