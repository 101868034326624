import { computed, getCurrentInstance, inject, onMounted, onUnmounted, reactive, ref } from 'vue'
import type { ComponentPublicInstance } from 'vue'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { Emitter } from 'mitt'

import type { Column } from '@/typesctipt/HTable'
import { GLOBALS } from '@/constants'
import { enmInputStatus } from '@/typescript/Enums'
import { Toast } from '@/scripts/services/utility'
import useTabStore from '@/stores/TabStore'
import { useStorePermissions } from '@/scripts/stores/Permissions/pagePermission.js'
import { useRouter } from 'vue-router'
import ValidationResult from '@/typescript/ValidationResult'
import { get } from 'lodash'
import dateUty from '@/scripts/services/date'
import { checkPermessi } from '@/router/newRouter'

export function makeIdPropsDefault() {
    return {
        id: {
            type: [String, Number],
            default: 0,
        },
    }
}

export function makePeriodoPropsDefault() {
    return {
        year: {
            type: [String, Number],
            required: true,
        },
        month: {
            type: [String, Number],
            required: true,
        },
        day: {
            type: [String, Number],
            required: false,
            default: 1, //di default se non viene passato nelle chiamate viene messo dal BE a 1
        },
    }
}

export function makeModPropsDefault() {
    return {
        mod: {
            type: String,
            default: GLOBALS.DATA_MODE.INS,
            validator(value: string) {
                return Object.values(GLOBALS.DATA_MODE).includes(value)
            },
        },
    }
}

//#region types and interfaces
interface ObjectCallback {
    callback: Function
    message: string
}

type TabRouting = {
    changeTab?: Function // metodo passato dalla pagina al cambio TAB
    resetPageData?: Function // reset data chiamato dalla pagina
    saveAndClose?: Function // metodo chiamato dalla pagina in uscita/salvataggio
    store: any // store della pagina
    tabs: {
        current: TabRoute // TAB corrente
        list?: TabRoute // TAB elenco (se previsto)
        parent: TabRoute // TAB parent (stepper, se previsto)
    }
}
//#endregion

export function usePage(pageInstance?: ComponentPublicInstance, tabIdPrefix = 'tab') {
    const vuePageInstance = pageInstance || getCurrentInstance()!.proxy
    const emitter = inject('emitter') as Emitter<{ setTabsErrors: string }>
    const formData = reactive({})
    const tabErrors = ref({})
    const tabStore = useTabStore()
    const router = useRouter()
    const storePermission = useStorePermissions()

    //#region hook
    onMounted(() => {
        emitter.on('setTabsErrors', updateTabErrorrs)
    })

    onUnmounted(() => {
        emitter.off('setTabsErrors', updateTabErrorrs)
    })
    //#endregion

    /**
     * Metodo chiamato dalla composable "page" quando si cambia/chiude un TAB
     * @param {RouteLocationNormalized} to Quello passato da vue-router
     * @param {RouteLocationNormalized} from Quello passato da vue-router
     * @param {NavigationGuardNext} next Quello passato da vue-router
     * @param {TabRouting} tabRouting Vedi TabRouting
     */
    async function onBeforeRouteLeave(
        to: RouteLocationNormalized,
        from: RouteLocationNormalized,
        next: NavigationGuardNext,
        tabRouting: TabRouting,
    ) {
        const mode = tabRouting.store.state?.props?.mod
        const resetPageData = tabRouting?.resetPageData || (() => {})
        const tabCurrent = tabRouting.tabs?.current
        const tabList = tabRouting.tabs?.list
        const tabParent = tabRouting.tabs?.parent
        // chiusura TAB
        if (tabCurrent?.TAB?.GROUP && !tabStore.checkTabsExist(tabCurrent.TAB.GROUP)) {
            next()
            return
        }
        // passaggio ad altro TAB
        if (to.meta.group !== tabParent?.NAME) {
            tabStore.updateTab(
                from.name as string,
                tabCurrent.TAB as TabProps,
                from.params,
                tabRouting.saveAndClose,
            )
            to.name === tabList?.NAME ? resetPageData() : tabRouting.changeTab?.()
            next()
            return
        }
        //Controllo permessi per aprire la pagina
        if (!checkPermessi(to, from)) {
            next(false)
            return
        }
        // passaggio ad altra pagina dello stesso TAB (menu sx)
        if (mode && mode !== GLOBALS.DATA_MODE.MOD) {
            resetPageData(true, false, true)
            next()
            return
        }
        // salvataggio dati
        if (tabRouting.saveAndClose) {
            const result = await tabRouting.saveAndClose(true)
            if (result) {
                if (tabCurrent.TAB) tabStore.setSave(tabCurrent.TAB, false)
                resetPageData(true)
            } else {
                tabRouting.store.activeChildName = from.name
                next(false)
                return
            }
        }
        next()
        return
    }

    function updateTabErrorrs() {
        tabErrors.value = Object.fromEntries(refFieldsInTab(true))
    }

    /**
     * Gestisce gli errori di ritorno da una API a seguito di form submission:
     * - spalma gli errori e/o  warning sui campi
     * - visualizza il messaggio dell'esito all'utente
     * - esegue una eventuale callback
     * @param {ApiResult} response La response API ritornata da Axios
     * @param {string | ObjectCallback} [success] Un messaggio custom da visualizzare con Toast e/o una callback
     * da eseguire in caso di successo
     * @param {string | ObjectCallback} [error] Un messaggio custom da visualizzare con Toast e/o una callback
     * da eseguire in caso di errore
     */
    async function submitFormErrors(
        response: ApiResult,
        success: string | ObjectCallback = 'Operazione completata con successo',
        error:
            | string
            | ObjectCallback = 'Operazione non riuscita.\n\nCorreggere i campi evidenziati in ' +
            'rosso e riprovare',
    ) {
        function findRef(name: string): any {
            return (
                vuePageInstance!.$refs?.[name] ||
                (Object.values(vuePageInstance!.$refs).find((val: any) => {
                    return val.uid === name || val.props?.uid === name
                }) as any)
            )
        }

        function spreadErrors(
            fields: { index: null | number; nome: string; message: string }[],
            status = enmInputStatus.onError,
        ) {
            // errors/warning spreading
            if (!fields) return
            let ref: any
            fields.forEach(field => {
                if (field.index === null || field.index === undefined) {
                    ref = findRef(field.nome)
                    if (!ref) return
                    if (Array.isArray(ref)) ref = ref[0]
                    const validation = new ValidationResult({
                        message: field.message,
                        value: status,
                    })
                    // inputs
                    if (ref.mapValidationValue) ref.mapValidationValue(validation)
                    // lookups
                    if (ref.$refs.refInputLookup?.mapValidationValue) {
                        ref.$refs.refInputLookup.mapValidationValue(validation)
                    }
                } else {
                    // griglia editabile
                    const [refName, fieldName] = field.nome.split('.')
                    ref = findRef(refName)
                    console.log(ref)

                    if (!ref) return
                    if (Array.isArray(ref)) ref = ref[0]
                    if (fieldName) {
                        // errore di campo
                        const row = field.index
                        const col = ref.hTable.columns.findIndex(
                            (col: Column) => col.data === fieldName,
                        )
                        ref.hTable.errMap.set(ref.hTable.errMapKey(row, col), field.message)
                        ref.hTable.updateData()
                    } else {
                        // errore generico
                        ref.alert = {
                            text: field.message,
                            type: status === enmInputStatus.onWarning ? 'warning' : 'error',
                        }
                    }
                }
            })
        }
        // reset
        Object.values(vuePageInstance!.$refs).forEach((ref: any) => {
            if (Array.isArray(ref)) ref = ref[0]
            // inputs
            if (ref.mapValidationValue) ref.mapValidationValue(new ValidationResult())
            // lookups
            if (ref.$refs.refInputLookup?.mapValidationValue) {
                ref.$refs.refInputLookup.mapValidationValue(new ValidationResult())
            }
        })
        //
        const res = response.responseStatus
        spreadErrors(res.fieldErrors)
        spreadErrors(res.customMessagesAfterValidation, enmInputStatus.onWarning)
        const actionResult = res.isSuccessful ? success : error
        ;(actionResult as ObjectCallback).callback || (() => {})()
        if (res.isSuccessful || res.fieldErrors) {
            const toast = res.isSuccessful ? Toast.success : Toast.error
            let message = (actionResult as ObjectCallback).message || actionResult
            toast(message as string)
        }
    }

    /**
     * Se isFields = true, ritorna una mappa dei campi di pagina referenziati con REF e con il cor_
     * rispondente ID del tab di appartenenza.
     * Se isFields = false (default), ritorna una mappa dei tab di pagina con i corrispondenti campi
     * referenziati da REF.
     * I tab devono avere l'attributo ID impostato con un valore del tipo 'tab-N', dove N è un indice
     * numerico.
     * @param {boolean} isError Se estrapolare solo i campi con errore o tutti (defalt false)
     * @param {boolean} isFields Se estrapolare la mappa tab-campi (default) o campi-tab
     * @return {Map<string, string[]>} refFieldsInTab
     */
    function refFieldsInTab(isError = false, isFields = false): Map<string, string | string[]> {
        const tabs: Map<string, string | string[]> = new Map()
        if (!isFields) {
            document.querySelectorAll(`[id^=${tabIdPrefix}-]`).forEach(tab => tabs.set(tab.id, []))
        }
        let element

        Object.entries(vuePageInstance!.$refs).forEach(([key, ref]: [string, any]) => {
            if (Array.isArray(ref)) ref = ref[0]
            if (!ref) return // in giro per l'app c'è anche questo(!), non togliere!
            element = ref.$el
            if (isError) {
                if (ref.hTable) {
                    if (!ref.hTable?.errMap.size) return
                    element = ref.$el.nextElementSibling
                } else {
                    if (!ref.error && !ref?.$refs?.refInputLookup?.error) return
                }
            }
            const tab = element?.closest(`[id^=${tabIdPrefix}-]`)?.id
            if (isFields) {
                if (tab) tabs.set(key, tab)
            } else {
                if (tabs.has(tab)) (tabs.get(tab) as string[]).push(key)
            }
        })
        return tabs
    }

    // Add definitions to the page form data

    // base: 'Base field value',
    // type: null | 'date' | 'lookup',
    // name: 'Temporary name on the field',
    // onGet: 'Function to run when the data is initialized
    //         the return of the function is assigned to the value',
    // basePath: 'In case the value is located into a child of the object, retried it from the path'
    // func: 'Function to run when the data is passed to the payload
    //         the return of the function is assigned to the value'

    const populateFormData = (obj: any) => {
        for (let key in obj) {
            const current = obj[key]
            let value = current.hasOwnProperty('base')
                ? current.base
                : typeof current === 'string' && current.length > 0
                  ? current
                  : null
            const name = current.name

            if (current.type && current.type === 'date') {
                value = dateUty.format(value) //MomentJS.ConvertRegularFormat(value)
            }

            if (current === false || current === true) {
                value = current
            }

            if (current.onGet && typeof current.onGet == 'function') {
                let funcValue = current.onGet(value)
                if (funcValue) value = funcValue
            }

            formData[name ? name : key] = value
        }
    }

    // const mapFormData = (obj: any, data: any) => {
    //     if (!data) return {}
    //     for (let key in obj) {
    //         const current = obj[key]
    //         let condition = data[key] != null && data[key] != undefined
    //         let value = condition ? data[key] : formData[key]
    //         formData[current.name ? current.name : key] = value
    //     }
    // }

    const mapFormData = (obj: any, data: any) => {
        if (!data) return {}
        for (let key in obj) {
            const current = obj[key]
            let finalValue = data[key]
            if (current.basePath) {
                let formattedPath = current.basePath.includes('.')
                    ? current.basePath.split('.')
                    : [current.basePath]
                finalValue = get(data, [...formattedPath, key])
            }
            let condition = finalValue != null && finalValue != undefined
            let value = condition ? finalValue : formData[key] ? formData[key] : ''

            if (current.type && current.type === 'date') {
                value = dateUty.format(value) //MomentJS.ConvertRegularFormat(value)
            }

            if (current.onGet && typeof current.onGet == 'function') {
                let funcValue = current.onGet(value)
                if (funcValue) value = funcValue
            }

            formData[current.name ? current.name : key] = value
        }
    }

    const mapFormQueryData = (form: any, parents: any = {}) => {
        let mappedData = {}

        for (let key in parents) mappedData[key] = {}

        const checkIfInParent = function (name: string) {
            for (let key in parents) {
                if (parents[key].includes(name)) return key
            }
            return false
        }

        for (let key in form) {
            const current = form[key] // current loop object
            const field = current.field //
            let value: string | null = ''

            const formdataKey = current.name ? current.name : key

            if (!!field && !!formData[formdataKey]) {
                value = formData[formdataKey][field] || formData[formdataKey]

                //get(formData, `${key}.${field}`, current.base || null)
            } else {
                value = formData[formdataKey]
            }

            if (current.type && current.type === 'date') {
                value = dateUty.toISO(value) //MomentJS.ConvertToIsoFormat(value)
            }

            if (typeof value === 'string' && value.length === 0) value = null

            const parent = checkIfInParent(key)
            if (!parent) mappedData[key] = current.func ? current.func(value) : value
            else mappedData[parent][key] = current.func ? current.func(value) : value
        }

        return mappedData
    }

    //USATO SINGOLARMENTE OPPURE IN PAGEFORM.TS
    const disableField = computed(() => {
        //SE LA PAGINA HA PIU' CODPERMESSI ASSOCIATI PER ORA VIENE GESTITO IN PAGINA
        if (Array.isArray(router.currentRoute.value?.meta?.permissions?.codPermesso)) return false

        const codPermesso = router.currentRoute.value?.meta?.permissions?.codPermesso
        const typePermission = storePermission.getPermissionType(codPermesso)
        //se typePermission !== A, campo read-only
        return typePermission !== 'A'
    })

    return {
        disableField,
        populateFormData,
        mapFormData,
        mapFormQueryData,
        formData,
        onBeforeRouteLeave,
        router,
        submitFormErrors,
        tabErrors,
        vuePageInstance,
    }
}
