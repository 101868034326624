import { defineStore } from 'pinia'
import { MessagiBE } from '@/scripts/services/service.js'
import { useGlobalStore } from '@/stores'
import { cloneDeep } from 'lodash'

const useStoreBeMessage = defineStore({
    id: 'beMessage',
    state: () => ({
        errorMessages: [],
        singleErrorMessage: '',
    }),
    getters: {
        getDescription(state) {
            return state.singleErrorMessage
        },
    },
    actions: {
        // da usare per recuperare messaggi by code e con valori da sostituire nella variabile
        async fetchBeMessage(code, msgValues = null) {
            const state = this

            await MessagiBE.GetVMessaggio(
                {
                    periodoDiRiferimento: {
                        year: 2022,
                        month: 2,
                    },
                    filter: "['isFrontEnd','=','true']",
                    replacementValues: msgValues,
                    codice: code,
                },
                data => {
                    state.singleErrorMessage = data.vMessaggio.descrizione
                },
            )
            return state.singleErrorMessage
        },

        // al load dell'applicazione, carica tutti i messaggi dentro lo store
        async fetchBeMessageAll() {
            const state = this
            let result = await MessagiBE.GetVMessaggiAsync({
                periodoDiRiferimento: useGlobalStore().state.periodoElab,
                filter: "['isFrontEnd','=','true']",
            })
            if (result.length == 0) return

            state.errorMessages = result.map(e => {
                return { codice: e.codice, descrizione: e.descrizione }
            })

            // MessagiBE.GetVMessaggiAsync({
            //     periodoDiRiferimento: {
            //         year: 2022,
            //         month: 11,
            //     },
            //     filter: "['isFrontEnd','=','true']",
            // }, (data) => {
            //     state.errorMessages = data.map(e => {
            //         return { codice: e.codice, descrizione: e.descrizione}
            //     });
            //     //console.log("state.errorMessages", state.errorMessages)
            //     //state.singleErrorMessage = data.vMessaggio.descrizione
            // })
        },

        getDescriptionByCode(code) {
            // ricerco il codice all'interno dell'oggetto in store creato al load dell'applicazione
            return this.errorMessages.find(e => e.codice === code)
        },
        getMessageByCode(code, ...args) {
            //...args: any[]
            // ricerco il codice all'interno dell'oggetto in store creato al load dell'applicazione
            const message = cloneDeep(this.errorMessages.find(e => e.codice === code)) // CloneDeep per evitare di sovrascrivere lo store
            if (!message) return ''
            //se il messaggio e' stato trovato bisogna sostituire $1,$2 le varie varaibili passate in args
            args.forEach((element, index) => {
                //devo inserire element al posto di $n
                message.descrizione = message.descrizione.replace(`$${index + 1}`, element)
            })
            return `${message.descrizione} (cod.${message.codice})`
        },
    },
})

export { useStoreBeMessage }
