import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

const BASE_URL = '/DittaUPTabellaAttiva'

class API {
    @call({ url: `${BASE_URL}/GetDittaUPTabelleAttive` })
    static async get(
        payload: BaseDittaUpTabellaAttivaPayload,
    ): Promise<AxiosResponse<BasicResponsePayload<DittaUPTabellaAttiva[]>> | null> {
        return null
    }
}

export default {
    get: API.get,
}
