import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'
import { BASE_URL_DITTA } from './ditta'

export const BASE_URL_OPZIONI_STAMPA = '/DittaUPOpzioneStampa'

class API {
    @call({ url: `${BASE_URL_OPZIONI_STAMPA}/GetDitteUPOpzioneStampa` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DittaUPOpzioneStampa>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DITTA}/SaveDittaOpzioniStampa` })
    static async save(
        payload: SaveDittaUPOpzioniStampa,
    ): Promise<AxiosResponse<BasicDittaResponsePayload<DittaUPOpzioneStampa>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
