import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

export const BASE_URL_DITTA = '/Ditta'

class API {
    //#region BASE
    @call({ url: `${BASE_URL_DITTA}/GetDitte` })
    static async get(
        payload: DittaPayload,
    ): Promise<AxiosResponse<BasicResponseData<DittaResponse>> | null> {
        return null
    }
    @call({ url: `${BASE_URL_DITTA}/CreateDitta` })
    static async createDitta(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponsePayload<DittaResponse>> | null> {
        return null
    }
    @call({ url: `${BASE_URL_DITTA}/UpdateDitta`, method: 'put' })
    static async updateDitta(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponsePayload<DittaResponse>> | null> {
        return null
    }
    @call({ url: `${BASE_URL_DITTA}/SaveDittaInpsEnti` })
    static async saveDittaInpsEnti(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponsePayload<DittaResponse>> | null> {
        return null
    }
    @call({ url: `${BASE_URL_DITTA}/SaveDittaOpzioni` })
    static async saveDittaOpzioni(
        payload: SaveDittaOpzioniPayload,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveDittaOpzioniPayload>> | null> {
        return null
    }
    @call({ url: `${BASE_URL_DITTA}/SaveDittaInail` })
    static async saveDittaInail(
        payload: SaveDittaInailPayload,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveDittaInailPayload>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DITTA}/DeleteDitta`, method: 'delete' })
    static async delete(
        payload: BaseDeletePayload,
    ): Promise<AxiosResponse<BaseDeleteResponse> | null> {
        return null
    }
    //#endregion

    @call({ url: `${BASE_URL_DITTA}/SaveDittaImpostazioneCedolino` })
    static async saveDittaImpostazioneCedolino(
        payload: SaveDittaUPEnteFissoPayload,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveDittaImpostazioneCedolino>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DITTA}/DuplicaDittaJob` })
    static async saveDuplicaDittaJob(
        payload: SaveDuplicaDittaJobPayload,
    ): Promise<AxiosResponse<BasicResponsePayload<any>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DITTA}/AdvancedDeleteDittaJob` })
    static async saveAdvancedDeleteDittaJob(
        payload: AdvancedDeleteDittaJobPayload,
    ): Promise<AxiosResponse<BasicResponsePayload<any>> | null> {
        return null
    }
}

export default {
    get: API.get,
    delete: API.delete,
    createDitta: API.createDitta,
    updateDitta: API.updateDitta,
    saveDittaImpostazioneCedolino: API.saveDittaImpostazioneCedolino,
    saveDuplicaDittaJob: API.saveDuplicaDittaJob,
    saveAdvancedDeleteDittaJob: API.saveAdvancedDeleteDittaJob,
    saveDittaInpsEnti: API.saveDittaInpsEnti,
    saveDittaInail: API.saveDittaInail,
    saveDittaOpzioni: API.saveDittaOpzioni,
}
