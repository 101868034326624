import { defineStore } from 'pinia'
import { reactive, computed, ref, inject } from 'vue'
import { routes } from '@/routes'
import { RouteLocation, useRouter } from 'vue-router'
import { ROUTES_INDEX } from '@/routes'
import { STORES_NAMES } from '@/constants'
import { castArray } from 'lodash'

type RouteAttrTypes = null | {
    name: string
    iconFont: string | undefined
    altLabel: string | undefined
}

export const MAX_TABS = 5

const useTabStore = defineStore(
    STORES_NAMES.TAB,
    () => {
        const pinia = inject<any>('pinia')
        const router = useRouter()
        const state: TabStoreState = reactive({
            activeTab: ref(''),
            conditionalClose: async (): Promise<any> => {},
            routeRedirect: null,
            tabs: {},
        })
        const storeToExlude = [STORES_NAMES.TAB] //lista degli store da escludere preventivamente

        async function closeTab(groupName?: string) {
            function getPreviuousTab(): null | string {
                // indice tab corrente
                const index = Object.keys(state.tabs).findIndex(k => k === groupName)
                return index ? state.tabs[Object.keys(state.tabs)[index - 1]]?.GROUP : null
            }
            if (!groupName) groupName = state.activeTab
            if (groupName === ROUTES_INDEX.INDEX_BACHECA.TAB.GROUP) return
            // result: false = rimane in pagina, true = esce
            const tab = state.tabs[groupName]
            const result = tab.toSave && tab.toSaveCallback ? await tab.toSaveCallback() : true
            tab.toSave = result ? 0 : 1
            if (result) {
                const groupNameTo =
                    state.routeRedirect || getPreviuousTab() || ROUTES_INDEX.INDEX_BACHECA.TAB.GROUP
                deleteTab(groupName)
                if (state.activeTab !== groupNameTo) await goToTab(groupNameTo)
            }
            state.routeRedirect = null
        }

        /**
         * Elimina il tab dalla lista dei tabs attivi. Eventuali stores ad esso associati verranno rimossi solo se non usati da altri TAB
         * @param groupName nome del tab (gruppo) presente in state.tabs da rimuovere
         */
        function deleteTab(groupName: string) {
            if (groupName === ROUTES_INDEX.INDEX_BACHECA.TAB.GROUP) return
            if (!groupName) {
                Object.keys(state.tabs).forEach(groupName => deleteTab(groupName))
                return
            }
            // eventuale inizializzazione degli store del gruppo tab (init)
            state.tabs[groupName]?.activeStores?.forEach((idStore: string) => {
                pinia._s.get(idStore)?.init?.()
            })
            //
            removeStores(groupName) //PROVA rimozione dinamica stores
            delete state.tabs[groupName]
        }

        // Ritorna la rotta del menù
        function getRoute(codice: string, isRouteName?: boolean): any {
            let _route = null
            function findRoute(route: any[]): any {
                return route.find(item => {
                    if (
                        (castArray(item.meta?.permissions.codVoceMenu).includes(codice) &&
                            !isRouteName) ||
                        (item.name === codice && isRouteName)
                    ) {
                        _route = item
                        return true
                    } else {
                        if (item.children) return findRoute(item.children)
                        return false
                    }
                })
            }
            findRoute(routes)
            return _route
        }

        function getTab(groupName: string): null | CurrentTab {
            return groupName in state.tabs ? state.tabs[groupName] : null
        }

        async function goToTab(groupName: number | string) {
            if (typeof groupName === 'number') {
                groupName = Object.keys(state.tabs)[groupName]
                if (!groupName) return
            }
            if (state.activeTab !== groupName) {
                await router.replace({
                    name: state.tabs[groupName].currentTabRoute.name,
                    params: { ...state.tabs[groupName].currentTabRoute.props },
                })
            }
        }

        function goToTabNext() {
            let idx = Object.keys(state.tabs).findIndex(key => key === state.activeTab) + 1
            if (idx > Object.keys(state.tabs).length - 1) idx = 0
            goToTab(idx)
        }

        function goToTabPrev() {
            let idx = Object.keys(state.tabs).findIndex(key => key === state.activeTab) - 1
            if (idx < 0) idx = Object.keys(state.tabs).length - 1
            goToTab(idx)
        }

        function isTabOpen(codVoceMenu: string) {
            return Object.values(state.tabs).some(tab =>
                castArray(tab.CODVOCEMENU).includes(codVoceMenu),
            )
        }

        /**
         * Chiamata per la prima volta per la registrazione di una pagina nel tabStore. Nel caso in cui il
         * TAB fosse gia presente all'interno in tabStore vengono aggiornati alcuni dettagli che possono
         * cambiare da una pagina all'altra per il medesimo TAB (es. nome o icona del tab, oppure funzione di callback per la chiusura del tab)
         * @param routeName nome della route associata ad una data pagina (o componente)
         * @param tabProps proprieta del TAB (titolo, icona, gruppo) definite dalla singole pagine
         * @param routeProps proprieta passate nella rotta
         * @param toSaveCallback funzione chiamata per gestire la routine di chiusura associato ad un dato TAB. Viene definita nella singola pagina e aggiornata in caso di cambio tab
         * @param activeStores lista, o singolo valore, degli stores utilizzati da una determinata pagina.
         */
        async function openTab(
            routeName: string,
            tabProps: TabProps,
            routeProps: any = null,
            toSaveCallback: null | (() => Promise<any>) = null,
            activeStores: string | string[] = [], //se non viene passato nulla crea un array di string vuoto
        ): Promise<CurrentTab | null> {
            if (getTab(tabProps.GROUP)) {
                // tab già presente
                state.activeTab = tabProps.GROUP
                state.tabs[tabProps.GROUP].toSaveCallback = toSaveCallback
                state.tabs[tabProps.GROUP].TITLE = tabProps.TITLE
                addLocalActiveStores(tabProps.GROUP, activeStores) //aggiunge eventuali idStore all'array nel caso si aprono altre pagine per quel TAB
                //fix salvataggio props ad ingresso tab o dettaglio
                state.tabs[tabProps.GROUP].currentTabRoute.name = routeName
                state.tabs[tabProps.GROUP].currentTabRoute.props = routeProps
                    ? routeProps
                    : router.currentRoute.value.params
                //
                if (state.tabs[tabProps.GROUP].toSave === 2) {
                    await closeTab(tabProps.GROUP)
                }
            } else {
                if (
                    routeName &&
                    !getTab(tabProps.GROUP) &&
                    Object.keys(state.tabs).length < MAX_TABS
                ) {
                    state.tabs[tabProps.GROUP] = {
                        ...tabProps,
                        currentTabRoute: {
                            name: routeName,
                            //fix salvataggio props ad ingresso tab o dettaglio
                            props: routeProps ? routeProps : router.currentRoute.value.params,
                        },
                        toSave: 0,
                        toSaveCallback,
                        //lista con id degli store usati dal tab
                        //nel caso in cui venga passata una stringa la inserisco in un nuovo array
                        activeStores: initActiveStores(activeStores),
                        //typeof activeStores === 'string' ? [activeStores] : activeStores,
                    }
                    state.activeTab = tabProps.GROUP
                    return state.tabs[tabProps.GROUP]
                }
            }
            return null
        }

        /**
         * Restituisce un oggetto (con name e params) da essere utilizzato all'interno del router
         * per la navigazione. L'oggeto restiuito corrisponde al dettaglio aperto all'interno del
         * tab attualmente attivo (o che era attivo prima di un refresh).
         * In caso il activeTab non e' valorizzato restituisce null
         */
        function getTabNavigationProps(): null | RouteLocation {
            if (state.activeTab && state.tabs?.[state.activeTab]?.currentTabRoute?.name) {
                return {
                    name: state.tabs[state.activeTab].currentTabRoute.name,
                    params: state.tabs[state.activeTab].currentTabRoute?.props
                        ? state.tabs[state.activeTab].currentTabRoute.props
                        : {},
                }
            }
            return null
        }

        /**
         * Aggiunge gli store utilizzati dalla pagina escludendo pero' quelli presenti in storeToExlude, per evitare
         * che venga inserito, per errore, uno store legato al funzionamento dell'applicativo
         * @param storeToAdd stringa o Array di stringhe contenenti il nome dello store utilizzato dal TAB
         */
        function initActiveStores(storeToAdd: string | string[]) {
            let tempActiveStores = typeof storeToAdd === 'string' ? [storeToAdd] : storeToAdd

            return tempActiveStores.filter(el => {
                return !storeToExlude.includes(el)
            })
        }

        /**
         * Effettua il ripristino dello stato iniziale degli stores non piu utilizzati e successivamente
         * effettua il dispose e rimuove eventuali dati salvati nel localStorage.
         * @param groupName nome del tab (gruppo) presente in state.tabs
         */
        function removeStores(groupName: string) {
            getIdStoreToRemove(groupName).forEach(idStore => {
                if (pinia._s.has(idStore)) {
                    delete pinia.state.value[idStore]
                    pinia._s.get(idStore).$dispose() //unmount dello store
                }
                localStorage.removeItem(idStore) //rimozione dal localStorage
            })
        }

        /**
         * Inserisce l'id dello store per un dato tab. Se l'id e' gia' presente non viene aggiunto.
         * @param groupName nome del tab (gruppo)  presente in state.tabs
         * @param activeStores stringa o array di stringhe che rappresentano gli id degli store utilizzati da un determinato TAB o pagina
         */
        function addLocalActiveStores(groupName: string, activeStores: string | string[] = []) {
            if (Array.isArray(activeStores)) {
                activeStores.forEach(idStore => {
                    //aggiunge eventuali idStore all'array nel caso si aprono altre pagine per quel TAB
                    addLocalActiveStore(groupName, idStore)
                })
            }
            if (typeof activeStores === 'string' && activeStores.length > 0) {
                state.tabs[groupName].activeStores = []
                addLocalActiveStore(groupName, activeStores)
            }
        }

        /**
         * Inserisce l'id dello store per un dato tab. Se l'id e' gia' presente non viene aggiunto.
         * @param groupName nome del tab (gruppo) presente in state.tabs da rimuovere
         * @param idStore id dello store utilizzato da quel tab
         */
        function addLocalActiveStore(groupName: string, idStore: string) {
            if (storeToExlude.includes(idStore)) {
                return
            }

            if (groupName in state.tabs) {
                //inserisco l'id dello store SE NON e presente in activeStores per un dato Tab
                if (!state.tabs[groupName].activeStores.find(element => element === idStore)) {
                    state.tabs[groupName].activeStores.push(idStore)
                }
            }
        }

        /**
         * Ottiene la lista degli idStore da rimuovere passando il nome del gruppo del tab aperto.
         * Gli store in comune non vengono inclusi nella lista.
         * @param groupNameToRemove nome del tab (gruppo)  presente in state.tabs
         */
        function getIdStoreToRemove(groupNameToRemove: string) {
            const localActiveStores = state.tabs[groupNameToRemove].activeStores
            let idStoresToRemove = localActiveStores //array degl idStore da rimuovere, inizialmente coincide con localActiveStores

            for (const groupName in state.tabs) {
                if (idStoresToRemove.length === 0) break //caso non ci sono store da rimuovere
                if (groupName === groupNameToRemove) continue

                //per ogni idStore di un tab aperto controllo se non venga utilizzato da uno degli store da rimuovere
                state.tabs[groupName].activeStores.forEach(idStore => {
                    idStoresToRemove = idStoresToRemove.filter(
                        idStoreToRemove => idStoreToRemove !== idStore,
                    )
                })
            }

            return idStoresToRemove
        }

        function setSave(tabProps: TabProps, value: boolean) {
            if (!tabProps || !tabProps.GROUP) return
            if (state.tabs[tabProps.GROUP] && !(state.tabs[tabProps.GROUP]?.toSave == 2)) {
                state.tabs[tabProps.GROUP].toSave = value ? 1 : 0
                //per evitare che venga cambiato dalla pagina quando il valore e' 2 (ovvero comando di chiusura)
            }
        }

        // Cambio tab attiva: aggiorna ultima rotta tab in uscita
        function updateTab(
            routeName: string,
            tabProps: TabProps,
            routeProps: any = {},
            toSaveCallback: null | (() => Promise<any>) = null,
        ) {
            state.tabs[tabProps.GROUP] = {
                ...tabProps,
                activeStores: state.tabs[tabProps.GROUP].activeStores,
                currentTabRoute: { name: routeName, props: routeProps },
                toSave: state.tabs[tabProps.GROUP].toSave,
                toSaveCallback,
            }
        }

        /**
         * Controlla se un tab è presente all'interno della lista dei tab attivi
         *
         * @param tabName - Nome del tab (gruppo) da controllare
         */
        function checkTabsExist(tabName: string): boolean {
            return tabName in state.tabs
        }

        const getLastIndex = computed(() => state.tabs.length - 1)

        function addMenuVoice(options = { label: null, routeName: null, iconFont: null }) {
            // if (!options.label || !options.routeName) {
            //     console.warn(
            //         'Attenzione. Bisogna passare oggetto con label e routeName settati ' +
            //             '(per la routeName rifarsi ai file nella cartella router)',
            //     )
            //     return
            // }
            // if (state.tabs.some((item: TabPage) => options.label === item.label)) return
            // state.tabs.push({
            //     altLabel: null,
            //     iconFont: options.iconFont,
            //     label: options.label,
            //     routeName: options.routeName,
            // })
            // state.activeTab = state.tabs.length - 1
        }

        function getRouteAttrByName(routeName: string, menuCollection: Route[]): RouteAttrTypes {
            let routeAttr = null
            // function setRouteAttr(route: Route) {
            //     routeAttr = {
            //         name: route.name,
            //         iconFont: route.meta?.iconFont,
            //         altLabel: route.meta?.altLabel,
            //     }
            // }
            // function findRoute(route: Route[]) {
            //     route.find(parent => {
            //         if (parent.name === routeName) {
            //             setRouteAttr(parent)
            //             return true
            //         } else {
            //             if (parent.children) return findRoute(parent.children)
            //         }
            //         return false
            //     })
            // }
            // findRoute(menuCollection)
            return routeAttr
        }

        async function removeMenuVoice(
            routeName: string,
        ): Promise<undefined | { pushNewPage: boolean; routeName?: string }> {
            // let closeResult = await removeMenuVoiceNew(routeName)
            // let force = closeResult.force
            // if (force && !closeResult.close)
            //     //nuova logica e non devo chiudere
            //     return
            // if (state.tabs[state.activeTab].routeName !== routeName && !force) return
            // if (!force) {
            //     if (!(await state.conditionalClose())) return
            // }
            // //!case: tabToClose is active tab
            // if (state.tabs[state.activeTab].routeName == routeName) {
            //     let newRouteName = null
            //     //?tab to close is lastTab
            //     if (state.activeTab === state.tabs.length - 1) {
            //         newRouteName = state.tabs[state.activeTab - 1].routeName
            //         state.tabs = state.tabs.filter(el => el.routeName !== routeName)
            //         state.activeTab = state.tabs.length - 1
            //         return { pushNewPage: true, routeName: newRouteName }
            //     }
            //     //?tab to close is not lastTab
            //     newRouteName = state.tabs[state.activeTab - 1].routeName
            //     state.activeTab -= 1
            //     state.tabs = state.tabs.filter(el => el.routeName != routeName)
            //     return { pushNewPage: true, routeName: newRouteName }
            // }
            // state.tabs = state.tabs.filter(el => el.routeName != routeName)

            return { pushNewPage: false }
        }

        function updateRouteName(label: string, routeName: string) {
            // getRouteAttrByName(routeName, routes as any)
            // state.activeTab = state.tabs.findIndex(e => e.label === label)
        }

        /**
         * Cerca se il nome dell'elemento e' presente all'interno di uno dei TAB aperti.
         * Torna TRUE se viene trovato,, altrimenti FALSE
         * @param name nome della pagina da cercare all'interno delle varie Tab aperte (state.tabs)
         */
        function nameInTabs(name: string) {
            let result = false

            //getGroupFromName(name)
            //devo controllare che il nome passato non rientri in un tab gia aperto!

            Object.keys(state.tabs).every(groupName => {
                let currentTabRouteName = state.tabs[groupName].currentTabRoute.name
                result = currentTabRouteName === name
                if (result) {
                    return false
                }
                return true //continua a ciclare
            })

            let obj = Object.keys(state.tabs).filter(groupName => {
                let currentTabRouteName = state.tabs[groupName].currentTabRoute.name
                return (result = currentTabRouteName === name)
                // if (result) {
                //     return false
                // }
                // return true //continua a ciclare
            })

            let ris = { result: obj.length == 1, element: state.tabs[obj] }

            return ris
        }

        /**
         * Cerca se il nome dell'elemento e' presente all'interno di uno dei TAB aperti.
         * Torna TRUE se viene trovato,, altrimenti FALSE
         * @param groupName nome del gruppo da cercare all'interno delle varie Tab aperte (state.tabs)
         */
        function groupInTabs(groupName: string) {
            const obj = state.tabs[groupName]

            let ris = { result: obj != undefined || obj != null, element: obj }

            return ris
        }

        /**
         * Cerca se il nome di una pagina o dettaglio e' presente all'interno di un determinato TAB.
         * Torna TRUE se viene trovato,, altrimenti FALSE
         * @param groupName nome del gruppo da cercare all'interno delle varie Tab aperte (state.tabs)
         * @param childName nome del dettaglio da cercare nel tab specifico
         */
        function detailInGroup(groupName: string, childName: string) {
            const objResult = groupInTabs(groupName)
            let result = false //{ result: obj != undefined || obj != null, element: obj }

            if (!objResult.result) return result

            if (objResult?.element?.currentTabRoute?.name === childName) {
                result = true
            }

            return result
        }

        /**
         * Ritorna TRUE se il numero dei TAB aperti e' inferiore al limite consentito definito in MAX_TABS
         */
        const grantOpenTab = computed(() => {
            return Object.keys(state.tabs).length < MAX_TABS
        })

        return {
            closeTab,
            checkTabsExist,
            deleteTab,
            state,
            addMenuVoice,
            getRoute,
            getRouteAttrByName,
            getTab,
            goToTab,
            goToTabNext,
            goToTabPrev,
            removeMenuVoice,
            updateRouteName,
            getLastIndex,
            openTab,
            updateTab,
            isTabOpen,
            setSave,
            addLocalActiveStores,
            grantOpenTab,
            groupInTabs,
            detailInGroup,
            getTabNavigationProps,
        }
    },
    {
        persist: {
            storage: sessionStorage,
        },
    },
)

export default useTabStore
