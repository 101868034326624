import { http } from '@/scripts/lib/http'
import { useGlobalStore } from '@/stores'

const BASE_URL = '/Schedulazione'

const payload: Payload = {
    takeLastJobsFinished: 10,
}

export default class APISchedulazione {
    static async get(params?: Payload) {
        const globalStore = useGlobalStore()
        try {
            return http.post(`${BASE_URL}/GetExecutionJobs`, {
                ...payload,
                ...{
                    periodoDiRiferimento: globalStore.state.periodoElab,
                },
                ...params,
            })
        } catch (error) {
            console.log(error)
        }
    }

    static async action(apiUrl: string, params?: Payload) {
        const globalStore = useGlobalStore()
        try {
            return http.post(`${BASE_URL}/${apiUrl}`, {
                ...payload,
                ...{
                    periodoDiRiferimento: globalStore.state.periodoElab,
                },
                ...params,
            })
        } catch (error) {
            console.log(error)
        }
    }
    static async getSchedulerOutputFile(params?: Payload) {
        const globalStore = useGlobalStore()
        try {
            return http.post(`${BASE_URL}/GetSchedulerOutputFile`, {
                ...params,
            })
        } catch (error) {
            console.log(error)
        }
    }
}
