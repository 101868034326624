import { cloneDeep } from 'lodash'
import { computed, ref, shallowReactive, reactive, watch } from 'vue'

const UP = reactive(new Map())
const active = ref(false)

export function useGestUP(store: any, _baseKey: string = '') {
    const set = (data: any, key: string) => {
        if (UP.has(key)) return

        const active = !!data.padre ? 'child' : 'parent'
        const isChild = !!data.padre
        const child = !!data.padre ? data[key] : undefined
        const parent = !!data.padre ? data.padre[key] : data[key]

        UP.set(key, { child, parent, active, isChild })

        const finalData = get(key)

        setDataHeader({
            dataInizio: finalData?.dataInizio ? finalData?.dataInizio : '',
            dataFine: finalData?.dataFine ? finalData?.dataFine : '',
        })
    }

    const get = (key: string) => {
        const data = UP.get(key)
        const active = data.active === 'child' && !!data.child ? 'child' : 'parent'
        return data[active]
    }

    const isActive = ref(cloneDeep(active.value))

    watch(isActive, (v: boolean) => (active.value = v))

    function setDataHeader(entity: any = null) {
        if (entity && store?.headerDate) {
            store.headerDate.inizio = entity?.dataInizio
            store.headerDate.fine = entity?.dataFine
        }
    }

    return {
        set,
        get,
        isActive,
    }
}
