import { http } from '@/scripts/lib/http'
import { useGlobalStore } from '@/stores'

const BASE_URL = '/Contratto'

const payload: ContrattoPayload = {
    requireTotalCount: false,
    isCountQuery: false,
}

export default class APIContratto {
    static async get(params?: ContrattoPayload, endpoint = 'GetContratti') {
        const globalStore = useGlobalStore()
        return http.post(`${BASE_URL}/${endpoint}`, {
            ...payload,
            ...{
                periodoDiRiferimento: globalStore.state.periodoElab,
            },
            ...params,
        })
    }

    static async save(endpoint: string, params?: { [key: string]: any }) {
        const globalStore = useGlobalStore()
        return http.post(`${BASE_URL}/${endpoint}`, {
            ...{
                periodoDiRiferimento: globalStore.state.periodoElab,
            },
            ...params,
        })
    }

    static async getCodiciLivelloContrattuale(params?: { [key: string]: any }) {
        return await this.get(params, 'GetContrattoElencoCodiciLivelloContrattuale')
    }

    static async GetContrattoElencoCodiciApprendistato(params?: { [key: string]: any }) {
        return await this.get(params, 'GetContrattoElencoCodiciApprendistato')
    }

    static async GetContrattoElencoCodiciLivelloContrattuale(params?: { [key: string]: any }) {
        return await this.get(params, 'GetContrattoElencoCodiciLivelloContrattuale')
    }

    static async GetContrattoElencoCodiciProgressioneRetributiva(params?: { [key: string]: any }) {
        return await this.get(params, 'GetContrattoElencoCodiciProgressioneRetributiva')
    }

    static async getCodiciProgressioneRetributiva(params?: { [key: string]: any }) {
        return await this.get(params, 'GetContrattoElencoCodiciProgressioneRetributiva')
    }

    static async getCodiciApprendistato(params?: { [key: string]: any }) {
        return await this.get(params, 'GetContrattoElencoCodiciApprendistato')
    }
    static async GetContrattoElencoCodiciElementiPaga(params?: { [key: string]: any }) {
        return await this.get(params, 'GetContrattoElencoCodiciElementiPaga')
    }
    static async GetAlterazioneElencoCodiciElementiPaga(params?: { [key: string]: any }) {
        return await this.get(params, 'GetAlterazioneElencoCodiciElementiPaga')
    }
    static async GetContrattoElencoEntiPrevisti(params?: { [key: string]: any }) {
        return await this.get(params, 'GetElencoEntiPrevisti')
    }
    static async GetInfoDittaByIdDittaOrIdDipendente(params?: { [key: string]: any }) {
        return await this.get(params, 'GetInfoDittaByIdDittaOrIdDipendente')
    }

    static async SaveContrattoStraordinario(params?: { [key: string]: any }) {
        return await this.save('SaveContrattoStraordinario', params)
    }

    static async SaveContrattoDivisore(params?: { [key: string]: any }) {
        return await this.save('SaveContrattoDivisore', params)
    }
    static async SaveContrattoFestivita(params?: { [key: string]: any }) {
        return await this.save('SaveContrattoFestivita', params)
    }
    static async SaveContrattoIstitutoContrattuale(params?: { [key: string]: any }) {
        return await this.save('SaveContrattoIstitutoContrattuale', params)
    }
    static async SaveContrattoFerieExFestRol(params?: { [key: string]: any }) {
        return await this.save('SaveContrattoFerieExFestRol', params)
    }

    static async SaveContrattoMensilitaAggiuntiva(params?: { [key: string]: any }) {
        return await this.save('SaveContrattoMensilitaAggiuntiva', params)
    }

    static async SaveContrattoInfortunio(params?: { [key: string]: any }) {
        return await this.save('SaveContrattoInfortunio', params)
    }
    static async SaveContrattoMalattia(params?: { [key: string]: any }) {
        return await this.save('SaveContrattoMalattia', params)
    }
    static async SaveContrattoEnte(params?: { [key: string]: any }) {
        return await this.save('SaveContrattoEnte', params)
    }
    static async SaveAlteraUnaTantum(params?: { [key: string]: any }) {
        return await this.save('SaveAlteraUnaTantum', params)
    }
    static async SaveContrattoMaternita(params?: { [key: string]: any }) {
        return await this.save('SaveContrattoMaternita', params)
    }
    static async SaveAlteraElePaga(params?: { [key: string]: any }) {
        return await this.save('SaveAlteraElePaga', params)
    }
    static async SaveAlteraValorizzaElePaga(params?: { [key: string]: any }) {
        return await this.save('SaveAlteraValorizzaElePaga', params)
    }
    static async SaveContrattoProgrRetrib(params?: { [key: string]: any }) {
        return await this.save('SaveContrattoProgrRetrib', params)
    }
}
