/* import * as childs from './childs'*/
const duplicaDittaUp = () => import('./utility/duplica/ditta/Duplica.vue')
const duplicaLavoratori = () => import('./utility/duplica/lavoratore/Duplica.vue')
const eliminaDittaUp = () => import('./utility/elimina/ditta/Elimina.vue')
const eliminaLavoratori = () => import('./utility/elimina/lavoratore/Elimina.vue')
// Create an array of key-value pairs for the Map constructor
const modalsArray: any = [
    ['000191', duplicaDittaUp],
    ['000192', duplicaLavoratori],
    ['000193', eliminaDittaUp],
    ['000194', eliminaLavoratori],
]

// Initialize the Map with the correct type
export const mapModals = new Map(modalsArray)
