import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'
import { BASE_URL_DITTA } from './ditta'
export const BASE_URL_DITTA_CIG = '/DittaAutorizzazioneCig'

class API {
    @call({ url: `${BASE_URL_DITTA_CIG}/GetDittaAutorizzaCig` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DittaAutorizzaCig>> | null> {
        return null
    }
    @call({ url: `${BASE_URL_DITTA}/SaveDittaAutorizzaCig` })
    static async save(
        payload: SaveDittaAutorizzaCig,
    ): Promise<AxiosResponse<BasicResponseData<DittaAutorizzaCig>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
