import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'
import { BASE_URL_DIPTE } from './dipte'

const BASE_URL = '/DipteStorico'

class API {
    @call({ url: `${BASE_URL}/GetDatiStoriciDipte` })
    static async getDatiStorici(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponsePayload<DipteStorico>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/GetDipteStorico` })
    static async getDipteStorico(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponsePayload<GetDipteStoricoResponse>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DIPTE}/SaveDatiStoriciDipte` })
    static async saveDatiStoriciDipte(
        payload: SaveDatiStoriciDiptePayload,
    ): Promise<AxiosResponse<BasicResponseData<DatiStoriciDipte>> | null> {
        return null
    }
}

export default {
    getDatiStorici: API.getDatiStorici,
    getDipteStorico: API.getDipteStorico,
    saveDatiStoriciDipte: API.saveDatiStoriciDipte,
}
