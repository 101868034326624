import { http } from '@/scripts/lib/http'
import { useGlobalStore } from '@/stores'

const BASE_URL = '/Formulario'

const httpMethods = new Map([['CheckValiditaFormula', http.post]])

function getPayload(payload: FormularioPayload): FormularioPayload {
    const globalStore = useGlobalStore()
    return {
        isCountQuery: false,
        requireTotalCount: false,
        periodoDiRiferimento: { ...globalStore.state.periodoElab },
        ...payload,
    } as FormularioPayload
}

function call(url: string) {
    return function (target: any, key: string, descriptor: PropertyDescriptor) {
        descriptor.value = async (payload: FormularioPayload = {}) => {
            try {
                const _http = httpMethods.get(url)
                return _http!(`${BASE_URL}/${url}`, getPayload(payload))
            } catch (error) {
                console.log(error)
            }
        }
        return descriptor
    }
}

export class APIFormulario {
    @call('CheckValiditaFormula')
    static check(payload: FormularioPayload): any {}
}
