import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

const BASE_URL = '/VSedeInps'

class API {
    @call({ url: `${BASE_URL}/GetVSedeInps` })
    static async get<T extends Payload>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponseData> | null> {
        return null
    }
}

export default {
    get: API.get,
}
