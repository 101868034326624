import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

const BASE_URL_BANCHE = '/DittaBanca'

class API {
    @call({ url: `${BASE_URL_BANCHE}/GetDitteBanca` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DittaBanca>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_BANCHE}/SaveDittaBanca` })
    static async save(
        payload: SaveDittaBanchePayload,
    ): Promise<AxiosResponse<BasicDittaResponsePayload<DittaBanca>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
