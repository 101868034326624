import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

export const BASE_URL_DITTA = '/Organico'

class API {
    @call({ url: `${BASE_URL_DITTA}/CalcolaOrganicoContatoriDitta` })
    static async get(
        payload: GetOrganicoPayload,
    ): Promise<AxiosResponse<BasicResponsePayload<Organico>> | null> {
        return null
    }
}

export default {
    get: API.get,
}
