import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

const BASE_URL_UP_ATTIVITA = '/DittaUPAttivita'

class API {
    @call({ url: `${BASE_URL_UP_ATTIVITA}/GetDitteUPAttivita` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DittaUPAttivita>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_UP_ATTIVITA}/DeleteDittaUPAttivita`, method: 'delete' })
    static async delete(payload: {
        id: number
    }): Promise<AxiosResponse<BasicDittaResponsePayload<DittaUPAttivita>> | null> {
        return null
    }
}

export default {
    get: API.get,
    delete: API.delete,
}
