import { SocioLista } from '@/views/Paghe/Anagrafiche/Socio/Lista'
import { SocioDettaglio, Childs } from '@/views/Paghe/Anagrafiche/Socio/Dettaglio'

const tabPropsDefault: TabProps = {
    TITLE: 'Anagrafica Socio',
    ICON: 'fa-regular fa-users-between-lines',
    GROUP: 'anagraficaSocio',
}

export const ROUTES_SOCIO = Object.freeze({
    SOCIO_LISTA: {
        TITLE: 'Elenco soci - Socio - Anagrafici - Gestione',
        LABEL: 'Elenco soci',
        PATH: 'socio/:year/:month/:dittaId/:dittaPadreId',
        NAME: 'SocioLista',
        TAB: { ...tabPropsDefault },
    },
    SOCIO_DETTAGLIO: {
        TITLE: 'Dettaglio socio - Socio - Anagrafici - Gestione - Paghe',
        LABEL: 'Dettaglio socio',
        PATH: 'socio/:year/:month/:day/:dittaId/:dittaPadreId/:id/:mod',
        NAME: 'SocioDettaglio',
        TAB: { ...tabPropsDefault },
    },
    SOCIO_ANAGRAFICA: {
        TITLE: null,
        LABEL: 'Dati anagrafici',
        PATH: 'anagrafica',
        NAME: 'SocioAnagrafica',
        TAB: { ...tabPropsDefault },
    },
})

export default [
    {
        path: ROUTES_SOCIO.SOCIO_LISTA.PATH,
        name: ROUTES_SOCIO.SOCIO_LISTA.NAME,
        component: SocioLista,
        meta: {
            title: ROUTES_SOCIO.SOCIO_LISTA.TITLE,
            permissions: {
                codPermesso: 'ANAG_SOCI',
                codVoceMenu: null,
            },
            tabGroup: tabPropsDefault.GROUP,
        },
        props: true,
    },
    {
        path: ROUTES_SOCIO.SOCIO_DETTAGLIO.PATH,
        name: ROUTES_SOCIO.SOCIO_DETTAGLIO.NAME,
        props: true,
        component: SocioDettaglio,
        meta: {
            title: ROUTES_SOCIO.SOCIO_DETTAGLIO.TITLE,
            permissions: {
                codPermesso: 'ANAG_SOCI',
                codVoceMenu: null,
            },
            tabGroup: tabPropsDefault.GROUP,
        },
        children: [
            {
                label: ROUTES_SOCIO.SOCIO_ANAGRAFICA.LABEL,
                path: ROUTES_SOCIO.SOCIO_ANAGRAFICA.PATH,
                name: ROUTES_SOCIO.SOCIO_ANAGRAFICA.NAME,
                tab: ROUTES_SOCIO.SOCIO_ANAGRAFICA.TAB,
                orderWizard: 0,
                component: Childs.SocioAnagrafica,
                meta: {
                    group: 'SocioDettaglio',
                    permissions: {
                        codPermesso: 'ANAG_SOCI',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
        ],
    },
]
