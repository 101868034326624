export const payloads = {
    GetIntermediari: {
        requireTotalCount: true,
        isCountQuery: false,
        skip: 0,
        take: 0,
        sort: '[]',
        filter: '[]',
        select: '[]',
        include: '[]',
        periodoDiRiferimento: {
            year: 0,
            month: 0,
        },
    },
    DeleteIntermediario: {
        id: 0,
        periodoDiRiferimento: {
            year: 0,
            month: 0,
        },
        isNuovaValidita: false,
    },
    GetIntermediariUniemen: {
        requireTotalCount: true,
        isCountQuery: false,
        skip: 0,
        take: 100,
        sort: '[]',
        filter: '[]',
        select: '[]',
        include: '[]',
        periodoDiRiferimento: {
            year: 0,
            month: 0,
            day: 0,
        },
    },
    CreateIntermediarioUniemen: {
        id: 0,
        codSedeInps: '',
        numeroAutorizzazione: '',
        codTipoMittente: '',
        codiceFiscaleMittente: '',
        codiceFiscalePfmittente: '',
        codiceConsulenteCassaEdile: '',
        idIntermediario: 0,
    },
    UpdateIntermediarioUniemen: {
        id: 0,
        codSedeInps: '',
        numeroAutorizzazione: '',
        codTipoMittente: '',
        codiceFiscaleMittente: '',
        codiceFiscalePfmittente: '',
        codiceConsulenteCassaEdile: '',
        idIntermediario: 0,
    },
    CreateIntermediario: {
        periodoDiRiferimento: {
            year: 0,
            month: 0,
            day: 0,
        },
        isNuovaValidita: false,
        nomeStudio: '',
        indirizzoStudio: '',
        capStudio: '',
        partitaIvaStudio: '',
        telefonoStudio: '',
        codiceFiscaleStudio: '',
        emailStudio: '',
        cognomePfstudio: '',
        nomePfstudio: '',
        cognomeConsulente: '',
        nomeConsulente: '',
        sessoConsulente: '',
        dataNascitaConsulente: '',
        codiceFiscaleConsulente: '',
        codAlboCons: '',
        numAlboCons: '',
        dataAlboCons: '',
        codiceUtenteMigrazione: 0,
        idComuneNascitaConsulente: 0,
        idComuneStudio: 0,
    },
    UpdateIntermediario: {
        periodoDiRiferimento: {
            year: 0,
            month: 0,
            day: 0,
        },
        id: 0,
        isNuovaValidita: false,
        nomeStudio: '',
        indirizzoStudio: '',
        capStudio: '',
        partitaIvaStudio: '',
        telefonoStudio: '',
        codiceFiscaleStudio: '',
        emailStudio: '',
        cognomePfstudio: '',
        nomePfstudio: '',
        cognomeConsulente: '',
        nomeConsulente: '',
        sessoConsulente: '',
        dataNascitaConsulente: '',
        codiceFiscaleConsulente: '',
        codAlboCons: '',
        numAlboCons: '',
        dataAlboCons: '',
        codiceUtenteMigrazione: 0,
        idComuneNascitaConsulente: 0,
        idComuneStudio: 0,
    },
    GetComuni: {
        requireTotalCount: true,
        isCountQuery: false,
        skip: 0,
        sort: '[]',
        filter: '[]',
        select: '[]',
        include: '[]',
        periodoDiRiferimento: {
            year: 2022,
            month: 2,
        },
    },
    GetVCodDes: {
        requireTotalCount: true,
        isCountQuery: false,
        skip: 0,
        sort: '[]',
        filter: '[]',
        select: '[]',
        include: '[]',
    },
    GetVSedeInps: {
        requireTotalCount: false,
        isCountQuery: false,
        skip: 0,
        sort: '[]',
        filter: '[]',
        select: '[]',
        include: '[]',
    },
    GetIntermediariInailLul: {
        requireTotalCount: true,
        isCountQuery: false,
        skip: 0,
        take: 0,
        sort: '[]',
        filter: '[]',
        select: '[]',
        include: '[]',
        periodoDiRiferimento: {
            year: 0,
            month: 0,
            day: 0,
        },
    },
    DeleteIntermediarioInailLul: {
        id: 0,
        periodoDiRiferimento: {
            year: 0,
            month: 0,
        },
        isNuovaValidita: false,
    },
    CreateIntermediarioInailLul: {
        periodoDiRiferimento: {
            year: 0,
            month: 0,
            day: 0,
        },
        isNuovaValidita: false,
        numeroAutorizzazione: '',
        dataAutorizzazione: '',
        numAutorizzaLaserDipti: '',
        dataAutorizzaLaserDipti: '',
        numAutorizzaLaserColl: '',
        dataAutorizzaLaserColl: '',
        idIntermediario: 0,
    },
    UpdateIntermediarioInailLul: {
        periodoDiRiferimento: {
            year: 0,
            month: 0,
            day: 0,
        },
        isNuovaValidita: false,
        id: 0,
        numeroAutorizzazione: '',
        dataAutorizzazione: '',
        numAutorizzaLaserDipti: '',
        dataAutorizzaLaserDipti: '',
        numAutorizzaLaserColl: '',
        dataAutorizzaLaserColl: '',
        idIntermediario: 0,
    },
    GetIntermediariFiscale: {
        requireTotalCount: true,
        isCountQuery: false,
        skip: 0,
        take: 0,
        sort: '[]',
        filter: '[]',
        select: '[]',
        include: '[]',
        periodoDiRiferimento: {
            year: 0,
            month: 0,
            day: 0,
        },
    },
    UpdateIntermediarioFiscale: {
        isNuovaValidita: false,
        id: 0,
        codTipoFornitore770Cu: '',
        codTipoSoggetto: '',
        codiceFiscaleFornitore: '',
        indirizzoFiscale: '',
        capFiscale: '',
        isInvioRicezAvvisoTelem770Cu: false,
        isFirmaDichiarante770Cu: false,
        isFirmaIncaricato770Cu: false,
        isImpegnoCumulativo770Cu: false,
        dataImpegno770: '',
        dataImpegnoCu: '',
        dataStampaCu: '',
        codiceFiscaleIntermediario: '',
        mailIntermediario: '',
        cellulareIntermediario: '',
        sedeEntratel: '',
        telefonoIntermediario: '',
        f24abi: '',
        f24cab: '',
        f24conto: '',
        f24cin: '',
        f24sia: '',
        isF24avvenutoPagamento: false,
        f24siaveic: '',
        codiceFiscaleTitolareCC: '',
        idComuneFiscale: 0,
        idIntermediario: 0,
        periodoDiRiferimento: {
            year: 0,
            month: 0,
            day: 0,
        },
    },
    CreateIntermediarioFiscale: {
        isNuovaValidita: false,
        id: 0,
        codTipoFornitore770Cu: '',
        codTipoSoggetto: '',
        codiceFiscaleFornitore: '',
        indirizzoFiscale: '',
        capFiscale: '',
        isInvioRicezAvvisoTelem770Cu: false,
        isFirmaDichiarante770Cu: false,
        isFirmaIncaricato770Cu: false,
        isImpegnoCumulativo770Cu: false,
        dataImpegno770: '',
        dataImpegnoCu: '',
        dataStampaCu: '',
        codiceFiscaleIntermediario: '',
        mailIntermediario: '',
        cellulareIntermediario: '',
        sedeEntratel: '',
        telefonoIntermediario: '',
        f24abi: '',
        f24cab: '',
        f24conto: '',
        f24cin: '',
        f24sia: '',
        isF24avvenutoPagamento: false,
        f24siaveic: '',
        codiceFiscaleTitolareCC: '',
        idComuneFiscale: 0,
        idIntermediario: 0,
        periodoDiRiferimento: {
            year: 0,
            month: 0,
            day: 0,
        },
    },
    GetBanche: {
        requireTotalCount: false,
        isCountQuery: false,
        skip: 0,
        take: 0,
        sort: '[]',
        filter: '[]',
        select: '[]',
        include: '[]',
        periodoDiRiferimento: {
            year: 0,
            month: 0,
            day: 0,
        },
    },
    UpdateStudio: {
        isNuovaValidita: false,
        id: 0,
        dataDelete: '',
        codice: 0,
        denominazione: '',
        studiGenerali: [
            {
                id: 0,
                isArrotondaNettoInBusta: false,
                isDetrazioneIrpef_1_12: false,
                isStampaCuCessatiMese: false,
                isNoAppredistati100: false,
                isControlloIndennitaIstituti: false,
                isFestivitaNonGodutaTfr: false,
                isFerieExFestRolCessatiTfr: false,
                isTfr15gg: false,
                isCarenzaLegaleInfortunio: false,
                isSegreteriaCodApe: false,
                isNoFoglioPresenze: false,
                codGestMensilizzatiAssCess: '',
                codOpzioneCosto: '',
                isNoDataOraStampe: false,
                isFiligranaSuCalcola: false,
                isNoStampaIntermittente0Ore: false,
                isStampaNomeStudioCedolino: false,
                isFirmaPerRicevuta: false,
                isNoCcnlnelCedolino: false,
                codEmailCedolinoLul: '',
                codFormatoCedolinoDipte: '',
                codFormatoCedolinoColl: '',
                isDiarioAnagrafici: false,
                isDiarioDatiElaborazione: false,
                passwordDefaultPdf: '',
                idIntermediario: 0,
                idStudio: 0,
            },
        ],
    },
    GetStudiGenerale: {
        requireTotalCount: true,
        isCountQuery: false,
        skip: 0,
        take: 0,
        sort: '[]',
        filter: '[]',
        select: '[]',
        include: '[]',
        periodoDiRiferimento: {
            year: 0,
            month: 0,
            day: 0,
        },
    },
    GetDitte: {
        requireTotalCount: false,
        isCountQuery: false,
        // skip: 0,
        // take: 0,
        // sort: "[]",
        // filter: "[]",
        // select: "[]",
        // include: "[]",
        // periodoDiRiferimento: {
        //     year: 0,
        //     month: 0,
        // },
    },

    GetDipendenti: {
        requireTotalCount: true,
        isCountQuery: false,
        // skip: 0,
        // take: 0,
        // sort: "[]",
        // filter: "[]",
        // select: "[]",
        // include: "[]",
        // periodoDiRiferimento: {
        //     year: 0,
        //     month: 0,
        // },
    },

    UpdateStudioGenerale: {
        //da definire
    },
    GetVoci: {
        requireTotalCount: true,
        isCountQuery: false,
        skip: 0,
        take: 0,
        sort: '[]',
        filter: '[]',
        select: '[]',
        include: '[]',
        periodoDiRiferimento: {
            year: 0,
            month: 0,
            day: 0,
        },
    },
    UpdateVoce: {
        isNuovaValidita: false,
        id: 0,
        codice: '',
        descrizione: '',
        codGruppo: '',
        tipoVoce: '',
        codComunicazioneMovimenti: '',
        isAutomatica: false,
        codFormulaComunicata: '',
        codFormulaAutomatica: '',
        codTipoDatoBase: '',
        validitaComunicaAuto: '',
        isPrefisso: false,
        isSuffisso: false,
        isVoceMultipla: false,
        isMaggiorazioneCUnitario: false,
        codStampaSuCedolino: '',
        codPrioritaCalcolo: '',
        codTipoInfoAgg: '',
        isMultiCCNoElabPeriodo: false,
        isMultiCCCaricaPeriodo: false,
        codMultiCCTipoQuadratura: '',
        codUniEmens: '',
        uniEmensSuffisso: '',
        codVoceCreaVoceCodice: '',
        codVoceCreaVoceCodice2: '',
        isVoceCreaVoceDb: false,
        isVoceCreaVoceCu: false,
        isVoceCreaVocePerc: false,
        isVoceCreaVoceImp: false,
        periodoDiRiferimento: {
            year: 0,
            month: 0,
            day: 0,
        },
    },
    CreateDitta: {
        //isNuovaValidita: false,
        //codiceDitta: "",
        //unitaProduttiva: "",
        //idPadre: null,
        //codiceDittaOrigine: "",
        //upOrigine: "",
        //dittaGenerale: {
        //	//id: 0,
        //	tipoDitta: "",
        //	codiceFiscale: "",
        //	partitaIva: "",
        //	dataCostituzione: "",
        //	dataCessazione: "",
        //	cognome: "",
        //	nome: "",
        //	sesso: "",
        //	dataNascita: "",
        //	cittadinanza: "",
        //	//idComuneNascita: 0,
        //	//idDitta: 0
        //},
        //dittaUPRagioneSociale: {
        //	//id: 0,
        //	//dataInizio: "2022-06-24T07:43:00.061Z",
        //	//dataFine: "2022-06-24T07:43:00.061Z",
        //	ragioneSociale: "",
        //	denominazioneAggiuntiva: "",
        //	//idDitta: 0
        //}
        //,
        //dittaUPAttivita: {
        //	//id: 0,
        //	//dataInizio: "2022-06-24T07:43:00.061Z",
        //	//dataFine: "2022-06-24T07:43:00.061Z",
        //	indirizzoAttivita: "",
        //	capAttivita: "",
        //	telefonoAttivita: "",
        //	descrizioneAttivita: "",
        //	rifUtente: "",
        //	isIndirizzoPerCU: true,
        //	fax: "",
        //	email: "",
        //	pecEmail: "",
        //	cellulare: "",
        //	sedeCU: "",
        //	idComuneAttivita: 0,
        //	idIntermediarioLul: 0,
        //	idIntermediarioComunicazioni: 0,
        //	//idDitta: 0
        //}
    },
    UpdateDitta: {
        //isNuovaValidita: false,
        //codiceDitta: "",
        //unitaProduttiva: "",
        ////idPadre: null,
        //codiceDittaOrigine: "",
        //upOrigine: "",
        //dittaGenerale: {
        //	id: 0,
        //	tipoDitta: "",
        //	codiceFiscale: "",
        //	partitaIva: "",
        //	dataCostituzione: "",
        //	dataCessazione: "",
        //	cognome: "",
        //	nome: "",
        //	sesso: "",
        //	dataNascita: "",
        //	cittadinanza: "",
        //	idComuneNascita: 0,
        //	idDitta: 0
        //},
        //dittaUPRagioneSociale: {
        //	id: 0,
        //	//dataInizio: "2022-06-24T07:43:00.061Z",
        //	//dataFine: "2022-06-24T07:43:00.061Z",
        //	ragioneSociale: "",
        //	denominazioneAggiuntiva: "",
        //	idDitta: 0
        //}
        //},
        //dittaUPAttivita: {
        //	//id: 0,
        //	//dataInizio: "2022-06-24T07:43:00.061Z",
        //	//dataFine: "2022-06-24T07:43:00.061Z",
        //	indirizzoAttivita: "",
        //	capAttivita: "",
        //	telefonoAttivita: "",
        //	descrizioneAttivita: "",
        //	rifUtente: "",
        //	isIndirizzoPerCU: true,
        //	fax: "",
        //	email: "",
        //	pecEmail: "",
        //	cellulare: "",
        //	sedeCU: "",
        //	idComuneAttivita: 0,
        //	idIntermediarioLul: 0,
        //	idIntermediarioComunicazioni: 0,
        //	idDitta: 0
        //}
    },
    GetIntermediarioComunicazioni: {
        requireTotalCount: true,
        isCountQuery: false,
        skip: 0,
        take: 0,
        sort: '[]',
        filter: '[]',
        select: '[]',
        include: '[]',
        periodoDiRiferimento: {
            year: 0,
            month: 0,
        },
    },
    GetDitteUPStatus: {
        requireTotalCount: true,
        isCountQuery: false,
        skip: 0,
        take: 0,
        sort: '[]',
        filter: '[]',
        select: '[]',
        include: '[]',
    },
    GetPeriodoElaborazione: {
        requireTotalCount: true,
        isCountQuery: false,
    },
    DeleteDitta: {
        id: 0,
        isNuovaValidita: false,
    },
    GetVMessaggio: {
        requireTotalCount: true,
        isCountQuery: false,
        periodoDiRiferimento: {
            year: 2022,
            month: 2,
        },
        codice: 0,
        replacementValues: [],
    },
    GetVMessaggi: {
        requireTotalCount: false,
        isCountQuery: false,
    },
    GetDiarioCodice: {
        requireTotalCount: true,
        isCountQuery: false,
    },
    GetProfile: {
        requireTotalCount: true,
        isCountQuery: false,
    },
    GetCasseEdile: {
        requireTotalCount: true,
        isCountQuery: false,
    },
    SaveDittaInpsEnti: {},
    GetEnti: {
        requireTotalCount: false,
        isCountQuery: false,
    },
    GetDitteBanca: {
        requireTotalCount: true,
        isCountQuery: false,
    },
    SaveDittaCentroCosto: {},
    GetDipendentiCollocamento: {
        requireTotalCount: true,
        isCountQuery: false,
        // orderByKey: false,
        // skip: 0,
        // take: 0,
        // sort: "[]",
        // filter: "[]",
        // select: "[]",
        // include: "[]",
        // periodoDiRiferimento: {
        //     year: 0,
        //     month: 0,
        // },
    },
    DeleteDipte: {},
    GetVCodiceUnilav: {
        requireTotalCount: false,
        isCountQuery: false,
        orderByKey: true,
    },
    SaveDipteAnagrafica: {},
    GetDipendentiAnagrafica: {},
    GetVRegioneStatuto: {
        requireTotalCount: true,
        skip: 0,
        take: 0,
        periodoDiRiferimento: {
            year: 0,
            month: 0,
        },
    },
    UpdateDittaSostImposta: {},
    CreateDittaSostImposta: {},
}
