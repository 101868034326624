import { ShallowReactive, shallowReactive, computed, ref, reactive } from 'vue'
import { defineStore } from 'pinia'
import { cloneDeep, get } from 'lodash'
import moment from 'moment'

import utyDate from '@/scripts/services/date'
import { STORES_NAMES } from '@/constants'
import { useDynamicStore } from '@/composables/dynamic-store'
import { useDipendenteStore } from './index'
import { useUtils } from '@/composables'
import { pagheAPI } from '@/api'

import { checkDoppioCedolino, getDayDipendente, getKeyDipte } from '@/scripts/utils/dipendenteUtils'

const defaultState = {
    filtro: '',
    escludiCessati: 2,
    hasUP: false,
    selectedKeys: true,
    sortBy: [
        { key: 'unitaProduttiva', order: 'asc' },
        { key: 'codice', order: 'asc' },
    ],
}

const useDipendenteListStore = defineStore(
    STORES_NAMES.DIPENDENTE_LIST,
    () => {
        const dynamicStore = useDynamicStore()
        const actualIndex = ref(0)
        const unitaProduttiva = ref()
        const utils = useUtils()
        const state: ShallowReactive<any> = shallowReactive(
            Object.assign(cloneDeep(defaultState), {
                lastIdx: -1, //index dell'ultimo elemento visualizzato ---> utilizzato per determinare il periodo e di conseguenza se riscaricare i dati delle lookup
                props: null, //props della lista dipendenti
            }),
        )

        const ditta = reactive({
            codice: '',
            ragSociale: '',
            isDiario: null,
        })

        //const ditta = ref({})

        function setDataDitta(value: any, reset: boolean = false) {
            if (reset) dynamicStore.deleteState('dto')
            ditta.codice = value?.codice
            ditta.ragSociale = value?.ragSociale
            ditta.isDiario = value?.isDiario
        }

        function getData(props: any) {
            return selectElement(props)
        }

        function init() {
            Object.assign(state, cloneDeep(defaultState))
        }

        function selectElement(props: any) {
            const data = dynamicStore.getState('dto.data', [])
            if (!props || !data) return null

            const elements = data.filter((item: any) => {
                return item.id == props.id
            })
            if (elements.length == 0) return null
            if (elements.length == 1) return elements[0]

            //caso piu record per lo stesso ID - doppio cedolino - prendo quello che ha dataInizioDoppioCed nel mese
            let periodoElabStr = `${state.props.year}-${state.props.month}`

            const element = elements.find((item: any) => {
                let checkDoppioCed = checkDoppioCedolino(item, periodoElabStr)
                //se il giorno e' maggiore dell'1 allora posso prendere quello con dataFine 9999, altrimenti prendo l'altro
                if (props.day > 1) return checkDoppioCed
                return !checkDoppioCed
            })

            return element
        }

        const unitaProduttivaCodice = ref(null)

        //si passa l'id del dipendente e si trova l'index in selectredKeys
        function getIndex(idx: any): number {
            if (idx == undefined || !state.selectedKeys?.findIndex) return 0
            const value = state.selectedKeys?.findIndex((item: string) => {
                let ids = item.slice(0, item.indexOf('-'))
                return ids == idx
            })
            return value ? value : 0
        }

        function getIdFromIndex(idx: number): number {
            let item = state.selectedKeys[idx]
            let id = item.slice(0, item.indexOf('-'))
            return id
        }

        const navLength = computed(() => {
            if (!state.selectedKeys?.length) return 0
            return state.selectedKeys ? state.selectedKeys.length : 0
        })

        //controlla se l'elemento da cui si proviene ha lo stesso periodo dell'elemento che si vuole visualizzare
        function samePeriod(): boolean {
            if (state.lastIdx === -1) return false

            //let previousPeriod = getPeriodoDipte(state.lastIdx, true)
            //let actualPeriod = getPeriodoDipte(actualIndex.value, true)
            let previousPeriod = getPropsNavigation(state.lastIdx)
            let actualPeriod = getPropsNavigation(actualIndex.value)

            if (
                previousPeriod.day === actualPeriod.day &&
                previousPeriod.month === actualPeriod.month &&
                previousPeriod.year === actualPeriod.year
            )
                return true

            return false
        }

        // //ottiene il periodo del dipendente salvato in dynamic/dto
        // function getPeriodoDipte(id: number, isIndex: boolean = false) {
        //     //TODO DA RIVEDERE
        //     debugger
        //     //puo essere il periodo della ditta o la data del doppio cedolino
        //     let idElement = id
        //     let periodo = { month: state.props.month, year: state.props.year, day: '1' } //periodo di base
        //     if (isIndex) {
        //         idElement = state.selectedKeys[id]
        //     }

        //     let element = dynamicStore
        //         .getState('dto.data', [])
        //         .find((item: any) => item.id == idElement)

        //     //se il dipte ha il doppio cedolino allora prendo quello altrimenti il periodo base salvato nelle props (che sarebbe quello della ditta corrispondente)
        //     let periodoElabStr = `${state.props.year}-${state.props.month}`
        //     if (checkDoppioCedolino(element, periodoElabStr)) {
        //         debugger
        //         //il periodo e' quello del doppioCedolino
        //         periodo = MomentJS.GetFullPeriodoRiferimentoFromIso(
        //             element.dipteDoppioCedolino.dataInizio,
        //         )
        //     }

        //     return periodo
        // }
        //scarica le informazioni base fornite dall'elenco ditte nel caso in cui si entri direttamente
        //nella pagina dell'elenco dipendenti (es. da URL diretto o HMR)
        async function getDataDitta(props: any) {
            const params: DittaPayload = {
                requireHasFutureValidity: false,
                periodoDiRiferimento: {
                    year: Number(props.year),
                    month: Number(props.month),
                },
                filter: `['id', '=', '${props.id}']`,
                select: JSON.stringify([
                    'id',
                    'codiceDitta',
                    'unitaProduttiva',
                    'dittaUPRagioneSociale.denominazioneAggiuntiva',
                    'dittaUPRagioneSociale.ragioneSociale',
                    'dittaUPOpzione.isDiario',
                ]),
            }
            if (+props.idPadre > 0) {
                //aggiungo alla select anche le info aggiuntive
                let obj = JSON.parse(params.select)
                if (!obj) {
                    obj.push(
                        'padre.id',
                        'padre.dittaUPOpzione.isDiario',
                        'padre.dittaUPRagioneSociale.denominazioneAggiuntiva',
                        'padre.dittaUPRagioneSociale.ragioneSociale',
                    )

                    params.select = JSON.stringify(obj)
                }
            }

            //state.data = null //TODO VALUTARE DI REINIZIALIZZARE L"OBJECT POICHE POTREBBE PORTARE PROBLEMI NELLA NAVIGAZIONE
            try {
                const response = await pagheAPI.anagrafiche.ditte.ditta.get(params)
                const { responseStatus, data } = response?.data
                if (responseStatus.isSuccessful) {
                    state.data = data[0]

                    const isDiario = get(
                        data[0],
                        'dittaUPOpzione.isDiario',
                        get(data[0], 'padre.dittaUPOpzione.isDiario', false),
                    )

                    setDataDitta({
                        codice: data[0].codiceDitta,
                        ragSociale: utils.ragSocialeFromData(data[0], true),
                        isDiario: isDiario,
                    })

                    //VIENE PRESO DA DIPLISTSTORE MA IN CASO DI F5 VALUTARE IL REINIT
                    //ditta.codice = data[0].ditta.codiceDitta
                }
            } catch (err) {
                console.error('Errore durante la chiamata: ', err)
            }
        }

        function getPeriodo() {
            return {
                year: state.props!.year,
                month: state.props!.month,
            }
        }

        function setProps(props: any) {
            if (state.props != null) {
                Object.assign(state.props, props)
            } else {
                state.props = { ...props }
            }
        }

        function getPropsNavigation(idx: number) {
            let item = state.selectedKeys[idx] //elemento chiave id+dataInizioDoppioCedolino
            let idElement = item.slice(0, item.indexOf('-'))
            let periodo = { month: state.props.month, year: state.props.year, day: 1 } //periodo di base

            //ricerca esatte
            let elements = dynamicStore.getState('dto.data', []).filter((item: any) => {
                return item.id == idElement
            })
            if (elements.length == 1) {
                return {
                    ...periodo,
                    id: elements[0].id,
                    dittaId: state.props.dittaId, //cambia solo se si seleziona una ditta diversa
                    dittaPadreId: state.props.dittaPadreId, //cambia solo se si seleziona una ditta diversa
                    mod: 'mod',
                }
            }

            //caso elementi multipli --- DOPPIO CEDOLINO
            let element = elements.find((dipte: any) => {
                return getKeyDipte(dipte) === item
            })

            if (checkDoppioCedolino(element, `${state.props.year}-${state.props.month}`))
                periodo.day = getDayDipendente(element?.dipteDoppioCedolino?.dataInizio)

            return {
                ...periodo,
                id: elements[0].id,
                dittaId: state.props.dittaId,
                dittaPadreId: state.props.dittaPadreId,
                mod: 'mod',
            }
        }

        const getCodiceDitta = computed(() => {
            return ditta?.codice
        })

        const isDitta = computed(() => {
            return !!ditta?.codice && !!ditta?.ragSociale && ditta?.isDiario != null
        })

        return {
            ...dynamicStore,
            ditta,
            actualIndex,
            getData,
            getPeriodo,
            //getPeriodoDipte,
            init,
            isDitta,
            navLength,
            samePeriod,
            state,
            setProps,
            getDataDitta,
            getIndex,
            getIdFromIndex,
            getPropsNavigation,
            getCodiceDitta,
            setDataDitta,
            unitaProduttiva,
            unitaProduttivaCodice,
        }
    },
    {
        /* persist: false, */
        persist: [
            {
                paths: ['ditta'],
            },
        ],
    },
)

export default useDipendenteListStore
