import axios from 'axios'
import { http } from '@/scripts/lib/http'
import { payloads } from '@/scripts/services/payloads.js'
import { endpoints } from '@/scripts/services/endpoints.js'
import { MessageBox, MomentJS, Toast } from '@/scripts/services/utility.js'

export class Intermediario {
    constructor() {}

    static GetIntermediari(payload, successCallBack, errorCallBack) {
        http.post(endpoints.GetIntermediari, {
            ...payloads.GetIntermediari,
            ...payload,
        })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['data'] && successCallBack) {
                    successCallBack(data['data'], data.totalCount || 0)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static async GetIntermediariAsync(payload) {
        let result = await http.post(endpoints.GetIntermediari, {
            ...payloads.GetIntermediari,
            ...payload,
        })
        return result['data']
    }

    static async CreateIntermediarioAsync(payload) {
        let result = await http.post(endpoints.CreateIntermediario, {
            ...payloads.CreateIntermediario,
            ...payload,
        })

        return result['data']
    }

    static async UpdateIntermediarioAsync(payload) {
        const result = await http.put(endpoints.UpdateIntermediario, {
            ...payloads.UpdateIntermediario,
            ...payload,
        })
        return result['data']
    }

    static async DeleteIntermediario(payload) {
        const result = await http.delete(endpoints.DeleteIntermediario, {
            data: { ...payloads.DeleteIntermediario, ...payload },
        })
        return result.data
    }
}

export class IntermediarioInailLul {
    constructor() {}

    static GetIntermediariInailLul(payload, successCallBack, errorCallBack) {
        http.post(endpoints.GetIntermediariInailLul, {
            ...payloads.GetIntermediariInailLul,
            ...payload,
        })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['data'] && successCallBack) {
                    successCallBack(data['data'], data['data'].totalCount || 0)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static async GetIntermediariInailLulAsync(payload) {
        let result = await http.post(endpoints.GetIntermediariInailLul, {
            ...payloads.GetIntermediariInailLul,
            ...payload,
        })
        let data = result['data']

        if (data.responseStatus && data.responseStatus.isSuccessful && data['data']) {
            return data['data']
        }
        //TODO: vedere se mostrare il toast o MessageBox
        Toast.ErrorMessage()
        return null
    }

    static CreateIntermediarioInailLul(payload, successCallBack, errorCallBack) {
        http.post(endpoints.CreateIntermediarioInailLul, {
            ...payloads.CreateIntermediarioInailLul,
            ...payload,
        })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['payload'] && successCallBack) {
                    successCallBack(data['payload'], 1)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static async CreateIntermediarioInailLulAsync(payload) {
        let result = await http.post(endpoints.CreateIntermediarioInailLul, {
            ...payloads.CreateIntermediarioInailLul,
            ...payload,
        })
        return result['data']
    }

    static UpdateIntermediarioInailLul(payload, successCallBack, errorCallBack) {
        http.put(endpoints.UpdateIntermediarioInailLul, {
            ...payloads.UpdateIntermediarioInailLul,
            ...payload,
        })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['payload'] && successCallBack) {
                    successCallBack(data['payload'], 1)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static async UpdateIntermediarioInailLulAsync(payload) {
        let result = await http.put(endpoints.UpdateIntermediarioInailLul, {
            ...payloads.UpdateIntermediarioInailLul,
            ...payload,
        })
        return result['data']
    }

    static DeleteIntermediarioInailLul(payload, successCallBack, errorCallBack) {
        http.delete(endpoints.DeleteIntermediarioInailLul, {
            data: { ...payloads.DeleteIntermediarioInailLul, ...payload },
        }).then(function (result) {
            if (result.status != 200 && errorCallBack) {
                errorCallBack(result)
                return
            }

            if (result.status == 200 && successCallBack) {
                successCallBack()
                return
            }
        })
    }
}
export class IntermediarioUniemen {
    constructor() {}

    static GetIntermediariUniemen(payload, successCallBack, errorCallBack) {
        http.post(endpoints.GetIntermediariUniemen, {
            ...payloads.GetIntermediariUniemen,
            ...payload,
        })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['data'] && successCallBack) {
                    successCallBack(data['data'], data['data'].totalCount || 0)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static async GetIntermediariUniemenAsync(payload) {
        let result = await http.post(endpoints.GetIntermediariUniemen, {
            ...payloads.GetIntermediariUniemen,
            ...payload,
        })
        let data = result['data']

        if (data.responseStatus && data.responseStatus.isSuccessful && data['data']) {
            return data['data']
        }
        //TODO: vedere se mostrare il toast o MessageBox
        Toast.ErrorMessage()
        return null
    }

    static CreateIntermediarioUniemen(payload, successCallBack, errorCallBack) {
        http.post(endpoints.CreateIntermediarioUniemen, {
            ...payloads.CreateIntermediarioUniemen,
            ...payload,
        })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['payload'] && successCallBack) {
                    successCallBack(data['payload'], 1)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static UpdateIntermediarioUniemen(payload, successCallBack, errorCallBack) {
        http.put(endpoints.UpdateIntermediarioUniemen, {
            ...payloads.UpdateIntermediarioUniemen,
            ...payload,
        })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['payload'] && successCallBack) {
                    successCallBack(data['payload'], 1)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static async UpdateIntermediarioUniemenAsync(payload) {
        let result = await http.put(endpoints.UpdateIntermediarioUniemen, {
            ...payloads.UpdateIntermediarioUniemen,
            ...payload,
        })
        return result['data']
    }

    static async CreateIntermediarioUniemenAsync(payload) {
        let result = await http.post(endpoints.CreateIntermediarioUniemen, {
            ...payloads.CreateIntermediarioUniemen,
            ...payload,
        })
        return result['data']
    }
}
export class IntermediarioFiscale {
    constructor() {}

    static GetIntermediariFiscale(payload, successCallBack, errorCallBack) {
        http.post(endpoints.GetIntermediariFiscale, {
            ...payloads.GetIntermediariFiscale,
            ...payload,
        })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['data'] && successCallBack) {
                    successCallBack(data['data'], data['data'].totalCount || 0)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static async GetIntermediariFiscaleAsync(payload) {
        let result = await http.post(endpoints.GetIntermediariFiscale, {
            ...payloads.GetIntermediariFiscale,
            ...payload,
        })
        let data = result['data']

        if (data.responseStatus && data.responseStatus.isSuccessful && data['data']) {
            return data['data']
        }
        //TODO: vedere se mostrare il toast o MessageBox
        Toast.ErrorMessage()
        return null
    }

    static CreateIntermediarioFiscale(payload, successCallBack, errorCallBack) {
        http.post(endpoints.CreateIntermediarioFiscale, {
            ...payloads.CreateIntermediarioFiscale,
            ...payload,
        })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['payload'] && successCallBack) {
                    successCallBack(data['payload'], 1)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static UpdateIntermediarioFiscale(payload, successCallBack, errorCallBack) {
        http.put(endpoints.UpdateIntermediarioFiscale, {
            ...payloads.UpdateIntermediarioFiscale,
            ...payload,
        })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['payload'] && successCallBack) {
                    successCallBack(data['payload'], 1)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static async UpdateIntermediarioFiscaleAsync(payload) {
        let result = await http.put(endpoints.UpdateIntermediarioFiscale, {
            ...payloads.UpdateIntermediarioFiscale,
            ...payload,
        })

        return result['data']
    }

    static async CreateIntermediarioFiscaleAsync(payload) {
        let result = await http.post(endpoints.CreateIntermediarioFiscale, {
            ...payloads.CreateIntermediarioFiscale,
            ...payload,
        })
        return result['data']
    }
}

export class DittaSostImposta {
    constructor() {}

    static updateDittaSostImposta(payload, successCallBack, errorCallBack) {
        http.put(endpoints.UpdateDittaSostImposta, {
            ...payloads.UpdateIntermediarioSostImposta,
            ...payload,
        })
            .then(function (result) {
                console.log(result)
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static createDittaSostImposta(payload, successCallBack, errorCallBack) {
        http.post(endpoints.CreateDittaSostImposta, {
            ...payloads.CreateIntermediarioSostImposta,
            ...payload,
        })
            .then(function (result) {
                console.log(result)
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }
}
export class Comune {
    static comuniListCase1 = []
    static comuniListCase2 = []

    constructor() {}

    static GetComuni(payload, successCallBack, errorCallBack) {
        http.post(endpoints.GetComuni, { ...payloads.GetComuni, ...payload })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['data'] && successCallBack) {
                    successCallBack(data['data'], data['data'].totalCount || 0)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }
    /**
	// TIPO: 2 US: 929
	static GetComuniPreInitCase2() {
		let payload = {
			filter: "['comuneDato.codExComune', '=', null]",
			select: "['codice', 'descrizione', 'siglaProvincia','cap','isNazione','comuneDato.isIstituitoXFusione', 'codFiscale']",
		};

        Comune.GetComuni(
            payload,
            function (data) {
                Comune.comuniListCase2 = data;
            },
            function () {
            }
        );
    }

    // TIPO: 1 US: 929
    static GetComuniPreInitCase1() {
        let payload = {
            select: "['codice', 'descrizione', 'siglaProvincia','cap','isNazione','comuneDato.isIstituitoXFusione', 'codFiscale']",
        };

		Comune.GetComuni(
			payload,
			function (data) {
				Comune.comuniListCase1 = data;
			},
			function () {}
		);
	}
	*/
    // TIPO: 2 US: 929
    static GetComuniPreInitCase2(successCallBack, errorCallBack) {
        if (Comune.comuniListCase2.length > 0) {
            successCallBack(Comune.comuniListCase2)
        } else {
            let payload = {
                filter: "['comuneDato.codExComune', '=', null]",
                select: "['id', 'codice', 'descrizione', 'siglaProvincia','cap','isNazione','comuneDato.isIstituitoXFusione', 'codFiscale', 'comuneDato.codExComune']",
            }

            Comune.GetComuni(
                payload,
                function (data) {
                    Comune.comuniListCase2 = data

                    if (successCallBack) successCallBack(data)
                },
                function () {
                    errorCallBack([])
                },
            )
        }
    }

    // TIPO: 1 US: 929
    static GetComuniPreInitCase1(successCallBack, errorCallBack) {
        if (Comune.comuniListCase1.length > 0) {
            successCallBack(Comune.comuniListCase1)
        } else {
            let payload = {
                select: "['id','codice', 'descrizione', 'siglaProvincia','cap','isNazione','comuneDato.isIstituitoXFusione','comuneDato.codExComune', 'codFiscale']",
            }

            Comune.GetComuni(
                payload,
                function (data) {
                    Comune.comuniListCase1 = data

                    if (successCallBack) successCallBack(data)
                },
                function () {
                    errorCallBack([])
                },
            )
        }
    }
}
export class VCodDes {
    constructor() {}
    static GetVCodDes(payload, successCallBack, errorCallBack) {
        http.post(endpoints.GetVCodDes, { ...payloads.GetVCodDes, ...payload })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['data'] && successCallBack) {
                    successCallBack(data['data'], data['data'].totalCount || 0)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static async GetVCodDesAsync(payload) {
        let result = await http.post(endpoints.GetVCodDes, {
            ...payloads.GetVCodDes,
            ...payload,
        })
        let data = result['data']

        if (data.responseStatus && data.responseStatus.isSuccessful && data['data']) {
            return data['data']
        }

        return null
    }
}

export class VRegioneStatuto {
    constructor() {}

    static GetVRegioneStatuto(payload, successCallBack, errorCallBack) {
        http.post(endpoints.GetVRegioneStatuto, { ...payloads.GetVRegioneStatuto, ...payload })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['data'] && successCallBack) {
                    successCallBack(data['data'], data['data'].totalCount || 0)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }
}
export class Banca {
    constructor() {}

    static GetBanche(payload, successCallBack, errorCallBack) {
        http.post(endpoints.GetBanche, { ...payloads.GetBanche, ...payload })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['data'] && successCallBack) {
                    successCallBack(data['data'], data['data'].totalCount || 0)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static async GetBancheAsync(payload) {
        let result = await http.post(endpoints.GetBanche, {
            ...payloads.GetBanche,
            ...payload,
        })
        let data = result['data']

        if (data.responseStatus && data.responseStatus.isSuccessful && data['data']) {
            return data['data']
        }

        return null
    }
}
export class VSedeInps {
    constructor() {}

    static GetVSedeInps(payload, successCallBack, errorCallBack) {
        http.post(endpoints.GetVSedeInps, {
            ...payloads.GetVSedeInps,
            ...payload,
        })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['data'] && successCallBack) {
                    successCallBack(data['data'], data['data'].totalCount || 0)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static async GetVSedeInpsAsync(payload) {
        let result = await http.post(endpoints.GetVSedeInps, {
            ...payloads.GetVSedeInps,
            ...payload,
        })
        let data = result['data']

        if (data.responseStatus && data.responseStatus.isSuccessful && data['data']) {
            return data['data']
        }

        return null
    }
}

export class Voce {
    constructor() {}

    static GetVoci(payload, successCallBack, errorCallBack) {
        http.post(endpoints.GetVoci, { ...payloads.GetVoci, ...payload })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['data'] && successCallBack) {
                    successCallBack(data['data'], data.totalCount || 0)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static UpdateVoce(payload, successCallBack, errorCallBack) {
        http.put(endpoints.UpdateVoce, { ...payloads.UpdateVoce, ...payload })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['payload'] && successCallBack) {
                    successCallBack(data['payload'], 1)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }
}

export class Ditta {
    constructor() {}

    static CreateDitta(payload, successCallBack, errorCallBack) {
        http.post(endpoints.CreateDitta, {
            ...payloads.CreateDitta,
            ...payload,
        })
            .then(function (result) {
                let data = result['data']
                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    MessageBox.ShowBEMessage(
                        result,
                        true, //chiamo il MessageBox per visualizzare il POPUP Alert
                        function () {
                            errorCallBack(result) //viene definito all'interno della pagina
                        },
                    )

                    return
                }
                if (data['payload'] && successCallBack) {
                    MessageBox.ShowBEMessage(result, false, function () {
                        successCallBack(data['payload'], 1)
                    })
                    return
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static UpdateDitta(payload, successCallBack, errorCallBack) {
        http.put(endpoints.UpdateDitta, { ...payloads.UpdateDitta, ...payload })
            .then(function (result) {
                let data = result['data']
                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    MessageBox.ShowBEMessage(
                        result,
                        true, //chiamo il MessageBox per visualizzare il POPUP Alert
                        function () {
                            errorCallBack(result) //viene definito all'interno della pagina
                        },
                    )
                    return
                }
                if (data['payload'] && successCallBack) {
                    MessageBox.ShowBEMessage(result, false, function () {
                        successCallBack(data['payload'], 1)
                    })
                    return
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static GetDitte(payload, successCallBack, errorCallBack) {
        http.post(endpoints.GetDitte, { ...payloads.GetDitte, ...payload })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['data'] && successCallBack) {
                    successCallBack(data['data'], data.totalCount || 0)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static async GetDitteAsync(payload) {
        let result = await http.post(endpoints.GetDitte, {
            ...payloads.GetDitte,
            ...payload,
        })
        let data = result['data']

        if (data.responseStatus && data.responseStatus.isSuccessful && data['data']) {
            return data['data']
        }
        //TODO: vedere se mostrare il toast o MessageBox
        Toast.ErrorMessage()
        return null
    }

    static async GetTotalUpAsync(payload) {
        let result = await http.post(endpoints.GetDitte, {
            ...payloads.GetDitte,
            ...payload,
        })
        let data = result['data']

        if (data.responseStatus && data.responseStatus.isSuccessful) {
            return data
        }
        //TODO: vedere se mostrare il toast o MessageBox
        Toast.ErrorMessage()
        return null
    }

    static DeleteDitta(payload, successCallBack, errorCallBack) {
        http.delete(endpoints.DeleteDitta, {
            data: { ...payloads.DeleteDitta, ...payload },
        })
            .then(function (result) {
                if (result.status != 200 && errorCallBack) {
                    errorCallBack(result)
                    return
                }

                if (result.status == 200 && successCallBack) {
                    successCallBack()
                    return
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static SaveDittaInpsEnti(payload, successCallBack, errorCallBack) {
        http.post(endpoints.SaveDittaInpsEnti, {
            ...payloads.SaveDittaInpsEnti,
            ...payload,
        })
            .then(function (result) {
                let data = result['data']
                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    MessageBox.ShowBEMessage(
                        result,
                        true, //chiamo il MessageBox per visualizzare il POPUP Alert
                        function () {
                            errorCallBack(result) //viene definito all'interno della pagina
                        },
                    )
                    return
                }
                if (data['payload'] && successCallBack) {
                    MessageBox.ShowBEMessage(result, false, function () {
                        successCallBack(data['payload'], 1)
                    })
                    return
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static SaveDittaCentroCosto(payload, successCallBack, errorCallBack) {
        http.post(endpoints.SaveDittaCentroCosto, {
            ...payloads.SaveDittaCentroCosto,
            ...payload,
        })
            .then(function (result) {
                let data = result['data']
                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    MessageBox.ShowBEMessage(
                        result,
                        true, //chiamo il MessageBox per visualizzare il POPUP Alert
                        function () {
                            errorCallBack(result) //viene definito all'interno della pagina
                        },
                    )
                    return
                }
                if (data['payload'] && successCallBack) {
                    MessageBox.ShowBEMessage(result, false, function () {
                        successCallBack(data['payload'], 1)
                    })
                    return
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static async SaveDittaInpsEntiAsync(payload) {
        let result = await http.post(endpoints.SaveDittaInpsEnti, {
            ...payloads.SaveDittaInpsEnti,
            ...payload,
        })
        return result.data
    }

    static async CreateDittaAsync(payload) {
        let result = await http.post(endpoints.CreateDitta, {
            ...payloads.CreateDitta,
            ...payload,
        })
        return result.data
    }

    static async UpdateDittaAsync(payload) {
        let result = await http.put(endpoints.UpdateDitta, {
            ...payloads.UpdateDitta,
            ...payload,
        })
        return result.data
    }

    static async DeleteDittaAsync(payload) {
        let pay = { ...payloads.DeleteDitta, ...payload }

        let result = await http.delete(endpoints.DeleteDitta, {
            data: pay,
        })

        let data = result['data']

        if (data.responseStatus && !data.responseStatus.isSuccessful) {
            await MessageBox.asyncShowBEMessage(result, true)
            return null
        }
        if (data['payload']) {
            await MessageBox.asyncShowBEMessage(result, false)
            let resultPayload = new Promise((resolve, reject) => {
                resolve(data['payload'])
            })
            return resultPayload
        }

        return null
    }
}

export class DittaUPAttivita {
    static async GetDitteUPAttivitaAsync(payload) {
        let result = await http.post(endpoints.GetDitteUPAttivita, { ...payload })
        let data = result['data']

        if (data.responseStatus && data.responseStatus.isSuccessful && data['data']) {
            return data['data']
        }
        //TODO: vedere se mostrare il toast o MessageBox
        Toast.ErrorMessage()
        return null
    }
}

export class DittaUPEnte {
    static async GetDittaUPEntiAsync(payload) {
        let result = await http.post(endpoints.GetDittaUPEnti, { ...payload })
        let data = result['data']

        if (data.responseStatus && data.responseStatus.isSuccessful && data['data']) {
            return data['data']
        }
        //TODO: vedere se mostrare il toast o MessageBox
        Toast.ErrorMessage()
        return null
    }
}
export class DiarioCodice {
    static GetDiarioCodice(payload, successCallBack, errorCallBack) {
        http.post(endpoints.GetDiarioCodice, {
            ...payloads.GetDiarioCodice,
            ...payload,
        })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['data'] && successCallBack) {
                    successCallBack(data['data'], data.totalCount || 0)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }
}
export class IntermediarioComunicazione {
    constructor() {}

    static GetIntermediarioComunicazioni(payload, successCallBack, errorCallBack) {
        http.post(endpoints.GetIntermediarioComunicazioni, {
            ...payloads.GetIntermediarioComunicazioni,
            ...payload,
        })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['data'] && successCallBack) {
                    successCallBack(data['data'], data.totalCount || 0)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }
}
export class DittaUPStatus {
    constructor() {}

    static GetDitteUPStatus(payload, successCallBack, errorCallBack) {
        http.post(endpoints.GetDitteUPStatus, {
            ...payloads.GetDitteUPStatus,
            ...payload,
        })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['data'] && successCallBack) {
                    successCallBack(data['data'], data.totalCount || 0)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static async GetPeriodoDittaAsync(payload) {
        let result = await http.post(endpoints.GetDitteUPStatus, {
            ...payloads.GetDitteUPStatus,
            ...payload,
        })
        let data = result['data']

        if (data.responseStatus && data.responseStatus.isSuccessful && data['data']) {
            if (data['data'][0])
                return MomentJS.GetPeriodoRiferimentoFromIso(data['data'][0].dataAnnoMeseElab)
            else return null
        }
        Toast.ErrorMessage()
        return null
    }
}
export class PeriodoElaborazione {
    constructor() {}

    static GetPeriodoElaborazione(payload, successCallBack, errorCallBack) {
        http.post(endpoints.GetPeriodoElaborazione, {
            ...payloads.GetPeriodoElaborazione,
            ...payload,
        })
            .then(function (result) {
                let data = result['data']
                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['data'] && successCallBack) {
                    successCallBack(data['data'], data.totalCount || 0)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }
    static async GetPeriodoElaborazioneAsync(payload) {
        let result = await http.post(endpoints.GetPeriodoElaborazione, {
            ...payloads.GetPeriodoElaborazione,
            ...payload,
        })
        let data = result['data']

        if (data.responseStatus && data.responseStatus.isSuccessful && data['data']) {
            return MomentJS.GetPeriodoRiferimentoFromIso(data['data'][0].dataPeriodoElaborazione)
        }
        Toast.ErrorMessage()
        return null
    }
}
export class MessagiBE {
    constructor() {}

    static GetVMessaggio(payload, successCallBack, errorCallBack) {
        http.post(endpoints.GetVMessaggio, {
            ...payloads.GetVMessaggio,
            ...payload,
        })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data.payload && successCallBack) {
                    successCallBack(data.payload, data.totalCount || 0)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static GetVMessaggi(payload, successCallBack, errorCallBack) {
        http.post(endpoints.GetVMessaggi, {
            ...payloads.GetVMessaggi,
            ...payload,
        })
            .then(function (result) {
                let data = result.data

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data.data && successCallBack) {
                    successCallBack(data.data, data.totalCount || 0)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static async GetVMessaggiAsync(payload) {
        let result = await http.post(endpoints.GetVMessaggi, {
            ...payloads.GetVMessaggi,
            ...payload,
        })
        let data = result['data']

        if (data.responseStatus && data.responseStatus.isSuccessful && data['data']) {
            return data['data']
        }
        return null
    }
}
export class Profile {
    constructor() {}

    static async GetProfile(payload) {
        const response = await http
            .post(endpoints.GetProfile, {
                ...payloads.GetProfile,
                ...payload,
            })
            .catch(e => {
                const message = e.response.data?.ResponseStatus?.Message
                message && MessageBox.ErrorMessage({ message })
            })
        return response.data.payload
    }
}
export class CassaEdile {
    constructor() {}

    static GetCasseEdile(payload, successCallBack, errorCallBack) {
        http.post(endpoints.GetCasseEdile, {
            ...payloads.GetCasseEdile,
            ...payload,
        })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['data'] && successCallBack) {
                    successCallBack(data['data'], data.totalCount || 0)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static async GetCassaEdileAsync(payload) {
        let result = await http.post(endpoints.GetCasseEdile, {
            ...payloads.GetCasseEdile,
            ...payload,
        })
        let data = result['data']

        if (data.responseStatus && data.responseStatus.isSuccessful && data['data']) {
            return data['data']
        }
        //TODO: vedere se mostrare il toast o MessageBox
        Toast.ErrorMessage()
        return null
    }
}
export class Dipendente {
    constructor() {}

    static GetDipendenti(payload, successCallBack, errorCallBack) {
        http.post(endpoints.GetDipendenti, {
            ...payloads.GetDipendenti,
            ...payload,
        })
            .then(function (result) {
                let data = result['data']

                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['data'] && successCallBack) {
                    successCallBack(data['data'], data.totalCount || 0)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }

    static async GetDipendentiAsync(payload) {
        let result = await http.post(endpoints.GetDipendenti, {
            ...payloads.GetDipendenti,
            ...payload,
        })
        return result.data
    }

    static async DeleteDipendenteAsync(payload) {
        let prova = { ...payloads.DeleteDipte, ...payload }

        let result = await http.delete(endpoints.DeleteDipte, {
            data: prova,
        })

        let data = result['data']
        if (data.responseStatus && !data.responseStatus.isSuccessful) {
            await MessageBox.asyncShowBEMessage(result, true)
            return null
        }
        if (data['payload']) {
            await MessageBox.asyncShowBEMessage(result, false)
            let resultPayload = new Promise((resolve, reject) => {
                resolve(data['payload'])
            })
            return resultPayload
        }

        return null
    }
}
export class Ente {
    constructor() {}

    static GetEnti(payload, successCallBack, errorCallBack) {
        http.post(endpoints.GetEnti, { ...payloads.GetEnti, ...payload })
            .then(function (result) {
                let data = result['data']
                if (data.responseStatus && !data.responseStatus.isSuccessful) {
                    errorCallBack(result)
                    return
                }

                if (data['data'] && successCallBack) {
                    successCallBack(data['data'], data.totalCount || 0)
                }
            })
            .catch(function (error) {
                if (error.status != 200 && errorCallBack) {
                    errorCallBack(error)
                }
            })
    }
}
export class DittaBanca {
    constructor() {}

    static async GetDitteBancaAsync(payload) {
        let result = await http.post(endpoints.GetDitteBanca, {
            ...payloads.GetDitteBanca,
            ...payload,
        })
        let data = result['data']

        if (data.responseStatus && data.responseStatus.isSuccessful && data['data']) {
            return data['data']
        }
        return null
    }
}

export class DipteCollocamento {
    constructor() {}

    static async GetDipendentiCollocamentoAsync(payload) {
        let result = await http.post(endpoints.GetDipendentiCollocamento, {
            ...payloads.GetDipendentiCollocamento,
            ...payload,
        })
        let data = result['data']

        if (data.responseStatus && data.responseStatus.isSuccessful && data['data']) {
            return data['data']
        }
        return null
    }

    static async UpdateDipendentiCollocamentoAsyc(payload) {
        let result = await http.put(endpoints.UpdateDipendentiCollocamento, {
            ...payloads.GetDipendentiCollocamento,
            ...payload,
        })
        return result.data
    }

    static async CreateDipendentiCollocamentoAsyc(payload) {
        let result = await http.post(endpoints.CreateDipendentiCollocamento, {
            ...payloads.GetDipendentiCollocamento,
            ...payload,
        })
        return result.data
    }
}

export class VCodiceUnilav {
    constructor() {}

    static async GetVCodiceUnilav(payload) {
        let result = await http.post(endpoints.GetVCodiceUnilav, {
            ...payloads.GetVCodiceUnilav,
            ...payload,
        })
        let data = result['data']

        if (data.responseStatus && data.responseStatus.isSuccessful && data['data']) {
            return data['data']
        }

        return null
    }
}

export class DipteAnagrafica {
    constructor() {}

    static async SaveDipteAnagraficaAsyc(payload) {
        let result = await http.post(endpoints.SaveDipteAnagrafica, {
            ...payloads.SaveDipteAnagrafica,
            ...payload,
        })
        return result.data
    }

    static async GetDipendentiAnagraficaAsync(payload) {
        let result = await http.post(endpoints.GetDipendentiAnagrafica, {
            ...payloads.GetDipendentiAnagrafica,
            ...payload,
        })
        return result.data
    }
}

class ServiceErrorsHandler {
    static async ManageCall(data, result) {
        if (data.responseStatus && !data.responseStatus.isSuccessful) {
            await MessageBox.asyncShowBEMessage(result, true)

            return null
        }
        if (data['payload']) {
            await MessageBox.asyncShowBEMessage(result, false)
            let resultPayload = new Promise((resolve, reject) => {
                resolve(data['payload'])
            })
            return resultPayload
        }
        return null
    }
}

export function aggregateCall(requests, callBack, callBackError) {
    return axios
        .all(requests.map(req => http.post(req.url, req.payload || {})))
        .then(axios.spread(async (...res) => await callBack(res.map(item => item.data))))
        .catch(callBackError)
}
