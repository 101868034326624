import pagheViews from '@paghe/views'

const tabPropsDefault: TabProps = {
    TITLE: 'Status',
    ICON: 'fa-regular fa-users-line',
    GROUP: 'anagraficaStatus',
    CODVOCEMENU: '000195',
}

export const SETTINGS = Object.freeze({
    STATUS_LISTA: {
        TITLE: 'Elenco Status - Status - Anagrafici - Gestione - Paghe',
        LABEL: 'Elenco Status',
        PATH: 'status',
        NAME: 'statusLista',
        TAB: { ...tabPropsDefault },
    },
    STATUS_DETTAGLIO: {
        TITLE: 'Anagrafica Status - Status - Anagrafici - Gestione - Paghe',
        PATH: 'status/dettaglio/:year/:month/:id/:idPadre/:mod',

        NAME: 'statusDettaglio',
        TAB: { ...tabPropsDefault },
    },
    STATUS: {
        TITLE: 'Anagrafica Status - Status - Anagrafici - Gestione - Paghe',
        PATH: 'status',
        NAME: 'status',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.STATUS_LISTA.PATH,
            name: SETTINGS.STATUS_LISTA.NAME,
            component: pagheViews.anagrafiche.status.Elenco,
            meta: {
                title: SETTINGS.STATUS_LISTA.TITLE,
                permissions: {
                    codPermesso: 'ANAG_DITTA',
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
        },
        {
            path: SETTINGS.STATUS_DETTAGLIO.PATH,
            name: SETTINGS.STATUS_DETTAGLIO.NAME,
            component: pagheViews.anagrafiche.status.dettaglio.Dettaglio,
            props: true,
            meta: {
                idPaginaMsg: 'STATUS',

                title: SETTINGS.STATUS_DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: 'ANAG_DITTA',
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            children: [
                {
                    path: SETTINGS.STATUS.PATH,
                    name: SETTINGS.STATUS.NAME,
                    component: pagheViews.anagrafiche.status.dettaglio.childs.Status,
                    props: true,
                    meta: {
                        title: SETTINGS.STATUS.TITLE,
                        permissions: {
                            codPermesso: 'ANAG_DITTA',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                },
            ],
        },
    ],
}
