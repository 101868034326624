import { pagheViews } from '@/views'

const tabPropsDefault: TabProps = {
    GROUP: 'risultatiCollaboratore',
    ICON: 'fa-regular fa-buildings',
    TITLE: 'Risultati COLLABORATORE',
    CODPERMESSO: 'RII_COLL',
}

export const SETTINGS = Object.freeze({
    COLLABORATORE_LISTA: {
        TITLE: 'Elenco Collaboratori - Risultati - Collaboratore - Gestione - Paghe',
        LABEL: 'RISULTATI COLLABORATORE',
        PATH: 'risCollaboratori/:year/:month/:day/:dittaId/:dittaPadreId',
        NAME: 'ElencoRisultatiCollaboratori',
        TAB: { ...tabPropsDefault },
    },
    COLLABORATORE_DETTAGLIO: {
        TITLE: 'Dettaglio risultati collaboratore - Risultati - collaboratore - Gestione - Paghe',
        LABEL: 'Dettaglio collaboratore',
        PATH: 'risCollaboratori/:year/:month/:day/:dittaId/:dittaPadreId/:id/:mod',
        NAME: 'CollaboratoreRisultatiDettaglio',
        TAB: { ...tabPropsDefault },
    },
    COLLABORATORE_RISULTATI: {
        TITLE: '',
        LABEL: 'Risultati',
        PATH: 'risultati',
        NAME: 'CollaboratoreRisultati',
        TAB: { ...tabPropsDefault },
    },
    COLLABORATORE_CARICHI_FAMIGLIA: {
        TITLE: '',
        LABEL: 'Spettanza detrazioni fiscali',
        PATH: 'carichiFamiglia',
        NAME: 'CollaboratoreRisFamiglia',
        TAB: { ...tabPropsDefault },
    },
    COLLABORATORE_PRESTITI: {
        TITLE: '',
        LABEL: 'Finanz./Pignoram.',
        PATH: 'prestiti',
        NAME: 'CollaboratoreRisPrestiti',
        TAB: { ...tabPropsDefault },
    },
    COLLABORATORE_INAIL: {
        TITLE: '',
        LABEL: 'Dati inail',
        PATH: 'inail',
        NAME: 'CollaboratoreRisInail',
        TAB: { ...tabPropsDefault },
    },
    COLLABORATORE_ENTI: {
        TITLE: '',
        LABEL: 'Enti',
        PATH: 'enti',
        NAME: 'CollaboratoreRisEnti',
        TAB: { ...tabPropsDefault },
    },
    COLLABORATORE_VOCI: {
        TITLE: '',
        LABEL: 'Voci',
        PATH: 'voci',
        NAME: 'CollaboratoreRisVoci',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.COLLABORATORE_LISTA.PATH,
            name: SETTINGS.COLLABORATORE_LISTA.NAME,
            component: pagheViews.risultati.Collaboratori.listeCollaboratori.Collaboratori, //ElencoCOllaboratori,
            meta: {
                title: SETTINGS.COLLABORATORE_LISTA.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            props: true,
        },
        {
            path: SETTINGS.COLLABORATORE_DETTAGLIO.PATH,
            name: SETTINGS.COLLABORATORE_DETTAGLIO.NAME,
            props: true,
            component: pagheViews.risultati.Collaboratori.dettaglioCollaboratori.collabDettaglio,
            meta: {
                title: SETTINGS.COLLABORATORE_DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: null,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            children: [
                {
                    label: SETTINGS.COLLABORATORE_RISULTATI.LABEL,
                    path: SETTINGS.COLLABORATORE_RISULTATI.PATH,
                    name: SETTINGS.COLLABORATORE_RISULTATI.NAME,
                    tab: SETTINGS.COLLABORATORE_RISULTATI.TAB,
                    component:
                        pagheViews.risultati.Collaboratori.dettaglioCollaboratori.childs.Risultati,
                    meta: {
                        group: SETTINGS.COLLABORATORE_DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    orderWizard: 0,
                    props: true,
                },
                {
                    label: SETTINGS.COLLABORATORE_CARICHI_FAMIGLIA.LABEL,
                    path: SETTINGS.COLLABORATORE_CARICHI_FAMIGLIA.PATH,
                    name: SETTINGS.COLLABORATORE_CARICHI_FAMIGLIA.NAME,
                    tab: SETTINGS.COLLABORATORE_CARICHI_FAMIGLIA.TAB,
                    component:
                        pagheViews.risultati.Collaboratori.dettaglioCollaboratori.childs
                            .CarichiFamiglia,
                    meta: {
                        group: SETTINGS.COLLABORATORE_DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    orderWizard: 1,
                    props: true,
                },
                {
                    label: SETTINGS.COLLABORATORE_PRESTITI.LABEL,
                    path: SETTINGS.COLLABORATORE_PRESTITI.PATH,
                    name: SETTINGS.COLLABORATORE_PRESTITI.NAME,
                    tab: SETTINGS.COLLABORATORE_PRESTITI.TAB,
                    component:
                        pagheViews.risultati.Collaboratori.dettaglioCollaboratori.childs.Prestiti,
                    meta: {
                        group: SETTINGS.COLLABORATORE_DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    orderWizard: 2,
                    props: true,
                },
                {
                    label: SETTINGS.COLLABORATORE_INAIL.LABEL,
                    path: SETTINGS.COLLABORATORE_INAIL.PATH,
                    name: SETTINGS.COLLABORATORE_INAIL.NAME,
                    tab: SETTINGS.COLLABORATORE_INAIL.TAB,
                    component:
                        pagheViews.risultati.Collaboratori.dettaglioCollaboratori.childs.Inail,
                    meta: {
                        group: SETTINGS.COLLABORATORE_DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    orderWizard: 3,
                    props: true,
                },
                {
                    label: SETTINGS.COLLABORATORE_ENTI.LABEL,
                    path: SETTINGS.COLLABORATORE_ENTI.PATH,
                    name: SETTINGS.COLLABORATORE_ENTI.NAME,
                    tab: SETTINGS.COLLABORATORE_ENTI.TAB,
                    component:
                        pagheViews.risultati.Collaboratori.dettaglioCollaboratori.childs.Enti,
                    meta: {
                        group: SETTINGS.COLLABORATORE_DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    orderWizard: 4,
                    props: true,
                },
                {
                    label: SETTINGS.COLLABORATORE_VOCI.LABEL,
                    path: SETTINGS.COLLABORATORE_VOCI.PATH,
                    name: SETTINGS.COLLABORATORE_VOCI.NAME,
                    tab: SETTINGS.COLLABORATORE_VOCI.TAB,
                    component:
                        pagheViews.risultati.Collaboratori.dettaglioCollaboratori.childs.Voci,
                    meta: {
                        group: SETTINGS.COLLABORATORE_DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    orderWizard: 5,
                    props: true,
                },
            ],
        },
    ],
}
