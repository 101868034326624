<template>
    <div v-if="readOnly">
        <v-icon
            aria-label="Pagina in sola lettura"
            class="text-warning"
            icon="fas fa-lock-keyhole"
            tabindex="0"
        />
        <v-tooltip
            activator="parent"
            location="top"
            role="tooltip"
            text="Solo consultazione"
        />
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import { useStorePermissions } from '@/scripts/stores/Permissions/pagePermission.js'
import { values } from 'lodash'

export interface ReadOnlyProps {
    isReadonly?: boolean
}

const props = defineProps<ReadOnlyProps>()

const permissionsStore = useStorePermissions()

const readOnly = computed(() => {
    if (props.isReadonly !== undefined) return props.isReadonly

    const permRoute = useRoute()?.meta.permissions as { [key: string]: any }
    if (!permRoute) return false
    const codTipoPermesso = permissionsStore.getPermissionType(permRoute.codPermesso)

    if (typeof codTipoPermesso === 'object') {
        //se almeno un elemento e' diverso da 'N' torno true
        return values(codTipoPermesso).every(a => a !== 'A')
    }

    return codTipoPermesso === 'C'
})
</script>

<style lang="scss">
@import '@/styles/global/vars';
</style>
