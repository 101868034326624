import { defineStore } from 'pinia'
import commonStore from '../common'

export const storeName = 'risultatiDipteDettaglioStore'

const useRisultatiDipteDettaglioStore = defineStore(
    storeName,
    commonStore.baseRisultatiDettaglioState,
    {
        persist: false,
    },
)

export default useRisultatiDipteDettaglioStore
