import { get } from 'lodash'
import { AxiosResponse } from 'axios'

import { INDEXED_DB } from '@/constants/index'
import { getDataDb } from '@/composables/indexDB'
import { call1 } from '@/scripts/lib/http'

const BASE_URL = '/Ente'
const defaultPayload: Payload = {}

class API {
    @call1({ url: `${BASE_URL}/GetEnti`, defaultPayload })
    static async get<T extends Payload>(
        payload: T,
        noHttp?: boolean,
    ): Promise<Array<Ente> | AxiosResponse<BasicResponseData<Ente>>> {
        return {
            noHttp: async (payload: T) => {
                let funzioni = null
                if (payload?.filter) {
                    let filters = JSON.parse(payload?.filter) //TODO:FIXARE ARRAY ANNIDATI
                    if (Array.isArray(filters) && !Array.isArray(filters[0])) filters = [filters]
                    funzioni = filters
                        .filter((_: any, idx: number) => (idx + 1) % 2 === 1) // elementi dispari di filter
                        .map((item: string) => item[2]) // nome della funzione in filter
                }
                const res: any = await getDataDb(
                    INDEXED_DB.STORES.ENTI,
                    payload as object,
                    funzioni,
                )
                if (!res?.responseStatus?.isSuccessful) return []
                return get(res, INDEXED_DB.STORES.ENTI.DATA_ROOT, [])
            },
        } as any
    }
}

export default {
    get: API.get<Payload>,
}
