import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

export const BASE_URL_DITTA = '/Ditta'

class API {
    @call({ url: `${BASE_URL_DITTA}/SaveDittaUPStatus` })
    static async save(
        payload: SaveDittaUPStatus,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveDittaUPStatus>> | null> {
        return null
    }
}

export default {
    save: API.save,
}
