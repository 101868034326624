import '@fortawesome/fontawesome-pro/css/all.min.css'
import 'vuetify/styles'
import { aliases, fa } from 'vuetify/iconsets/fa'
import { it } from '@/i18n'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import colors from 'vuetify/lib/util/colors.mjs'

const darkTheme = {
    colors: {
        info: colors.blue.darken3,
    },
}

const lightTheme = {
    // dark: false,
    colors: {
        info: colors.blue.darken2,
        // da qui in poi eventualmente da rivedere
        anti_flash_white: '#F3F4F6',
        primary: '#255e7e', //blue
        secondary: '#A82428', //red
        tertiary: '#095b8b', //blue
        // specifici
        accent: '#5b9bd5',
        back: '#f0f0f0',
        error: '#c40000',
        headers: '#f8f8f8',
        lavender: '#E0E7FF',
        lightblue: '#73A1C2',
        purple: '#3A4078',
        sidebar__button: '#218FB6',
        success: '#4CAF50',
        unlocked__input_border: '#d0d0d0',
        warning: '#FF9800',
    },
}

export default createVuetify({
    // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
    components,
    directives,
    theme: {
        defaultTheme: 'lightTheme',
        themes: {
            dark: darkTheme,
            lightTheme,
        },
    },
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
            fa,
        },
    },
    locale: {
        locale: 'it',
        fallback: 'it',
        messages: { it },
    },
    display: {
        mobileBreakpoint: 'sm',
        thresholds: {
            xs: 340,
            sm: 540,
            md: 800,
            lg: 1280,
        },
    },
})
