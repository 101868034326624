<template>
    <v-dialog
        class="base-dialog"
        :model-value="show"
        no-click-animation
        persistent
        :retain-focus="retainFocus"
        @keydown.esc="$emit('cancel')"
    >
        <v-card
            ref="baseDialogCard"
            class="d-flex align-self-center"
            :min-height="minHeight"
            :min-width="fitContent ? undefined : minWidth"
        >
            <v-card-title :class="`d-flex align-center v-theme--lightTheme bg-${type}`">
                <v-icon
                    class="mr-2"
                    :icon="`far fa-${icon}`"
                    size="small"
                />
                <span class="ml-2">{{ title }}</span>
                <v-spacer />
                <v-icon
                    icon="far fa-xmark"
                    size="small"
                    @click="$emit('cancel')"
                />
            </v-card-title>
            <div
                class="base-dialog-body mb-2"
                :style="{ height: minHeight }"
            >
                <slot name="body" />
            </div>
            <v-spacer />
            <div
                v-if="$slots.bodyFooterLeft || $slots.bodyFooterRight"
                class="d-flex justify-space-between"
            >
                <div class="d-flex mx-3 mb-3">
                    <slot name="bodyFooterLeft" />
                </div>
                <div class="d-flex justify-end mx-3 mb-3">
                    <slot name="bodyFooterRight" />
                </div>
            </div>
        </v-card>
        <v-overlay
            class="align-center justify-center"
            contained
            :model-value="loading"
        >
            <v-progress-circular
                color="primary"
                indeterminate
            />
        </v-overlay>
    </v-dialog>
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref } from 'vue'

export interface BaseDialogProps {
    closeButton?: boolean
    fitContent?: boolean
    icon?: string
    loading?: boolean
    minHeight?: number | string
    minWidth?: number | string
    retainFocus?: boolean
    show: boolean
    title: string
    type: 'error' | 'info' | 'success' | 'warning'
}

withDefaults(defineProps<BaseDialogProps>(), {
    closeButton: false,
    fitContent: false,
    icon: 'rectangle-list',
    loading: false,
    minHeight: 'auto',
    minWidth: '50vw',
    retainFocus: false,
    show: false,
    title: '',
    type: 'info',
})

defineEmits(['cancel'])

const baseDialogCard = ref()

onMounted(() => {
    nextTick(() => {
        if (baseDialogCard.value) dragAndDropDialog()
    })
})

function dragAndDropDialog() {
    function dragMouseDown(event: MouseEvent) {
        event.preventDefault()
        coords.oldX = event.clientX
        coords.oldY = event.clientY
        cardElement.onmouseup = () => {
            cardElement.onmouseup = null
            cardElement.onmousemove = null
        }
        cardElement.onmousemove = (event: MouseEvent) => {
            event.preventDefault()
            coords.newX = coords.oldX - event.clientX
            coords.newY = coords.oldY - event.clientY
            coords.oldX = event.clientX
            coords.oldY = event.clientY
            cardElement.style.top = cardElement.offsetTop - coords.newY + 'px'
            cardElement.style.left = cardElement.offsetLeft - coords.newX + 'px'
        }
    }
    const cardElement = baseDialogCard.value.$el
    const cardTitleElement = cardElement.querySelector('.v-card-title')
    const coords = { newX: 0, newY: 0, oldX: 0, oldY: 0 }
    cardTitleElement.onmousedown = dragMouseDown
    cardTitleElement.style.cursor = 'move'
    cardElement.style.position = 'absolute'
    cardElement.style.transform = 'translateY(-50%)'
    // NOTA: baseDialogCard ha posizione 'absolute', il translateY(-50%) serve per centrarlo in
    //       altezza con il suo contenitore v-overlay__content
}
</script>

<script lang="ts">
export default { name: 'BaseDialog' }
</script>

<style lang="scss">
.base-dialog {
    transition: none;
    .base-dialog-body {
        > .v-window {
            height: 100%;
            .v-window__container,
            .v-window-item {
                height: 100%;
            }
        }
    }
}
</style>
