<template>
    <div
        v-if="!globalStore.isLoading"
        class="d-flex flex-column justify-end mt-2"
    >
        <div class="d-flex align-center justify-end">
            <MixinPeriodo
                v-bind="periodoElab"
                :gestione="route.params.gestione"
                gestione-off
            />
            <MixinValidita
                v-if="!disablePeriodoValidita"
                class="ms-3"
                v-bind="periodoValidita"
            />
            <MixinIsReadOnly class="ms-3" />
            <MixinIsElaborato
                v-if="dittaElaborata"
                class="ms-3"
            />
        </div>
        <div class="d-flex flex-column mt-4 align-end">
            <MixinInfo
                v-if="!disableInfoDitta"
                v-bind="infoTipoDati"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import MixinInfo, { InfoProps } from '@/components/mixins/Info.vue'
import MixinIsElaborato from '@/components/mixins/IsElaborato.vue'
import MixinIsReadOnly from '@/components/mixins/IsReadOnly.vue'
import MixinPeriodo, { PeriodoProps } from '@/components/mixins/Periodo.vue'
import MixinValidita, { ValiditaProps } from '@/components/mixins/Validita.vue'
import { ComputedRef } from 'vue'
import { computed } from 'vue'
import { pagheStores, useGlobalStore } from '@/stores'
import { useRoute } from 'vue-router'

const globalStore = useGlobalStore()
const route = useRoute()

interface DittaRightHeader {
    /**considerato se non vengono passate le props al componente. Le proprieta da visualizzare vengono prese dallo store */
    ditteStore?: any
    /** se true l'infomazione relativa al periodo di validita' viene nascosto */
    disablePeriodoValidita?: boolean
    /** se true viene disabilitato il testo informativo relativo ai dati della ditta */
    disableInfo?: boolean
    /** info details*/
    info?: InfoProps
    /** se true indica che i dati sono della Ditta altrimenti dell U.P. - USATO SOLO NELLE UP */
    isDatiDitta?: boolean
    /** indica se la ditta e' stata elaborata*/
    isElaborato?: boolean
    /** periodo di elaborazione della ditta corrente */
    periodoElab?: PeriodoProps
    /** periodo di validita' dei dati mostrati in pagina */
    validita?: ValiditaProps
}

const props = withDefaults(defineProps<DittaRightHeader>(), {
    disablePeriodoValidita: false,
    disableInfo: undefined,
    isElaborato: undefined,
})

const disableInfoDitta: ComputedRef<boolean> = computed(() => {
    return props.disableInfo !== undefined ? props.disableInfo : true
})

const periodoElab: ComputedRef<PeriodoProps> = computed(() => {
    return (
        props.periodoElab || {
            periodo: props.ditteStore?.getPeriodo?.periodoDiRiferimento,
        }
    )
})

const periodoValidita: ComputedRef<ValiditaProps> = computed(() => {
    return (
        props.validita || {
            isFutura: props.ditteStore?.headerDate?.validitaFutura,
            validita: props.ditteStore?.headerDate,
        }
    )
})

const dittaElaborata: ComputedRef<boolean> = computed(() => {
    if (props.isElaborato !== undefined) return props.isElaborato
    if (props.ditteStore?.isDittaElaborata !== undefined) return props.ditteStore?.isDittaElaborata

    return false
})

const infoTipoDati: ComputedRef<InfoProps> = computed(() => {
    return {
        icon: null,
        text: props.isDatiDitta
            ? 'I dati presenti sono della ditta'
            : "I dati presenti sono dell'U.P.",
        textColor: props.isDatiDitta ? 'error' : 'info',
    }
})
</script>

<style lang="scss">
@import '@/styles/global/vars';
</style>
