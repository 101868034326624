import pagheViews from '@paghe/views'

const tabPropsDefault: TabProps = {
    CODVOCEMENU: '000008',
    GROUP: 'movimentiMese',
    ICON: 'far fa-calendar-circle-user',
    TITLE: 'Movimenti',
}

const SETTINGS = Object.freeze({
    ELENCO: {
        NAME: 'MovimentiElencoDitte',
        PATH: 'movimenti/ditte',
        TAB: { ...tabPropsDefault },
        TITLE: 'Paghe - Movimenti mese',
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.ELENCO.PATH,
            name: SETTINGS.ELENCO.NAME,
            component: pagheViews.movimenti.ditte.Elenco,
            meta: {
                title: SETTINGS.ELENCO.TITLE,
                permissions: {
                    codPermesso: 'MOVIMENTO',
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
        },
    ],
}
