<template>
    <v-row class="h-100 w-100 d-flex align-items-center justify-content-center">
        <v-col class="text-center">
            <h1 class="sr-only">Bacheca</h1>
            <img
                src="@/assets/undraw_data_processing_yrrv.svg"
                class="img-fluid"
                alt="placeholder image"
                title="placeholder image"
                aria-hidden="true"
                border="0"
            />
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import { useTabStore } from '@/stores'
import { ROUTES_INDEX } from '@/routes'

const tabStore = useTabStore()
tabStore.openTab(ROUTES_INDEX.INDEX_BACHECA.NAME, ROUTES_INDEX.INDEX_BACHECA.TAB)
</script>

<style lang="scss">
/** {
	box-sizing: border-box !important;
}*/
.main {
    &__holder {
        width: 100%;
        height: 100%;
    }
    &__menu {
        background-color: white;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        &__displayer {
            height: 100%;
            padding-left: 0.78rem !important;
        }
        &__sidebar {
            border-right: 1px solid rgb(197, 197, 197);
        }
    }
}
.column {
    &__header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        width: 90%;
        height: 40px;
        border-bottom: 2px solid black;
        margin: auto;
    }
    &__body {
        margin-top: 10px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: start;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}

.btn {
    // width: 75%;
    // margin-bottom: 10px;
    // &-primary {
    // 	color: rgb(73, 102, 165);
    // 	background-color: #d4d4d4;
    // 	border-color: #d4d4d4;
    // 	font-weight: 700;
    // }
}

.navigation {
    &__button {
        display: flex;
        align-items: center;
        padding-left: 3%;
        margin: 5px 5px;
        cursor: pointer;
        height: 40px;
        background: linear-gradient(0deg, rgb(226, 226, 226) 0%, rgb(245, 245, 245) 25%);
        border-radius: 4px;
        h6 {
            font-size: 0.9rem !important;
        }
    }
}
</style>
