import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'
import { BASE_URL_DIPTE } from './dipte'

const BASE_URL = '/DipteANF'
class API {
    @call({ url: `${BASE_URL}/GetDipteANF` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponsePayload<DipteANF>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DIPTE}/SaveDipteANF` })
    static async saveDipteANF(
        payload: saveDipteANFPayload,
    ): Promise<AxiosResponse<BasicResponseData<DipteANF>> | null> {
        return null
    }
}

export default {
    get: API.get,
    saveDipteANF: API.saveDipteANF,
}
