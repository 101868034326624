import { defineStore } from 'pinia'
import commonStore from '../common'

export const storeName = 'risultatiCollabDettaglioStore'

const useRisultatiCollabDettaglioStore = defineStore(
    storeName,
    () => commonStore.baseRisultatiDettaglioState(true),
    {
        persist: false,
    },
)

export default useRisultatiCollabDettaglioStore
