import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

const BASE_URL = '/Regione'

class API {
    @call({ url: `${BASE_URL}/GetRegioni` })
    static async get(payload: Payload): Promise<AxiosResponse<BasicResponseData<Regione>> | null> {
        return null
    }
}

export default {
    get: API.get,
}
