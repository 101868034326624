import pagheViews from '@paghe/views'

const tabPropsDefault: TabProps = {
    GROUP: 'risultatiDitta',
    ICON: 'fa-regular fa-buildings',
    TITLE: 'Risultati Ditta',
    CODVOCEMENU: '000010',
    CODPERMESSO: 'RII_DITTA',
}

//https://router.vuejs.org/guide/essentials/passing-props
function propsFn(route) {
    return {
        year: Number(route.params.year),
        month: Number(route.params.month),
        day: Number(route.params.day),
        id: Number(route.params.id),
        idPadre: Number(route.params.idPadre),
        isAllDitta: String(route.params.isAllDitta) === '1',
        isCo: String(route.params.isCo) === '1',
    }
}

export const SETTINGS = Object.freeze({
    DITTE_LISTA: {
        TITLE: 'Elenco Ditte - Risultati - Dipendente - Gestione - Paghe',
        LABEL: 'Risultati Ditta',
        PATH: 'risDitta',
        NAME: 'ElencoDitte',
        TAB: { ...tabPropsDefault },
    },
    DITTA_DETTAGLIO: {
        TITLE: 'Dettaglio risultati dipendenti - Risultati - Ditta dipendete - Gestione - Paghe',
        LABEL: 'Dettaglio ditta dipendenti',
        PATH: 'risDitta/:year/:month/:day/:id/:idPadre/:isAllDitta/:isCo',
        NAME: 'DettaglioRisultatiDettaglio',
        TAB: { ...tabPropsDefault },
    },
    DITTA_RISULTATI: {
        TITLE: '',
        LABEL: 'Risultati',
        PATH: 'risultati',
        NAME: 'DittaRisultati',
        TAB: { ...tabPropsDefault },
    },
    DITTA_RITENUTE: {
        TITLE: '',
        LABEL: 'Ritenute',
        PATH: 'ritenute',
        NAME: 'DittaRisultatiRitenute',
        TAB: { ...tabPropsDefault },
    },
    DITTA_CONTRIBUTI: {
        TITLE: '',
        LABEL: 'Contributi',
        PATH: 'contributi',
        NAME: 'DittaContributi',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.DITTE_LISTA.PATH,
            name: SETTINGS.DITTE_LISTA.NAME,
            component: pagheViews.risultati.Elenco,
            meta: {
                title: SETTINGS.DITTE_LISTA.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            props: true,
        },
        {
            path: SETTINGS.DITTA_DETTAGLIO.PATH,
            name: SETTINGS.DITTA_DETTAGLIO.NAME,
            props: propsFn,
            component: pagheViews.risultati.ditta.dettaglioDitta.Dettaglio,
            meta: {
                title: SETTINGS.DITTA_RISULTATI.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: null,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            children: [
                {
                    label: SETTINGS.DITTA_RISULTATI.LABEL,
                    path: SETTINGS.DITTA_RISULTATI.PATH,
                    name: SETTINGS.DITTA_RISULTATI.NAME,
                    tab: SETTINGS.DITTA_RISULTATI.TAB,
                    component: pagheViews.risultati.ditta.dettaglioDitta.childs.risultati,
                    meta: {
                        idPaginaMsg: 'RD01',
                        group: SETTINGS.DITTA_DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    orderWizard: 0,
                    props: propsFn,
                },
                {
                    label: SETTINGS.DITTA_RITENUTE.LABEL,
                    path: SETTINGS.DITTA_RITENUTE.PATH,
                    name: SETTINGS.DITTA_RITENUTE.NAME,
                    tab: SETTINGS.DITTA_RITENUTE.TAB,
                    component: pagheViews.risultati.ditta.dettaglioDitta.childs.ritenute,
                    meta: {
                        idPaginaMsg: 'RD02',
                        group: SETTINGS.DITTA_DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    orderWizard: 0,
                    props: propsFn,
                },
                {
                    label: SETTINGS.DITTA_CONTRIBUTI.LABEL,
                    path: SETTINGS.DITTA_CONTRIBUTI.PATH,
                    name: SETTINGS.DITTA_CONTRIBUTI.NAME,
                    tab: SETTINGS.DITTA_CONTRIBUTI.TAB,
                    component: pagheViews.risultati.ditta.dettaglioDitta.childs.contributi,
                    meta: {
                        idPaginaMsg: 'RD03',
                        group: SETTINGS.DITTA_DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    orderWizard: 0,
                    props: propsFn,
                },
            ],
        },
    ],
}
