import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

const BASE_URL_INTERMEDIARIO = '/Intermediario'
const BASE_URL_INTERMEDIARIO_COMUNICAZIONE = '/IntermediarioComunicazione'
const BASE_URL_INTERMEDIARIO_FISCALE = '/IntermediarioFiscale'
const BASE_URL_INTERMEDIARIO_INAIL_LUL = '/IntermediarioInailLul'
const BASE_URL_INTERMEDIARIO_UNIEMEN = '/IntermediarioUniemen'

//TODO: TIPIZZARE INGRESSO E USCITA
class API {
    //#region Intermediario
    @call({ url: `${BASE_URL_INTERMEDIARIO}/DeleteIntermediario`, method: 'delete' })
    static async deleteIntermediario(
        payload: BaseDeletePayload,
    ): Promise<AxiosResponse<BaseDeleteResponse> | null> {
        return null
    }

    @call({ url: `${BASE_URL_INTERMEDIARIO}/GetIntermediari` })
    static async getIntermediari(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<IntermediarioResponse>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_INTERMEDIARIO}/CreateIntermediario` })
    static async createIntermediario(
        payload: CreateIntermediarioPayload,
    ): Promise<AxiosResponse<
        BasicResponsePayload<{ intermediario: IntermediarioResponse }>
    > | null> {
        return null
    }

    @call({ url: `${BASE_URL_INTERMEDIARIO}/UpdateIntermediario`, method: 'put' })
    static async updateIntermediario(
        payload: UpdateIntermediarioPayload,
    ): Promise<AxiosResponse<
        BasicResponsePayload<{ intermediario: IntermediarioResponse }>
    > | null> {
        return null
    }
    //#endregion
    //#region Intermediario Comunicazione
    @call({ url: `${BASE_URL_INTERMEDIARIO_COMUNICAZIONE}/GetIntermediarioComunicazione` })
    static async getIntermediariComunicazione(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<IntermediarioComunicazioneResponse>> | null> {
        return null
    }
    //#endregion
    //#region Intermediario Fiscale
    @call({ url: `${BASE_URL_INTERMEDIARIO_FISCALE}/DeleteIntermediarioFiscale`, method: 'delete' })
    static async deleteIntermediarioFiscale(
        payload: BaseDeletePayload,
    ): Promise<AxiosResponse<BaseDeleteResponse> | null> {
        return null
    }

    @call({ url: `${BASE_URL_INTERMEDIARIO_FISCALE}/GetIntermediariFiscale` })
    static async getIntermediariFiscale(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<IntermediarioFiscaleResponse>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_INTERMEDIARIO_FISCALE}/CreateIntermediarioFiscale` })
    static async createIntermediarioFiscale(
        payload: CreateIntermediarioFiscalePayload,
    ): Promise<AxiosResponse<
        BasicResponsePayload<{ intermediarioFiscale: IntermediarioFiscaleResponse }>
    > | null> {
        return null
    }

    @call({ url: `${BASE_URL_INTERMEDIARIO_FISCALE}/UpdateIntermediarioFiscale`, method: 'put' })
    static async updateIntermediarioFiscale(
        payload: UpdateIntermediarioFiscalePayload,
    ): Promise<AxiosResponse<
        BasicResponsePayload<{ intermediarioFiscale: IntermediarioFiscaleResponse }>
    > | null> {
        return null
    }
    //#endregion
    //#region Intermediario Inail Lul
    @call({
        url: `${BASE_URL_INTERMEDIARIO_INAIL_LUL}/DeleteIntermediarioInailLul`,
        method: 'delete',
    })
    static async deleteIntermediarioInailLul(
        payload: BaseDeletePayload,
    ): Promise<AxiosResponse<BaseDeleteResponse> | null> {
        return null
    }

    @call({ url: `${BASE_URL_INTERMEDIARIO_INAIL_LUL}/GetIntermediariInailLul` })
    static async getIntermediariInailLul(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<IntermediarioInailLulResponse>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_INTERMEDIARIO_INAIL_LUL}/CreateIntermediarioInailLul` })
    static async createIntermediarioInailLul(
        payload: CreateIntermediarioInailLulPayload,
    ): Promise<AxiosResponse<
        BasicResponsePayload<{ intermediarioInailLul: IntermediarioInailLulResponse }>
    > | null> {
        return null
    }

    @call({ url: `${BASE_URL_INTERMEDIARIO_INAIL_LUL}/UpdateIntermediarioInailLul`, method: 'put' })
    static async updateIntermediarioInailLul(
        payload: UpdateIntermediarioInailLulPayload,
    ): Promise<AxiosResponse<
        BasicResponsePayload<{ intermediarioInailLul: IntermediarioInailLulResponse }>
    > | null> {
        return null
    }
    //#endregion
    //#region Intermediario Uniemen
    @call({
        url: `${BASE_URL_INTERMEDIARIO_UNIEMEN}/DeleteIntermediarioUniemen`,
        method: 'delete',
    })
    static async deleteIntermediarioUniemen(
        payload: BaseDeletePayload,
    ): Promise<AxiosResponse<BaseDeleteResponse> | null> {
        return null
    }

    @call({ url: `${BASE_URL_INTERMEDIARIO_UNIEMEN}/GetIntermediariUniemen` })
    static async getIntermediariUniemen(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<IntermediarioUniemenResponse>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_INTERMEDIARIO_UNIEMEN}/CreateIntermediarioUniemen` })
    static async createIntermediarioUniemen(
        payload: CreateIntermediarioUniemenPayload,
    ): Promise<AxiosResponse<
        BasicResponsePayload<{ intermediarioUniemen: IntermediarioUniemenResponse }>
    > | null> {
        return null
    }

    @call({ url: `${BASE_URL_INTERMEDIARIO_UNIEMEN}/UpdateIntermediarioUniemen`, method: 'put' })
    static async updateIntermediarioUniemen(
        payload: UpdateIntermediarioUniemenPayload,
    ): Promise<AxiosResponse<
        BasicResponsePayload<{ intermediarioUniemen: IntermediarioUniemenResponse }>
    > | null> {
        return null
    }
    //#endregion
}

export default {
    deleteIntermediario: API.deleteIntermediario,
    getIntermediari: API.getIntermediari,
    createIntermediario: API.createIntermediario,
    updateIntermediario: API.updateIntermediario,
    //
    getIntermediariComunicazione: API.getIntermediariComunicazione,
    //
    deleteIntermediarioFiscale: API.deleteIntermediarioFiscale,
    getIntermediariFiscale: API.getIntermediariFiscale,
    createIntermediarioFiscale: API.createIntermediarioFiscale,
    updateIntermediarioFiscale: API.updateIntermediarioFiscale,
    //
    deleteIntermediarioInailLul: API.deleteIntermediarioInailLul,
    getIntermediariInailLul: API.getIntermediariInailLul,
    createIntermediarioInailLul: API.createIntermediarioInailLul,
    updateIntermediarioInailLul: API.updateIntermediarioInailLul,
    //
    deleteIntermediarioUniemen: API.deleteIntermediarioUniemen,
    getIntermediariUniemen: API.getIntermediariUniemen,
    createIntermediarioUniemen: API.createIntermediarioUniemen,
    updateIntermediarioUniemen: API.updateIntermediarioUniemen,
}
