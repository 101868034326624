import { http } from '@/scripts/lib/http'
import { useGlobalStore } from '@/stores'

const BASE_URL = '/DiarioCodice'

const payload: Payload = {
    requireTotalCount: false,
    isCountQuery: false,
}

export default class APIDiarioCodice {
    static async get(params?: Payload) {
        const globalStore = useGlobalStore()

        return http.post(`${BASE_URL}/GetDiarioCodice`, {
            ...payload,
            ...{
                periodoDiRiferimento: globalStore.state.periodoElab,
            },
            ...params,
        })
    }
}
