import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'
import { BASE_URL_DITTA } from './ditta'

const BASE_URL_ASSENTEISMO = '/DittaAssenteismo'

class API {
    @call({ url: `${BASE_URL_ASSENTEISMO}/GetDittaAssenteismo` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DittaAssenteismo>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DITTA}/SaveDittaAssenteismo` })
    static async save(
        payload: SaveDittaAssenteismoPayload,
    ): Promise<AxiosResponse<BasicDittaResponsePayload<DittaAssenteismo>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
