import { defineStore } from 'pinia'
import { computed, reactive, ref, Ref, shallowReactive } from 'vue'

import { pagheAPI } from '@/api'
import { pagheRoutes } from '@/routes'
import { STORES_NAMES } from '@/constants'
import { useDynamicStore } from '@/composables/dynamic-store'
import { useGlobalStore } from '@/stores'

const useStudioStore = defineStore(
    STORES_NAMES.STUDIO,
    () => {
        const globalStore = useGlobalStore()
        const activeChildName: Ref<string | null> = ref(null)
        const studio = reactive({ id: null, codice: null, denominazione: null })
        const _isNote = ref(false)
        const focusElementUID = ref(null)

        const state: DitteStoreState = reactive({
            data: {
                codiceDitta: '',
                unitaProduttiva: '',
                dittaUPRagioneSociale: {
                    ragioneSociale: '',
                    denominazioneAggiuntiva: '',
                },
            },
            isDittaElaborata: false,
            props: null,
            dataChild: {
                childName: null,
                origin: null,
                originPadre: null,
                form: null,
                isPadre: true,
                gestUPFigliaInit: false,
                gestUPFiglia: false,
                //focussedItem: '',
            },
            localTab: 0,
            lastIdx: -1, //index dell'ultimo elemento visualizzato ---> utilizzato per determinare il periodo e di conseguenza se riscaricare i dati delle lookup
            organico: Object.assign({}, undefined),
        })

        const dataLookup = shallowReactive(new Map())

        const getPeriodo = computed(() => {
            return { periodoDiRiferimento: globalStore.state.periodoElab }
        })

        function setProps(props: any) {
            if (state.props != null) {
                Object.assign(state.props, props)
            } else {
                state.props = { ...props }
            }
        }

        function resetPageData(resetLookup: boolean = true, navigation: boolean = false) {
            resetBaseData(resetLookup, navigation)
            //reset dati aggiuntivi
            state.dataChild.origin = null
            state.dataChild.originPadre = null
            state.dataChild.form = null
            if (state.dataChild.changeMap && state.dataChild.changeMap.clear)
                state?.dataChild?.changeMap?.clear() //vedere se metterlo qui o in resetBaseData
            state.dataChild._grid = null
        }

        function resetBaseData(resetLookup: boolean = true, navigation: boolean = false) {
            state.dataChild.childName = null
            if (resetLookup) {
                dataLookup.clear() //PER NUIOVA GESTIONE LOOKUP
            }
            state.dataChild.isPadre = true
            state.dataChild.origin = null //fix per datalookup uguali non scatta il watch
            focusElementUID.value = ''
            if (!navigation) {
                //operazioni da fare solo se non si sta navigando tra le ditte
                localTab.value = 0
            }
        }

        async function getData() {
            if (studio.id != null) return
            try {
                const response = await pagheAPI.anagrafiche.studio.get({
                    select: JSON.stringify([
                        'id',
                        'codice',
                        'denominazione',
                        'containsStudioNoteCalc',
                    ]),
                    take: 1, // ad oggi prende il primo in tabella
                })
                const { responseStatus, data } = response.data
                if (responseStatus.isSuccessful) {
                    studio.id = data[0].id
                    studio.codice = data[0].codice
                    studio.denominazione = data[0].denominazione
                    isNote.value = data[0].containsStudioNoteCalc
                }
            } catch (err) {
                console.error('Errore durante la chiamata: ', err)
            }
        }

        function resetActiveChildName() {
            activeChildName.value = pagheRoutes.anagrafiche.studio.SETTINGS.GENERALE.NAME
        }

        const isNote = computed({
            get: () => _isNote.value,
            set: (val: boolean) => (_isNote.value = val),
        })

        const dataChildFilled = computed(() => {
            return (
                state.dataChild &&
                state.dataChild.origin &&
                state.dataChild.childName === activeChildName.value
            )
        })

        const noteContext = computed(() => ({
            entityId: studio.id,
            entityType: 'STUDIO',
            isNote,
            title: 'studio',
            vCodDes: 'TAG-ALERT-STUDIO',
        }))

        const localTab = ref(0)

        return {
            ...useDynamicStore(),
            activeChildName,
            getData,
            isNote,
            noteContext,
            studio,
            state,
            getPeriodo,
            dataLookup,
            localTab,
            resetPageData,
            setProps,
            focusElementUID,
            resetActiveChildName,
            dataChildFilled,
        }
    },
    {
        persist: false,
    },
)

export default useStudioStore
