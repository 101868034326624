<template>
    <v-sheet
        :id="uid"
        v-resize="updateHeaderHeight"
        class="base-table"
        height="99%"
        tabindex="0"
    >
        <v-card
            v-if="filter"
            class="table-header"
            flat
            :style="{ borderRadius: '0px' }"
            height="fit-content"
            min-height="60px"
        >
            <v-row class="my-1 align-start">
                <v-col
                    md="3"
                    class="base-table-filter"
                >
                    <BaseInputText
                        ref="filtro"
                        :model-value="state.filtro"
                        hide-details
                        label="Filtro"
                        mask="lower"
                        @update:model-value="updateFiltro"
                    />
                </v-col>
                <v-col class="d-flex justify-space-evenly">
                    <slot name="filterExt" />
                </v-col>
                <v-col
                    v-if="newItemButton !== null || reloadButton || $slots.rightHeader"
                    class="d-flex justify-end"
                    md="2"
                >
                    <slot name="rightHeader" />
                    <v-btn
                        v-if="newItemButton !== null"
                        class="base me-2"
                        :disabled="!newItemButton"
                        flat
                        @click="emit('newItem')"
                    >
                        <v-icon
                            color="white"
                            icon="far fa-plus"
                        />
                        <v-tooltip
                            activator="parent"
                            location="top"
                            :text="newItemButtonString"
                        />
                    </v-btn>
                    <v-btn
                        v-if="reloadButton"
                        class="base"
                        flat
                        @click="reloadItems"
                    >
                        <v-icon
                            color="white"
                            icon="far fa-arrow-rotate-right"
                        />
                        <v-tooltip
                            activator="parent"
                            location="top"
                            :text="reloadButtonString"
                        />
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
        <v-card
            id="table"
            class="table-body"
            :height="`calc(100% - ${headerHeight}px)`"
        >
            <h2 class="sr-only">Elenco dei risultati</h2>
            <!-- ARIA LIVE per comunicare eventuali cambi nelle voci di elenco della tabella -->
            <div
                id="aria-live-message"
                aria-live="polite"
                aria-hidden="true"
                class="sr-only"
            >
                {{ ariaLiveMessage }}
            </div>
            <v-badge
                id="badge"
                class="selectedBadge"
                :content="selectedKeys?.length || ''"
                offset-x="-43"
                offset-y="6"
                :style="badgeStyle"
            />
            <v-data-table
                ref="dataTable"
                v-bind="dtOptions"
                v-model="selectedKeys"
                density="compact"
                fixed-header
                :items-per-page="state.itemPerPage"
                :item-value="props.selectionKey"
                :items="items"
                multi-sort
                :page="currentPage"
                select-strategy="all"
                @dblclick="enterRow"
                @update:sort-by="updateSort"
            >
                <!-- SLOTS HEADERS -->
                <template
                    v-for="(iterHeader, idx) in dtProps.headers"
                    :key="`header${idx}`"
                    #[`header.${iterHeader.key}`]="{ column }"
                >
                    <span> {{ column.title }}</span>
                    <template v-for="(sort, key) in [sortBy(column)]">
                        <!-- con 'template v-for ...' evito di chiamare più volte il metodo 'sortBy' -->
                        <v-badge
                            v-if="sort"
                            :key="`sort${key}`"
                            class="sort-number"
                            :content="sort.index"
                            floating
                            offset-x="2"
                            offset-y="10"
                            :aria-sort="sort.order"
                        >
                            <v-icon
                                class="ml-1"
                                :icon="`far fa-angle-${{ asc: 'up', desc: 'down' }[sort.order]}`"
                            />
                        </v-badge>
                    </template>
                </template>
                <!-- SLOTS SELECT ALL ITEMS -->
                <template #header.data-table-select="{ allSelected, selectAll, someSelected }">
                    <v-checkbox
                        ref="selectAllCheckbox"
                        density="compact"
                        hide-details
                        :indeterminate="someSelected && !allSelected"
                        indeterminate-icon="fal fa-square-minus"
                        :model-value="allSelected"
                        true-icon="fal fa-square-check"
                        @click="selectAllRows(selectAll, !allSelected)"
                    />
                </template>
                <!-- SLOTS ITEMS -->
                <template #item="{ columns, isSelected, item }: any">
                    <tr
                        :class="{ disabled: !selectedRow(isSelected, item) }"
                        :selection-key="(item as any)[props.selectionKey]"
                        @click="event => clickRow(event, item)"
                    >
                        <td
                            v-for="(column, idx) of columns"
                            :key="`item${idx}`"
                            :class="{
                                divider: column.divider || column.key === 'data-table-select',
                            }"
                            :style="{
                                textAlign: column.align,
                                minWidth: `${column.width}px`,
                                maxWidth: `${column.width}px`,
                            }"
                        >
                            <v-checkbox
                                v-if="column.key === 'data-table-select'"
                                density="compact"
                                hide-details
                                :model-value="isSelected({ value: item[props.selectionKey] })"
                                true-icon="fal fa-square-check"
                                @click="toggleSelect(item)"
                            />
                            <div
                                v-else-if="!column.slot"
                                :class="item._class || null"
                                :style="item._style || null"
                            >
                                <v-icon
                                    v-if="column.type === 'check'"
                                    color="success"
                                    :icon="get(item, column.key) ? 'fas fa-check' : ''"
                                />
                                <span v-else>
                                    {{ formatValue(get(item, column.key), column) }}
                                </span>
                            </div>
                            <div v-else>
                                <slot
                                    v-bind="{
                                        attr: { disabled: !selectedRow(isSelected, item) },
                                        column: columns as any,
                                        item: item as any,
                                    }"
                                    :name="column.key"
                                />
                            </div>
                        </td>
                    </tr>
                </template>
                <template #bottom>
                    <v-row
                        class="table-footer mt-4 d-flex align-center"
                        no-gutters
                    >
                        <v-col>
                            <BaseInputLookup
                                v-model="state.itemPerPage"
                                class="mb-2 ml-2"
                                is-select
                                :items="[
                                    { codice: 25, descrizione: '25' },
                                    { codice: 50, descrizione: '50' },
                                    { codice: 100, descrizione: '100' },
                                ]"
                                density="compact"
                                hide-details
                                :hint-message="false"
                                label="Righe per pagina"
                                :show-item-value="false"
                                style="width: 150px"
                            />
                        </v-col>
                        <v-col>
                            <v-pagination
                                ref="pagination"
                                v-model="currentPage"
                                :border="true"
                                density="compact"
                                first-icon="far fa-chevrons-left"
                                last-icon="far fa-chevrons-right"
                                :length="totPages"
                                next-icon="far fa-angle-right"
                                prev-icon="far fa-angle-left"
                                show-first-last-page
                                total-visible="10"
                                @update:model-value="scrollToFirstRow"
                            />
                        </v-col>
                        <v-col class="d-flex justify-end">
                            <div class="mr-4">{{ currentPagesText }}</div>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
        </v-card>
    </v-sheet>
</template>

<script setup lang="ts">
import type { Ref, ShallowReactive } from 'vue'
import type { VDataTable } from 'vuetify/components'
import type { Pinia } from 'pinia'
import {
    CSSProperties,
    ComputedRef,
    computed,
    getCurrentInstance,
    inject,
    nextTick,
    onMounted,
    onUnmounted,
    reactive,
    shallowRef,
    watch,
} from 'vue'
import { defineStore } from 'pinia'
import { cloneDeep, orderBy, get } from 'lodash'
import moment from 'moment'

import BaseInputLookup from '@/components/base/BaseInputLookup.vue'
import BaseInputText from '@/components/base/BaseInputText.vue'
import utyDate from '@/scripts/services/date'

type SelectedKey = number | string
type ReadonlyHeaders = VDataTable['$props']['headers']
type UnwrapReadonlyArray<A> = A extends Readonly<Array<infer I>> ? I : never
type ReadonlyDataTableHeader = UnwrapReadonlyArray<ReadonlyHeaders>
export type ColumnHeader = Omit<ReadonlyDataTableHeader, 'align' | 'key'> & {
    align?: string
    divider?: boolean
    key: string | null
    length?: number
    type?: string
    slot?: boolean
}

export interface IBaseTableProps {
    dtProps: any
    filter?: boolean
    height?: null | Function | number | string
    newItemButton?: boolean | null | string
    reloadButton?: boolean | string
    selectedKey?: number | string
    selectionKey?: string
    showSelect?: boolean
    store?: any
}

const props = withDefaults(defineProps<IBaseTableProps>(), {
    filter: true,
    height: null,
    newItemButton: null,
    reloadButton: false,
    selectedKey: undefined,
    selectionKey: 'id',
    store: undefined,
})

const pinia = inject<Pinia>('pinia')
const emit = defineEmits(['clickRow', 'dataReload', 'enterRow', 'newItem', 'selectRow'])

const ariaLiveMessage: Ref<string> = shallowRef('')
const dataTable: Ref<any> = shallowRef()
const filtro: Ref<any> = shallowRef()
const headerHeight: Ref<number> = shallowRef(0)
const selectAllCheckbox: Ref<any> = shallowRef()
const uid: Ref<string> = shallowRef(`basetable${getCurrentInstance()!.uid}`)

// Crea uno store temporaneo se non ne viene passato uno tramite props
const defStore = props.store
    ? null
    : defineStore(uid.value, () => {
          const state: ShallowReactive<any> = reactive({
              currentPage: 1,
              filtro: '',
              itemPerPage: 50,
              selectedKeys: [],
              sortBy: props.dtProps.sortBy || [],
          })
          return { state }
      })()

const badgeStyle: ComputedRef<CSSProperties> = computed(() => {
    return {
        position: 'fixed',
        visibility: dtOptions.value.showSelect && selectedKeys.value?.length ? 'visible' : 'hidden',
        zIndex: '99999',
    }
})

const currentPage = computed({
    get: () => state.value.currentPage,
    set: val => (state.value.currentPage = val || 1),
})

const currentPagesText = computed(() => {
    let end = currentPage.value * state.value.itemPerPage
    let start = end ? end - state.value.itemPerPage + 1 : 0
    if (end > items.value.length) end = items.value.length
    if (start > end) start = end
    return `${start} - ${end} di ${items.value.length}`
})

const dtOptions = computed(() => {
    props.dtProps.headers.forEach((header: ColumnHeader) => {
        if (!('divider' in header)) header.divider = true
    })
    const settings = Object.assign(
        // base
        { headers: [], items: [], showSelect: true },
        // props
        props.dtProps,
        // store
        { sortBy: state.value?.sortBy || [] },
    )
    if (!Array.isArray(settings.sortBy)) settings.sortBy = [settings.sortBy]
    return settings
})

const items = computed(() => {
    const sortKeys = state.value.sortBy.map((item: any) => item.key)
    const sortOrders = state.value.sortBy.map((item: any) => item.order)
    let items = orderBy(cloneDeep(props.dtProps.items), sortKeys, sortOrders)
    items.forEach((item: any) => Object.entries(item).forEach(([k, v]) => (item[k] = v || '')))
    const filtro = state.value.filtro?.toLowerCase()
    if (filtro) {
        items = items.filter((item: any) =>
            props.dtProps.headers.some((header: ColumnHeader) => {
                if (!header.key) return false
                const value = formatValue(get(item, header.key), header)
                return value?.toString().toLowerCase().includes(filtro)
            }),
        )
    }
    return items
})

const newItemButtonString = computed(() =>
    typeof props.newItemButton === 'string' ? props.newItemButton : 'Nuovo elemento',
)

const reloadButtonString = computed(() =>
    typeof props.reloadButton === 'string' ? props.reloadButton : 'Ricarica',
)

const selectedKeys = computed({
    get: () => state.value.selectedKeys,
    set: newSelectedKeys => {
        const sortKeys = state.value.sortBy.map((item: any) => item.key)
        const sortOrders = state.value.sortBy.map((item: any) => item.order)
        let keys = orderBy(props.dtProps.items, sortKeys, sortOrders).map(
            (item: any) => item[props.selectionKey],
        )
        if (dtOptions.value.showSelect) {
            keys = keys.filter((key: SelectedKey) => newSelectedKeys.includes(key))
        }
        state.value.selectedKeys = keys
    },
})

const state = computed(() => {
    const state = props.store?.state || props.store || defStore?.state
    if (state.selectAllOnEnter === undefined) state.selectAllOnEnter = state.selectedKeys === true
    if (state.currentPage === undefined) state.currentPage = 1
    if (state.itemPerPage === undefined) state.itemPerPage = 50
    return state
})

const totPages = computed(() => Math.ceil(items.value.length / state.value.itemPerPage))

function beforeKeyDown(event: KeyboardEvent, force = false, select = true) {
    function goToRow(tr: Element | null, block: Block, selectRow: boolean) {
        if (!tr) return
        const key = tr.getAttribute('selection-key')
        if (selectRow) {
            const item = items.value.find((item: any) => item[props.selectionKey] == key)
            clickRow(tr, item)
        }
        if (block === 'start') {
            tr = tr.previousElementSibling
            if (!tr) {
                tr = firstPageRow
                block = 'end'
            }
        }
        tr?.scrollIntoView({ behavior: 'instant', block })
    }
    type Block = 'start' | 'center' | 'end' | 'nearest'
    if (!items.value.length || ((event.target as Element)?.id !== uid.value && !force)) return
    const marginTop =
        document.querySelector(`#${uid.value} thead tr`)?.getBoundingClientRect().bottom || 0
    const marginBottom =
        document.querySelector(`#${uid.value} .v-table__wrapper`)?.getBoundingClientRect().bottom ||
        0
    const queryStrTR = `#${uid.value} tbody tr`
    const firstPageRow = document.querySelector(`${queryStrTR}:nth-child(1)`)
    const lastPageRow = document.querySelector(`${queryStrTR}:last-child`)
    const keyHomeEvent = new KeyboardEvent('keydown', { code: 'Home' })
    const keyEndEvent = new KeyboardEvent('keydown', { code: 'End' })
    const currentTR: Element | null =
        document.querySelector(`${queryStrTR}.highlight-row-color`) || firstPageRow || null
    let tr: Element | null | undefined = null
    let block: Block = 'nearest'
    let selectRow = select
    switch (event.code) {
        case 'ArrowDown':
            tr = currentTR?.nextElementSibling
            if (!tr && currentPage.value < totPages.value) {
                currentPage.value += 1
                nextTick(() => beforeKeyDown(keyHomeEvent, true))
            }
            break
        case 'ArrowUp':
            tr = currentTR?.previousElementSibling
            if (tr) {
                if (tr.getBoundingClientRect().top <= marginTop) block = 'start'
            } else {
                if (currentPage.value > 1) {
                    currentPage.value -= 1
                    nextTick(() => beforeKeyDown(keyEndEvent, true))
                }
            }
            break
        case 'End':
            if (event.ctrlKey) {
                currentPage.value = totPages.value
                nextTick(() => beforeKeyDown(keyEndEvent, true))
            } else {
                if (event.shiftKey || force) {
                    tr = lastPageRow
                    block = 'end'
                }
            }
            break
        case 'Enter':
            if (props.store?.actualIndex >= 0) {
                const key = selectedKeys.value[props.store.actualIndex]
                const queryString = `${queryStrTR}[selection-key="${key}"]`
                enterRow(document.querySelector(queryString))
            } else {
                enterRow(document.querySelector(`${queryStrTR}.highlight-row-color`))
            }
            return
        case 'Home':
            if (event.ctrlKey) {
                currentPage.value = 1
                nextTick(() => beforeKeyDown(keyHomeEvent, true))
            } else {
                if (event.shiftKey || force) {
                    tr = firstPageRow
                    block = 'start'
                }
            }
            break
        case 'PageDown':
            selectRow = false
            tr = Array.from(document.querySelectorAll(queryStrTR)).find(
                tr => tr.getBoundingClientRect().bottom > marginBottom,
            )
            if (event.shiftKey || !tr || tr === lastPageRow) {
                if (currentPage.value < totPages.value) {
                    currentPage.value += 1
                    nextTick(() => beforeKeyDown(keyHomeEvent, true, false))
                }
                return
            }
            block = 'start'
            break
        case 'PageUp':
            selectRow = false
            tr = Array.from(document.querySelectorAll(queryStrTR))
                .reverse()
                .find(tr => tr.getBoundingClientRect().top < marginTop)
            if (event.shiftKey || !tr || tr === lastPageRow) {
                if (currentPage.value > 1) {
                    currentPage.value -= 1
                    nextTick(() =>
                        beforeKeyDown(event.shiftKey ? keyHomeEvent : keyEndEvent, true, false),
                    )
                }
                return
            }
            block = 'end'
            break
        case 'Space':
            if (dtOptions.value.showSelect) {
                tr = document.querySelector(`${queryStrTR}.highlight-row-color`)
                let key = selectedKeys.value[props.store.actualIndex]
                if (key) {
                    const idx = selectedKeys.value.indexOf(key)
                    if (idx >= 0) selectedKeys.value.splice(idx, 1)
                } else {
                    key = tr?.getAttribute('selection-key')
                    if (key) selectedKeys.value.push(parseInt(key))
                }
                selectedKeys.value = selectedKeys.value.slice()
            }
            break
    }
    if (tr) {
        nextTick(() => setTimeout(() => goToRow(tr!, block, selectRow), 50))
        event.preventDefault()
    }
}

let highlightedRow: Element | null
function clickRow(event: MouseEvent | Element, item: any) {
    let tr
    if (event instanceof MouseEvent) {
        if (!event.target) return
        tr = (event.target as Element).closest('tr')
    } else {
        tr = event
    }
    if (!tr) return
    highlightedRow?.classList.remove('highlight-row-color')
    const selectionKey = tr.getAttribute('selection-key')
    highlightedRow = tr
    highlightedRow?.classList.add('highlight-row-color')
    nextTick(() => emit('clickRow', item))
    if (props.store?.actualIndex !== undefined) {
        // eslint-disable-next-line vue/no-mutating-props
        props.store.actualIndex = selectedKeys.value.findIndex(
            (k: number | string) => k == selectionKey,
        )
    }
}

function enterRow(event: MouseEvent | Element | null) {
    let tr
    if (event instanceof MouseEvent) {
        if (!event.target) return
        tr = event.target as Element
    } else {
        tr = event
    }
    if (!tr) return
    const comp = tr.hasAttribute('selection-key') ? tr : tr.closest('[selection-key]')
    if (!comp) return
    const selectionKey = comp.getAttribute('selection-key')
    if (!selectionKey) return
    const selectedItem = props.dtProps.items.find(
        (item: object) => item[props.selectionKey as keyof typeof item] == selectionKey,
    )
    if (selectedItem) nextTick(() => emit('enterRow', selectedItem))
}

function formatValue(value: any, column: ColumnHeader) {
    if (!value) return null
    switch (column.type) {
        // GENERICI
        case 'code0':
            return value && column?.length
                ? value.toString().padStart(column.length, '0')
                : value || null
        case 'date6':
            if (utyDate.dateOk(value)) return utyDate.format(value, 'monthYear')
            break
        case 'date8':
            if (utyDate.dateOk(value)) return utyDate.format(value)
            break
        case 'datetime':
            return moment(value).format('DD/MM/YYYY - HH:mm:ss')
        // SPECIFICI
        case 'codeEnte':
            return value.length > 4 ? (value = `${value.slice(0, 4)}.${value.slice(4)}`) : value
        default:
            return value
    }
}

function reloadItems() {
    emit('dataReload')
    updateFiltro('', false)
}

function scrollToFirstRow() {
    highlightedRow = document.querySelector(`#${uid.value} tbody tr:nth-child(1)`) || null
    highlightedRow?.scrollIntoView({ behavior: 'instant', block: 'center' })
}

function selectAllRows(selectAll: Function, allSelected: boolean) {
    selectAll(allSelected)
    emit('selectRow', selectedKeys.value)
}

function selectedRow(isSelected: Function, item: any): boolean {
    return (
        !dtOptions.value.showSelect ||
        (dtOptions.value.showSelect && isSelected({ value: item[props.selectionKey] }))
    )
}

function sortBy(column: ColumnHeader): null | { index: number; order: string } {
    if (dataTable.value && column.sortable) {
        let idx = dtOptions.value.sortBy.findIndex((h: any) => h.key === column.key)
        idx++
        if (idx) {
            const _sort = dataTable.value.sortBy[idx - 1]
            if (_sort) return { index: idx, order: _sort.order }
        }
    }
    return null
}

function toggleSelect(item: any) {
    const keys = selectedKeys.value.slice()
    const key = item[props.selectionKey]
    const index = keys.indexOf(key)
    if (index > -1) {
        keys.splice(index, 1)
    } else {
        keys.push(key)
    }
    selectedKeys.value = keys
    emit('selectRow', selectedKeys.value)
}

function updateFiltro(value: string, resetItems: boolean = true) {
    currentPage.value = 1
    state.value.filtro = value
    if (dtOptions.value.showSelect) {
        if (resetItems) selectedKeys.value = []
        nextTick(() => {
            emit('clickRow', null)
            selectAllCheckbox.value?.$el.querySelector('input[type="checkbox"]')?.click()
        })
    }
    if (highlightedRow) highlightedRow.classList.remove('highlight-row-color')
}

function updateHeaderHeight() {
    const queryStr = `#${uid.value} .v-card.table-header`
    headerHeight.value = (document.querySelector(queryStr) as HTMLElement)?.offsetHeight
}

function updateSort(_sortBy: { key: string; order: 'asc' | 'desc' }[]) {
    currentPage.value = 1
    state.value.sortBy = _sortBy
    if (dtOptions.value.showSelect) selectedKeys.value = selectedKeys.value.slice()
}

watch(
    () => [props.dtProps.items.length],
    async () => {
        if (dtOptions.value.showSelect) {
            if (!isMounted) state.value.selectedKeys = state.value.selectAllOnEnter
            if (state.value.selectedKeys === true) {
                selectedKeys.value = items.value.map((item: any) => item[props.selectionKey])
            } else {
                if (!state.value.selectAllOnEnter) selectedKeys.value = []
            }
        }
        isMounted = false
        nextTick(() => {
            let idx = 0
            let key = null
            if (dtOptions.value.showSelect) {
                idx = props.store?.actualIndex || 0
                key = selectedKeys.value[idx]
            } else {
                if (props.selectedKey) {
                    idx = items.value.findIndex(
                        (item: any) => item[props.selectionKey] === props.selectedKey,
                    )
                    if (idx >= 0) key = props.selectedKey
                }
            }
            if (!key) return
            if (key) currentPage.value = Math.ceil((idx + 1) / state.value.itemPerPage)
            highlightedRow?.classList.remove('highlight-row-color')
            setTimeout(() => {
                const queryString = `#${uid.value} tbody tr[selection-key="${key}"]`
                highlightedRow = document.querySelector(queryString) || null
                highlightedRow?.scrollIntoView({ behavior: 'instant', block: 'center' })
                highlightedRow?.classList.add('highlight-row-color')
            }, 50)
        })
    },
)

let isMounted = false
onMounted(() => {
    isMounted = true
    nextTick(() => filtro.value?.$el.querySelector('.v-input input').focus())
    document.addEventListener('keydown', beforeKeyDown)
})

onUnmounted(() => {
    if (!props.store) {
        delete pinia?.state.value[uid.value]
        if (defStore) defStore.$dispose()
    }
    currentPage.value = 0 // per far scattare il ricalcolo al rientro (vedi watch di items)
    document.removeEventListener('keydown', beforeKeyDown)
})
</script>

<style lang="scss">
@import '@/styles/global/vars';
.base-table {
    outline: none;
    .table-header {
        background: var(--bg-page-container);
        .base-table-filter .input-box {
            input {
                text-transform: lowercase;
            }
        }
        .v-btn.base {
            background-color: var(--bg-button-base);
        }
    }
    .table-body {
        .v-data-table {
            height: 100%;
            background: var(--bg-page-container);
            .v-table__wrapper {
                height: 100%;
            }

            thead th {
                background-color: var(--bg-page-container) !important;
                border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
                text-align: center !important;
                &:not(:has(.v-selection-control)) {
                    color: var(--color-table-column-header);
                    font-family: Roboto, sans-serif;
                    font-size: 12px;
                    font-weight: bold;
                }
                div.v-badge:not(.selectedBadge) span {
                    background: transparent !important;
                    color: var(--color-table-column-header) !important;
                }
                .v-input.v-checkbox {
                    color: var(--color-table-select-all);
                    display: flex;
                    justify-content: center;
                }
            }
            tbody {
                font-family: Roboto, sans-serif;
                font-size: 14px;
                tr {
                    cursor: default;
                    &:has(td:hover) {
                        td {
                            background-color: var(--bg-table-row-hover);
                        }
                    }
                    &:not(.highlight-row-color) .v-btn:not(.base-table-row-icon) {
                        background-color: var(--bg-table-action-button);
                    }
                    &.disabled td:not(:has(.v-selection-control)) {
                        color: var(--color-table-row-disabled);
                        .v-btn.v-btn--disabled .v-icon {
                            opacity: 0.5;
                        }
                    }
                    &.highlight-row-color {
                        td {
                            background-color: var(--bg-table-row-selected);
                        }
                        .v-btn:not(.base-table-row-icon) {
                            background-color: var(--bg-table-action-button-selected-row);
                        }
                    }
                    td {
                        background: transparent;
                        &.divider {
                            border-right: thin solid
                                rgba(var(--v-border-color), var(--v-border-opacity));
                        }
                        div.highlight {
                            font-weight: 500;
                        }
                        &.v-data-table-column--align- {
                            &center {
                                text-align: center;
                            }
                            &left {
                                text-align: left;
                            }
                            &right {
                                text-align: right;
                            }
                        }
                        .v-input.v-checkbox {
                            display: flex;
                            justify-content: center;
                        }
                        .v-selection-control.v-checkbox-btn {
                            height: 36px !important;
                        }
                    }
                }
            }
        }
    }
    .table-footer {
        font-family: Roboto, sans-serif;
        margin-bottom: 5px !important;
        .v-pagination .v-pagination__list {
            margin-bottom: 0;
            padding-bottom: 5px;
            li {
                button {
                    font-size: small;
                }
                &.v-pagination__first,
                &.v-pagination__last,
                &.v-pagination__next,
                &.v-pagination__prev {
                    button {
                        font-size: x-small;
                    }
                }
            }
        }
    }
}
</style>
