import pagheViews from '@paghe/views'

const tabPropsDefault: TabProps = {
    CODVOCEMENU: '000120',
    GROUP: 'exportLavoratori',
    ICON: 'fa-regular fa-file-export',
    TITLE: 'Export lavoratori',
}

const SETTINGS = Object.freeze({
    DETTAGLIO: {
        NAME: 'ExportLavoratori',
        PATH: 'export/lavoratori',
        TAB: { ...tabPropsDefault },
        TITLE: 'Paghe - Export lavoratori',
    },
    ANAGRAFICA: {
        NAME: 'Anagrafica',
        PATH: 'anagrafica',
        TAB: { ...tabPropsDefault },
        TITLE: '',
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            component: pagheViews.exports.dipendenti.Dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: 'DEF_EXPORT_SP',
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            children: [
                {
                    path: SETTINGS.ANAGRAFICA.PATH,
                    name: SETTINGS.ANAGRAFICA.NAME,
                    component: pagheViews.exports.dipendenti.childs.Export,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: 'DEF_EXPORT_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                },
            ],
        },
    ],
}
