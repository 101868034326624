import { useGlobalStore } from '@/stores'
import pagheViews from '@paghe/views'

const tabPropsDefault: TabProps = {
    GROUP: 'tabelleVarie',
    ICON: 'fa-regular fa-table-list',
    TITLE: 'Varie',
    CODVOCEMENU: '000038',
    CODPERMESSO: 'TABELLE_SP',
}

export const SETTINGS = Object.freeze({
    TABELLE_VARIE: {
        TITLE: 'Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Varie',
        PATH: 'tabelleVarie/:year?/:month?/:attualeCheck?/:allValidate?/',
        NAME: 'TabelleVarie',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_CODICE_DESCRIZIONE: {
        TITLE: 'Codice descrizione - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Codice descrizione VCODES',
        PATH: 'codiceDescrizione/:codTable?/',

        NAME: 'codiceDescrizione',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_CODICE_DESCRIZIONE_SINGOLA: {
        TITLE: 'Codice descrizione singola - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Codice descrizione SINGOLA',
        PATH: 'codiceDescrizioneSingole/:codTable?/',

        NAME: 'codiceDescrizioneSingola',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_COMUNI: {
        TITLE: 'Comuni - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Comuni',
        PATH: 'comuni',
        NAME: 'comuni',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_CNEL: {
        TITLE: 'Codici contratto CNEL - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Codici contratto CNEL',
        PATH: 'codiciContrattoCNEL',
        NAME: 'codiciContrattoCNEL',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_UNIEMENS: {
        TITLE: 'Uniemens - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Uniemens',
        PATH: 'uniemens',
        NAME: 'uniemens',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_COTRATTO_INPS: {
        TITLE: 'Codici contratto INPS - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Codici contratto INPS ',
        PATH: 'codContrINPS',
        NAME: 'codContrINPS',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_CODICI_ESAZIONI: {
        TITLE: 'Codici contratto INAIL - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Codici esazione',
        PATH: 'codiciEsazione',
        NAME: 'codiciEsazione',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_MAGGIORAZIONI_SPETTANTI: {
        TITLE: 'Maggiorazioni spettanti - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Maggiorazioni spettanti',
        PATH: 'maggiorazioniSpettanti',
        NAME: 'maggiorazioniSpettanti',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_PROVINCE: {
        TITLE: 'Province - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Province',
        PATH: 'province',
        NAME: 'province',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_QUALIFICHE_INPS: {
        TITLE: 'Qualifiche INPS - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Qualifiche INPS',
        PATH: 'qualifiInps',
        NAME: 'qualifiInps',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_UNIMARE: {
        TITLE: 'Unimare - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Unimare',
        PATH: 'Unimare',
        NAME: 'Unimare',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_VOCI_DI_TARIFFA: {
        TITLE: 'Voci di tariffa - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Voci di tariffa',
        PATH: 'vociDiTariffa',
        NAME: 'vociDiTariffa',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_CAAF: {
        TITLE: 'Caaf - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Caaf',
        PATH: 'caaf',
        NAME: 'caaf',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_CODICI_CONTRATTO_CO: {
        TITLE: 'Codici contratto CO - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Codici contratto CO',
        PATH: 'codiciContrattoCO',
        NAME: 'codiciContrattoCO',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_CODICI_TRIBUTO: {
        TITLE: 'Codici tributo - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Codici tributo',
        PATH: 'codiciTributo',
        NAME: 'codiciTributo',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_CODICI_OUTPUT: {
        TITLE: 'Codici output - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Codici output',
        PATH: 'codiciOutput',
        NAME: 'codiciOutput',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_TIPO_RETRIBUZIONE: {
        TITLE: 'Tipo retribuzione particolare - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Tipo retribuzione particolare',
        PATH: 'tipoRetribuzione',
        NAME: 'tipoRetribuzione',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_BANCHE: {
        TITLE: 'Banche - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Banche',
        PATH: 'banche',
        NAME: 'banche',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_IRPEF_DETRAZIONI_FIGLI: {
        TITLE: 'IRPEF detrazioni figli - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'IRPEF detrazioni figli',
        PATH: 'irpefDetrazioniFigli',
        NAME: 'irpefDetrazioniFigli',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_IRPEF_SCAGLIONI_IMPOSTA: {
        TITLE: "IRPEF: scaglioni d'imposta - Varie - Tabelle di domino - Gestione - Paghe",
        LABEL: "IRPEF: scaglioni d'imposta",
        PATH: 'irpefScaglioni',
        NAME: 'irpefScaglioni',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_IRPEF_DETRAZIONI: {
        TITLE: 'IRPEF: detrazione art.13 co.1-2  - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'IRPEF: detrazione art.13 co.1-2',
        PATH: 'irpefDetrazioni',
        NAME: 'irpefDetrazioni',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.TABELLE_VARIE.PATH,
            name: SETTINGS.TABELLE_VARIE.NAME,
            component: pagheViews.tabelle.varie.dettaglio,
            meta: {
                title: SETTINGS.TABELLE_VARIE.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
                defaultProps: {
                    year: () => useGlobalStore().state.periodoElab.year,
                    month: () => useGlobalStore().state.periodoElab.month,
                    attualeCheck: 1,
                    allValidate: 0,
                },
            },
            props: true,

            children: [
                {
                    path: SETTINGS.TABELLE_CODICE_DESCRIZIONE.PATH,
                    name: SETTINGS.TABELLE_CODICE_DESCRIZIONE.NAME,
                    component: pagheViews.tabelle.varie.childs.codiceDescrizione,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'VCODD',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },

                {
                    path: SETTINGS.TABELLE_CODICE_DESCRIZIONE_SINGOLA.PATH,
                    name: SETTINGS.TABELLE_CODICE_DESCRIZIONE_SINGOLA.NAME,
                    component: pagheViews.tabelle.varie.childs.codiceDescrizione,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'VCODD',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },

                {
                    path: SETTINGS.TABELLE_COMUNI.PATH,
                    name: SETTINGS.TABELLE_COMUNI.NAME,
                    component: pagheViews.tabelle.varie.childs.comuni,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TCOMU',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_CNEL.PATH,
                    name: SETTINGS.TABELLE_CNEL.NAME,
                    component: pagheViews.tabelle.varie.childs.CodiciContrattoCNEL,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TCN00',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_UNIEMENS.PATH,
                    name: SETTINGS.TABELLE_UNIEMENS.NAME,
                    component: pagheViews.tabelle.varie.childs.uniemens,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TU000', //TODO: DA CAMBIARE CHIEDERE A GIACOMO
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_COTRATTO_INPS.PATH,
                    name: SETTINGS.TABELLE_COTRATTO_INPS.NAME,
                    component: pagheViews.tabelle.varie.childs.contrattoInps,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TC400',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_CODICI_ESAZIONI.PATH,
                    name: SETTINGS.TABELLE_CODICI_ESAZIONI.NAME,
                    component: pagheViews.tabelle.varie.childs.codiciEsazione,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TD800',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_CODICI_OUTPUT.PATH,
                    name: SETTINGS.TABELLE_CODICI_OUTPUT.NAME,
                    component: pagheViews.tabelle.varie.childs.codiciOutput,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TH100',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_UNIMARE.PATH,
                    name: SETTINGS.TABELLE_UNIMARE.NAME,
                    component: pagheViews.tabelle.varie.childs.unimare,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TGM00', //TODO: DA CAMBIARE CHIEDERE A GIACOMO
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },

                {
                    path: SETTINGS.TABELLE_QUALIFICHE_INPS.PATH,
                    name: SETTINGS.TABELLE_QUALIFICHE_INPS.NAME,
                    component: pagheViews.tabelle.varie.childs.qualificheInps,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TC600',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },

                {
                    path: SETTINGS.TABELLE_VOCI_DI_TARIFFA.PATH,
                    name: SETTINGS.TABELLE_VOCI_DI_TARIFFA.NAME,
                    component: pagheViews.tabelle.varie.childs.vociDiTariffa,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TE700',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_MAGGIORAZIONI_SPETTANTI.PATH,
                    name: SETTINGS.TABELLE_MAGGIORAZIONI_SPETTANTI.NAME,
                    component: pagheViews.tabelle.varie.childs.maggiorazioniSpettanti,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'T7A00',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_CAAF.PATH,
                    name: SETTINGS.TABELLE_CAAF.NAME,
                    component: pagheViews.tabelle.varie.childs.Caaf,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TB900',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_CODICI_TRIBUTO.PATH,
                    name: SETTINGS.TABELLE_CODICI_TRIBUTO.NAME,
                    component: pagheViews.tabelle.varie.childs.codiciTributo,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TB500',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_CODICI_CONTRATTO_CO.PATH,
                    name: SETTINGS.TABELLE_CODICI_CONTRATTO_CO.NAME,
                    component: pagheViews.tabelle.varie.childs.CodiciContrattoCO,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TC900',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_BANCHE.PATH,
                    name: SETTINGS.TABELLE_BANCHE.NAME,
                    component: pagheViews.tabelle.varie.childs.banche,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TBANC',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_PROVINCE.PATH,
                    name: SETTINGS.TABELLE_PROVINCE.NAME,
                    component: pagheViews.tabelle.varie.childs.province,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TPROV',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_IRPEF_DETRAZIONI_FIGLI.PATH,
                    name: SETTINGS.TABELLE_IRPEF_DETRAZIONI_FIGLI.NAME,
                    component: pagheViews.tabelle.varie.childs.irpefDetrazioniFigli,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TID00',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_TIPO_RETRIBUZIONE.PATH,
                    name: SETTINGS.TABELLE_TIPO_RETRIBUZIONE.NAME,
                    component: pagheViews.tabelle.varie.childs.tipoRetribuzione,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TC700',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_IRPEF_SCAGLIONI_IMPOSTA.PATH,
                    name: SETTINGS.TABELLE_IRPEF_SCAGLIONI_IMPOSTA.NAME,
                    component: pagheViews.tabelle.varie.childs.irpefScaglioniImposta,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TI100',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_IRPEF_DETRAZIONI.PATH,
                    name: SETTINGS.TABELLE_IRPEF_DETRAZIONI.NAME,
                    component: pagheViews.tabelle.varie.childs.irpefDetrazione,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TIB00',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
            ],
        },
    ],
}
