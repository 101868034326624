import { defineStore } from 'pinia'
import { ref, reactive, Ref, shallowRef, computed, shallowReactive } from 'vue'

import { STORES_NAMES } from '@/constants'

import { ROUTES_OPERATORE } from '../routes/admin/operatori'
import { useGlobalStore } from '@/stores'

import moment from 'moment'
import dateUty from '@/scripts/services/date'

const useOperatoreStore = defineStore(
    STORES_NAMES.OPERATORE,
    () => {
        const globalStore = useGlobalStore()

        const activeChildName: Ref<string | null> = shallowRef(null) //Nome del dettaglio attivo
        const dataLookup = shallowReactive(new Map()) //Dati delle lookup (ref poiche con shallowRef non aggiorna le lookup)

        const state = reactive({
            routeList: ROUTES_OPERATORE.OPERATORE_LISTA, //TODO: DA SPOSTARE IN FUTURO ALL'ESTERNO
        })

        //TODO DA SEMPLIFICARE (TOGLIERE L'ANNIDAZIONE SE POSSIBILE)
        const dataChild = shallowReactive({
            name: null, //Nome del dettaglio a cui appartengono i dati in dataChild
            origin: null, //Dati da ripristinare al cambio TAB
        })

        //DATI GRIGLIA
        const _grid = ref({}) //Dati della griglia da ripristinare al cambio tab (utilizzato dalla composable baseTableEdit ma puo essere usato anche senza)
        const localTab = ref(0) //Indica il subTab attivo nella pagina

        //#region GETTERs
        const getPeriodo = computed(() => {
            return globalStore.state.periodoElab || dateUty.toObject(moment())
        })

        const dataChildFilled = computed(() => {
            return !!dataChild.origin && dataChild.name === activeChildName.value
        })
        //#endregion

        //#region ACTIONs - FUNZIONI
        function resetActiveChildName() {
            activeChildName.value = ROUTES_OPERATORE.OPERATORE.NAME
        }

        function resetBaseData(resetLookup: boolean = true, navigation: boolean = false) {
            dataChild.name = null
            dataChild.origin = null
            if (resetLookup) {
                dataLookup.clear()
            }
            if (!navigation) {
                //operazioni da fare solo se non si sta navigando
                localTab.value = 0
            }
        }

        function resetPageData(resetLookup: boolean = true, navigation: boolean = false) {
            resetBaseData(resetLookup, navigation)
            _grid.value = {}
        }
        //#endregion

        return {
            activeChildName,
            dataChildFilled,
            dataChild,
            dataLookup,
            getPeriodo,
            _grid,
            localTab,
            state,
            resetActiveChildName,
            resetBaseData,
            resetPageData,
        }
    },
    {
        persist: false,
    },
)

export default useOperatoreStore
