const Dipendente730 = () => import('./Dipendente730.vue')
const DipendenteAlterazioni = () => import('./DipendenteAlterazioni.vue')
const DipendenteAlterazioneContrattoStraordinario = () =>
    import('./DipendenteAlterazioneContrattoStraordinario.vue')
const DipendenteAlterazioneFerie = () => import('./DipendenteAlterazioneFerie.vue')
const DipendenteAlterazioneDivisoriOrari = () => import('./DipendenteAlterazioneDivisoriOrari.vue')
const DipendenteAlterazioneFestivita = () => import('./DipendenteAlterazioneFestivita.vue')
const DipendenteAlterazioneMalattia = () => import('./DipendenteAlterazioneMalattia.vue')
const DipendenteAnagrafica = () => import('./DipendenteAnagrafica.vue')
const DipendenteAnf = () => import('./DipendenteAnf.vue')
const DipendenteAreaProduttiva = () => import('./DipendenteAreaProduttiva.vue')
const DipendenteCollocamento = () => import('./DipendenteCollocamento.vue')
const DipendenteDetrazioni = () => import('./DipendenteDetrazioni.vue')
const DipendenteEnti = () => import('./DipendenteEnti.vue')
const DipendenteEredi = () => import('./DipendenteEredi.vue')
const DipendenteOpzioni = () => import('./DipendenteOpzioni.vue')
const DipendenteRapporto = () => import('./DipendenteRapporto.vue')
const DipendenteImpostazioniCedolino2 = () => import('./DipendenteImpostazioniCedolino2.vue')
const DipendenteImpostazioniCedolino1 = () => import('./DipendenteImpostazioniCedolino1.vue')
const DipendenteTFRFPC = () => import('./DIpendenteTFRFPC.vue')
const DipedenteRedditiErogati = () => import('./DipendenteRedditiErogati.vue')
const DipendenteRetribuzione = () => import('./DipendenteRetribuzione.vue')
const DipendenteScadenziario = () => import('./DipendenteScadenziario.vue')
const DipendenteStorico = () => import('./DipendenteStorico.vue')
const DipendentePrecRapporto = () => import('./DipendentePrecRapporto.vue')
const DipendenteDiario = () => import('./DipendenteDiario.vue')
const DipendenteCampiPersonalizzati = () => import('./DipendenteCampiPersonalizzati.vue')
const IstitutiContrattuali = () => import('./IstitutiContrattuali.vue')
const DipendenteAlterazioneMensilitaAggiuntive = () =>
    import('./DipendenteAlterazioneMensilitaAggiuntive.vue')
const DipendenteAlterazioneInfortunio = () => import('./DipendenteAlterazioneInfortunio.vue')
const DipendenteAlterazioneMaternita = () => import('./DipendenteAlterazioneMaternita.vue')
const DipendenteAlterazioneContributive = () => import('./DipendenteAlterazioneContributive.vue')
const DipendenteAlterazioneElementiPaga = () => import('./DipendenteAlterazioneElementiPaga.vue')

export {
    Dipendente730,
    DipendenteAnagrafica,
    DipendenteAreaProduttiva,
    DipendenteAlterazioni,
    DipendenteAlterazioneContributive,
    DipendenteAnf,
    DipendenteCollocamento,
    DipendenteDetrazioni,
    DipendenteEnti,
    DipendenteEredi,
    DipendenteRapporto,
    DipendenteRetribuzione,
    DipendenteOpzioni,
    DipedenteRedditiErogati,
    DipendenteScadenziario,
    DipendenteImpostazioniCedolino2,
    DipendenteStorico,
    DipendenteTFRFPC,
    DipendentePrecRapporto,
    DipendenteDiario,
    DipendenteCampiPersonalizzati,
    DipendenteAlterazioneContrattoStraordinario,
    DipendenteAlterazioneFestivita,
    DipendenteAlterazioneDivisoriOrari,
    IstitutiContrattuali,
    DipendenteAlterazioneElementiPaga,
    DipendenteAlterazioneFerie,
    DipendenteAlterazioneMensilitaAggiuntive,
    DipendenteAlterazioneInfortunio,
    DipendenteAlterazioneMalattia,
    DipendenteAlterazioneMaternita,
    DipendenteImpostazioniCedolino1,
}
