<template>
    <div
        class="hmodalFirtsLevelMenu d-inline-flex"
        role="tablist"
        @keydown="navigateButtons"
    >
        <v-btn
            v-for="(item, index) in getMenuItemsByPermission"
            :id="'btn' + index"
            :key="item.codVoceMenu"
            variant="plain"
            :class="{ active: index == activeItemId, focusOn: currentIndex === index }"
            :aria-selected="index == activeItemId ? 'true' : 'false'"
            :aria-label="item.descrizione"
            :tabindex="index == activeItemId ? '0' : '-1'"
            @click="showSelectedMenu(index), (currentIndex = index)"
        >
            {{ item.descrizione }}
        </v-btn>
    </div>
</template>

<script>
// IMPORT

//CODE
export default {
    name: 'FirstLevelItems',
    components: {},
    props: {
        menuModel: {
            default: () => [],
            type: Array,
        },
        activeItemId: {
            default: 0,
            type: Number,
        },
    },
    emits: ['show-selected-menu'],
    data() {
        return {
            open: true,
            currentIndex: 0,
        }
    },
    computed: {
        getMenuItemsByPermission() {
            let filteredMenu = []
            if (this.menuModel.length > 0) {
                //livello 1
                filteredMenu = this.menuModel.filter(
                    item => item.codAutoMenu == 'A' || item.codAutoMenu == 'D',
                )
            }
            return filteredMenu
        },
    },
    methods: {
        showSelectedMenu(index) {
            this.activeItem = index
            this.$emit('show-selected-menu', index)
        },
        navigateButtons(event) {
            if (event.key === 'ArrowRight') {
                if (this.currentIndex + 1 < this.getMenuItemsByPermission.length) {
                    this.currentIndex++
                }
            } else if (event.key === 'ArrowLeft') {
                if (this.currentIndex > 0) {
                    this.currentIndex--
                }
            } else if (event.key === 'Tab') {
                // set focus on submenu items
                document.getElementById('modal-inner-section').focus()
            }
            //this.buttons[this.currentIndex].focus()
            document.getElementById('btn' + this.currentIndex).focus()
        },
    },
}
</script>

<style lang="scss">
.hmodalFirtsLevelMenu li.focusOn {
    &:focus-within,
    &:focus-visible,
    &:focus {
        border: 1px solid #000;
        padding: 0.5rem;
    }
}
</style>
