enum STORES_NAMES {
    COLLABORATORE = 'collaboratoreStore',
    COLLABORATORE_LIST = 'collaboratoreListStore',
    COMUNI = 'comuniStore',
    DIPENDENTE = 'dipendenteStore',
    DIPENDENTE_LIST = 'dipendenteListStore',
    DITTE = 'ditteStore',
    DITTE_LIST = 'ditteListStore',
    EXPORT = 'exportStore',
    EXPORT_DEF = 'exportDefStore',
    GLOBAL = 'globalStore',
    INTERMEDIARIO = 'intermediarioStore',
    LOOKUP = 'lookup',
    OPERATORE = 'operatoreStore',
    OPERATORE_LIST = 'listaOperatoreStore',
    SEGRETERIA = 'segreteriaStore',
    SOCIO = 'socioStore',
    SOCIO_LIST = 'socioListStore',
    SOMMDIST = 'sommdistStore',
    SOMMDIST_LIST = 'sommdistListStore',
    STUDIO = 'studioStore',
    RISULTATI_DP = 'RisultatiDPStore',
    TAB = 'tabStore',
}

export default STORES_NAMES
