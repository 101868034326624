import { http } from '@/scripts/lib/http.js'
import { useGlobalStore } from '@/stores'

const BASE_URL = '/VQualificaDipte'

const payload: Payload = {
    requireTotalCount: false,
    isCountQuery: false,
}

export default class APIVQualificaDipte {
    static async get(params?: any) {
        const globalStore = useGlobalStore()
        return http.post(`${BASE_URL}/GetVQualificaDipte`, {
            ...payload,
            ...{
                periodoDiRiferimento: globalStore.state.periodoElab,
            },
            ...params,
        })
    }
}
