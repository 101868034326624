import { defineStore } from 'pinia'
import { useDynamicStore } from '@/composables/dynamic-store'
import { ref, reactive, computed, shallowReactive } from 'vue'
import { pagheRoutes } from '@/routes'

import { useGlobalStore } from '@/stores'

const storeName = 'intermediarioStore'

const useIntermediarioStore = defineStore(
    storeName,
    () => {
        const state = reactive({
            selectedKeys: true,
            routeList: pagheRoutes.anagrafiche.intermediari.SETTINGS.INTERMEDIARIO_LISTA,
            sortBy: [{ key: 'id', order: 'asc' }],
            dataChild: {
                childName: null,
                origin: null,
                form: null,
                localTab: 0,
            },
            props: null,
        })

        const globalStore = useGlobalStore()
        const dynamicStore = useDynamicStore() //utilizzato per il salvataggio di dati dinamici (es. dati di una griglia editabile particolare in una pagina)

        const localTab = ref(0)
        const dataLookup = shallowReactive(new Map())

        function resetPageData(resetLookup: boolean = true, navigation: boolean = false) {
            state.dataChild.childName = null
            state.dataChild.origin = null
            if (resetLookup) {
                dataLookup.clear()
            }
            localTab.value = 0
        }
        function resetActiveChildName() {}

        const dataChildFilled = computed(() => {
            return state.dataChild && state.dataChild.origin
        })
        const getPeriodo = computed(() => {
            return {
                periodoDiRiferimento: {
                    year: globalStore.state.periodoElab?.year,
                    month: globalStore.state.periodoElab?.month,
                },
            }
        })

        return {
            ...useDynamicStore(),
            dataChildFilled,
            dataLookup,
            dynamicStore,
            getPeriodo,
            globalStore,
            localTab,
            resetPageData,
            state,
            resetActiveChildName,
        }
    },
    {
        persist: false,
    },
)

export default { useIntermediarioStore, storeName }
