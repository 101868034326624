<template>
    <BaseDialog
        :loading="loading"
        icon="fal fa-ballot-check"
        min-height="70vh"
        min-width="95%"
        max-width="95%"
        show
        title="Schedulazione attività"
        type="info"
        @cancel="emit('resolve')"
    >
        <template #body>
            <BaseTable
                class="mx-2"
                :dt-props="dtProps"
                reload-button="Ricarica l'elenco"
                selection-key="codice"
                @data-reload="dataLoad"
            >
                <template #stato="{ attr, item }">
                    <div>
                        <v-icon
                            v-bind="attr"
                            :color="item.statoMeta.color"
                            :icon="`far fa-${item.statoMeta.icon}`"
                        />
                        <v-tooltip
                            activator="parent"
                            location="top"
                            :text="item.statoMeta.tooltip"
                        />
                        <v-badge
                            v-show="item.statoMeta.badge"
                            class="stato-meta-badge"
                            color="white"
                            :icon="`fas fa-${item.statoMeta.badge}`"
                            offset-x="-23"
                            offset-y="-13"
                            text-color="red"
                        >
                        </v-badge>
                    </div>
                </template>
                <template #output="{ attr, item }">
                    <div v-if="item.stato === 4">
                        <v-icon
                            v-bind="attr"
                            color="tertiary"
                            :icon="`far fa-file-${item.output}`"
                            @click="downloadFile(item)"
                        />
                        <v-tooltip
                            activator="parent"
                            location="top"
                            text="Scarica il file"
                        />
                    </div>
                </template>
                <template #actions="{ item }">
                    <v-btn
                        flat
                        size="x-small"
                        @click=""
                    >
                        <v-icon icon="far fa-ellipsis-vertical" />
                        <v-menu
                            activator="parent"
                            location="top"
                        >
                            <v-list density="compact">
                                <template
                                    v-for="(action, index) in actions"
                                    :key="index"
                                    :value="index"
                                >
                                    <v-list-item
                                        :disabled="!checkAction(action.action, item)"
                                        :title="action.title"
                                        size="x-small"
                                        @click="taskAction(action.action, item)"
                                    >
                                        <template #append>
                                            <v-icon
                                                color="tertiary"
                                                :icon="`far fa-${action.icon}`"
                                                size="small"
                                            />
                                        </template>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-menu>
                    </v-btn>
                </template>
            </BaseTable>
        </template>
        <template #bodyFooterRight>
            <div class="d-flex justify-end">
                <v-btn
                    color="tertiary"
                    flat
                    @click="emit('resolve')"
                >
                    ESCI
                </v-btn>
            </div>
        </template>
    </BaseDialog>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref, watch } from 'vue'
import moment from 'moment'

import { APISchedulazione } from '@/api'
import { MessageBox } from '@/scripts/services/utility.js'
import { useGlobalStore } from '@/stores'
import { useUtils } from '@/composables'
import BaseDialog from './base/BaseDialog.vue'
import BaseTable from '@/components/base/BaseTable.vue'

type Item = { [key: string]: any }

const dtProps = reactive({
    headers: [
        {
            align: 'center',
            key: 'dataSchedulazione',
            title: 'Data/ora schedulazione',
            type: 'datetime',
            width: 200,
        },
        {
            align: 'center',
            key: 'tipologia',
            title: 'Tipologia',
        },
        {
            key: 'descrizione',
            title: 'Descrizione',
        },
        {
            align: 'center',
            key: 'stato',
            slot: true,
            title: 'Stato',
        },
        {
            align: 'center',
            key: 'output',
            sortable: false,
            slot: true,
            title: 'Output',
        },
        {
            key: 'errore',
            sortable: false,
            title: 'Log',
        },
        {
            align: 'center',
            text: 'Azioni',
            key: 'actions',
            slot: true,
            sortable: false,
        },
    ],
    items: [],
    showSelect: false,
})
const actions = [
    { action: 'delete', icon: 'trash-can', title: 'Elimina' },
    { action: 'stop', icon: 'circle-stop', title: 'Metti in pausa' },
    { action: 'resume', icon: 'circle-play', title: 'Riprendi' },
]
const emit = defineEmits(['reject', 'resolve'])
const globalStore = useGlobalStore()
const utils = useUtils()
const loading = ref(true)
const taskCompletatiTot = ref(globalStore.state.taskCompletatiTot)

onMounted(async () => await dataLoad())

watch(
    () => [globalStore.state.taskCompletatiTot],
    async () => await dataLoad(),
)

function checkAction(action: string, item: Item) {
    if (!item.guid) return false
    switch (action) {
        case 'delete':
            return [1, 2, 3, 6, 7, 8].includes(item.stato)
        case 'resume':
            return [3, 8].includes(item.stato)
        case 'stop':
            return [1, 2, 6, 7].includes(item.stato)
    }
    return false
}

async function dataLoad() {
    loading.value = true
    const response = await APISchedulazione.get({
        takeLastJobsFinished: taskCompletatiTot.value || 10,
    })
    loading.value = false
    if (response?.statusText === 'OK') {
        const items = response.data.payload.schedulerJobList

        items.forEach((item: Item) => {
            item.output = 'download'
            switch (item.stato) {
                case 1:
                    item.statoMeta = {
                        color: 'tertiary',
                        icon: 'hourglass-clock',
                        tooltip: 'In attesa di esecuzione',
                    }
                    break
                case 2:
                    item.statoMeta = {
                        color: 'tertiary',
                        icon: 'clock',
                        tooltip: 'In esecuzione',
                    }
                    break
                case 3:
                    item.statoMeta = {
                        color: 'tertiary',
                        icon: 'circle-pause',
                        tooltip: 'In pausa',
                    }
                    break
                case 4:
                    item.statoMeta = {
                        color: 'success',
                        icon: 'circle-check',
                        tooltip: 'Terminata con successo',
                    }
                    break
                case 5:
                    item.statoMeta = {
                        color: 'red',
                        icon: 'circle-xmark',
                        tooltip: 'Terminata con errore',
                    }
                    break
                case 6:
                    item.statoMeta = {
                        badge: 'circle-xmark',
                        color: 'red',
                        icon: 'circle-play',
                        tooltip: 'Terminata con errore in riesecuzione',
                    }
                    break
                case 7:
                    item.statoMeta = {
                        badge: 'circle-xmark',
                        color: 'red',
                        icon: 'hourglass-clock',
                        tooltip: 'Terminata con errore in attesa di riesecuzione',
                    }
                    break
                case 8:
                    item.statoMeta = {
                        badge: 'circle-xmark',
                        color: 'red',
                        icon: 'circle-pause',
                        tooltip: 'Terminata con errore in stato di pausa',
                    }
                    break
                case 9:
                    item.statoMeta = {
                        color: 'warning',
                        icon: 'trash-can',
                        tooltip: 'Eliminata',
                    }
                    break
            }
        })
        dtProps.items = items
    } else {
        MessageBox.ErrorMessage({ message: 'Operazione non riuscita' })
    }
}

async function downloadFile(item: Item) {
    loading.value = true
    /*  const response = await APISchedulazione.action('GetSchedulazione', {
        filter: JSON.stringify([['id', '=', item.id]]),
        select: JSON.stringify(['outputResult']),
    }) */

    const response = await APISchedulazione.getSchedulerOutputFile({
        idSchedulazione: item.id,
    })
    loading.value = false
    if (!response.data.responseStatus?.isSuccessful) {
        MessageBox.ErrorMessage({ message: 'Operazione non riuscita' })
        return
    }
    /*  console.log(item) */
    const outputResult = response.data.payload.outputFile
    /* console.log(outputResult) */
    /*  const result = JSON.parse(outputResult) */
    /* console.log(result) */
    const extension = outputResult.extension
    const base64 = outputResult.fileOutput
    const filename = `${item.descrizione.replace(/\s/g, '_')}_${moment(item.dataSchedulazione).format('YYYYMMDD_hhmmss')}.${extension}`
    utils.downloadJsonFile(base64, extension, filename)
}

async function taskAction(action: string, item: Item) {
    loading.value = true
    let apiUrl = null
    switch (action) {
        case 'delete':
            apiUrl = 'DeleteExecutionJob'
            break
        case 'resume':
            apiUrl = 'ResumeExecutionJob'
            break
        case 'stop':
            apiUrl = 'StopExecutionJob'
            break
    }
    const response = await APISchedulazione.action(apiUrl, { jobGuid: item.guid })
    loading.value = false
    await dataLoad()
    // if (response.data.responseStatus?.isSuccessful) {
    // } else {
    //     MessageBox.ErrorMessage({ message: 'Operazione non riuscita' })
    // }
}
</script>

<style lang="scss"></style>
