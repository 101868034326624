import { get } from 'lodash'
import { AxiosResponse } from 'axios'

import { INDEXED_DB } from '@/constants/index'
import { getDataDb } from '@/composables/indexDB'
import { call1 } from '@/scripts/lib/http'
import { call } from '@/scripts/lib/http'
const BASE_URL = '/VCodDes'
const defaultPayload: Payload = {}

class API {
    @call1({ url: `${BASE_URL}/GetVCodDes`, defaultPayload })
    static async get<T extends Payload>(
        payload: T,
        noHttp?: boolean,
    ): Promise<Array<VcodDes> | AxiosResponse<BasicResponseData<VcodDes>>> {
        return {
            noHttp: async (payload: T) => {
                // /**
                //     Filtra tutto VCodDes per ritornare solo le funzioni richieste in filter
                //     - filter: ['funzione', '=', 'AAAAA'],
                //     - filter: [
                //         ['funzione', '=', 'AAAAA'],
                //         'OR',
                //         ['funzione', '=', 'BBBBB'],
                //         'OR',
                //         ['funzione', '=', 'CCCCC'],
                //         ]
                // */
                let funzioni = null
                if (payload?.filter) {
                    let filters = JSON.parse(payload?.filter)
                    if (Array.isArray(filters) && !Array.isArray(filters[0])) filters = [filters]
                    funzioni = filters
                        .filter((_: any, idx: number) => (idx + 1) % 2 === 1) // elementi dispari di filter
                        .map((item: string) => item[2]) // nome della funzione in filter
                }
                const res: any = await getDataDb(
                    INDEXED_DB.STORES.VCODDES,
                    payload as object,
                    funzioni,
                )
                if (!res?.responseStatus?.isSuccessful) return []
                return get(res, INDEXED_DB.STORES.VCODDES.DATA_ROOT, [])
            },
        } as any
    }

    static async getVCodDesData<T>(params: any, payload?: T): Promise<Array<VcodDes>> {
        let filter: (string | string[])[] = []
        if (params.filter) {
            params.filter.forEach((func: string, idx: number) => {
                if (idx > 0) filter.push('or')
                filter.push(['funzione', '=', func])
            })
        }
        if (!params.inclusions) params.inclusions = ['vcodDesElementoList']
        return await API.get<Payload>({
            ...payload,
            filter: filter.length ? JSON.stringify(filter) : null,
            include: JSON.stringify(params.inclusions),
        })
    }

    @call({ url: `${BASE_URL}/GetVCodDes` })
    static getVCodDes<T>(payload: T): any {}

    @call({ url: `${BASE_URL}/SaveVCodDesElemento` })
    static async saveGenerale(
        payload: SaveVCodDesElementoPayload,
    ): Promise<AxiosResponse<BasicResponsePayload> | null> {
        return null
    } // TODO VEDERE COSA RISPONDE
}

export default {
    get: API.get<Payload>,
    getVCodDes: API.getVCodDes<Payload>,
    getVCodDesData: API.getVCodDesData<Payload>,
    saveDato: API.saveGenerale,
}
