import { computed, ComputedRef, ref, Ref, shallowRef, ShallowRef } from 'vue'
import { defineStore } from 'pinia'
import { useGlobalStore } from '@/stores'
import { GLOBALS } from '@/constants'
import dateUty, { DateRec } from '@/scripts/services/date'

export const storeName: string = 'statusElencoStore'

interface StatusListStore {
    actualIndex: Ref<number>
    dataList: Ref<DittaResponse[] | undefined>
    filtroDitteGestite: Ref<boolean>
    filtroDitteTipo: Ref<Array<number>>
    lastIdx: Ref<number>
    filtro: ShallowRef<string>
    selectedKeys: Ref<number[] | boolean>
    sortBy: Ref<Array<{ key: string; order: 'asc' | 'desc' }>>
    getPeriodoDitta: (id: number, isIndex?: boolean) => DateRec | null | undefined
    getPropsNavigation: (idx: number) =>
        | {
              id: number
              mod: string
              year?: string | number | undefined
              month?: string | number | undefined
              day?: string | number | undefined
          }
        | null
        | undefined
    navLength: ComputedRef<number>
    samePeriod: () => boolean
}
const useStatusElencoStore = defineStore(storeName, (): StatusListStore => {
    const globalStore = useGlobalStore()
    const actualIndex = ref(0)
    const filtro = shallowRef('')
    const selectedKeys: Ref<number[] | boolean> = ref(true)
    const lastIdx: Ref<number> = ref(-1) //index dell'ultimo elemento visualizzato ---> utilizzato per determinare il periodo e di conseguenza se riscaricare i dati delle lookup
    const filtroDitteGestite = ref(true)
    const filtroDitteTipo = ref([1, 2])
    const dataList: ShallowRef<DittaResponse[] | undefined> = shallowRef()

    const sortBy: Ref<Array<{ key: string; order: 'asc' | 'desc' }>> = ref([
        { key: 'codiceDitta', order: 'asc' },
        { key: 'unitaProduttiva', order: 'asc' },
    ])

    /**
     * Restituisce i parametri di navigazione relativi a una data Ditta.
     * @param idx id della Ditta dalla quale ottenere il periodo
     * @param isIndex indica se l'id passato e' l'indice della lista di elementi (selectedKeys) selezionati nell'elenco
     * @returns
     */
    function getPropsNavigation(idx: number) {
        try {
            const id = typeof selectedKeys.value != 'boolean' ? selectedKeys.value[idx] : 0 //elemento chiave id+dataInizioDoppioCedolino

            const periodoDiRiferimento = getPeriodoDitta(id)

            return { ...periodoDiRiferimento, id: id, mod: GLOBALS.DATA_MODE.MOD }
        } catch (error) {
            null
        }
    }
    //#region Getters
    const navLength = computed(() =>
        typeof selectedKeys.value != 'boolean' ? selectedKeys.value?.length : 0,
    )
    //#endregion

    /**
     * Funzione che permette di ottenere il periodi di elaborazione di una ditta in base all'id.
     * La ditta e' una di quelle scaricate dall'elenco ditte.
     * @param id Id della ditta di cui si vuole conoscere il periodo di elaborazione
     * @param isIndex
     * @returns periodo di elaborazione della ditta
     */
    function getPeriodoDitta(id: number, isIndex: boolean = false) {
        const element = dataList.value?.find(item => item?.id == id)

        const periodoElab = element?.dataPeriodoElaborazione

        if (periodoElab) {
            return dateUty.toObject(periodoElab) //MomentJS.GetPeriodoRiferimentoFromIso(periodoElab) //periodo specifico
        } else {
            //potrebbe trattarsi di una figlia, in tal caso vado a prendere il periodo di elaborazione del padre
            if (element?.idPadre) {
                return getPeriodoDitta(element.idPadre)
            }
        }
        return globalStore.state.periodoElab
    }

    function samePeriod(): boolean {
        if (lastIdx.value === -1) return false
        let previousPeriod = getPeriodoDitta(lastIdx.value, true)
        let actualPeriod = getPeriodoDitta(actualIndex.value, true)
        if (
            previousPeriod?.month === actualPeriod?.month &&
            previousPeriod?.year === actualPeriod?.year
        )
            return true

        return false
    }

    return {
        actualIndex,
        dataList,
        filtroDitteGestite,
        filtroDitteTipo,
        filtro,
        getPeriodoDitta,
        getPropsNavigation,
        lastIdx,
        navLength,
        samePeriod,
        selectedKeys,
        sortBy,
    }
})

export default useStatusElencoStore
