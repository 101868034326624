<template>
    <div
        id="menuQuickSearchWrapper"
        ref="menuQuickSearchWrapper"
        class="d-inline-flex position-relative"
    >
        <v-btn
            id="searchIcon"
            ref="searchIcon"
            variant="plain"
            size="small"
            icon
            color="black"
            :disabled="isActive"
            @click="showHideSearch"
        >
            <v-icon
                class="topmenu-search-icon"
                icon="fa:fas fa-magnifying-glass"
            ></v-icon>
        </v-btn>

        <focus-trap
            ref="focusTrapDialog"
            :active="isActive"
            :initial-focus="() => $refs.searchinput"
            :allow-outside-click="isAllowOutsideClick"
            :escape-deactivates="isAllowOutsideClick"
            @keydown.escape.stop.prevent="keyEvent($event)"
        >
            <div
                v-show="showSearchBar"
                id="menuQuickSearchBox"
                role="dialog"
                tabindex="-1"
                aria-modal="true"
                aria-labelledby="modalTitle"
            >
                <h1
                    id="modalTitle"
                    class="sr-only"
                >
                    Modale di ricerca nell'applicazione
                </h1>
                <div class="position-relative">
                    <input
                        ref="searchinput"
                        v-model="searchQuery"
                        type="text"
                        placeholder="cerca funzioni nell'applicazione"
                        class="searchBox w-100"
                        aria-label="cerca funzioni"
                    />
                    <Transition>
                        <v-btn
                            v-if="searchQuery"
                            variant="plain"
                            size="small"
                            icon
                            color="black"
                            class="resetSearchString"
                            aria-label="reset della ricerca"
                            title="reset della ricerca"
                            @click="resetSearchString"
                        >
                            <v-icon icon="fa:fas fa-trash-can"></v-icon>
                        </v-btn>
                    </Transition>
                </div>

                <!--// spazio per i risultati-->
                <nav role="navigation">
                    <h3 class="sr-only">Elenco risultati ricerca</h3>
                    <div
                        role="status"
                        class="sr-only"
                    >
                        {{ flatFilteredList.length }} risultati disponibili
                    </div>
                    <ul
                        v-if="flatFilteredList.length > 0"
                        class="searchResults nav"
                    >
                        <li
                            v-for="(item, idx) in flatFilteredList"
                            :key="idx"
                            class="list-item w-100"
                        >
                            <a
                                v-if="!findInActiveTabs(item.itemName)"
                                href="#"
                                class="quickSearchLink"
                                @click.prevent
                                @click="goToPage(item.itemName, item.itemKey)"
                            >
                                <b class="itemPath">{{ item.itemPath }} - </b>
                                <span class="itemName">{{ item.itemName }}</span>
                            </a>
                            <div v-else>
                                <b class="itemPath">{{ item.itemPath }} - </b>
                                <span class="itemName">{{ item.itemName }}</span>
                            </div>
                        </li>
                    </ul>
                </nav>
                <!-- search message status-->
                <div
                    class="d-flex align-items-center justify-content-between border-top border-dark"
                >
                    <div
                        id="search-status-message"
                        role="status"
                    >
                        <b>{{ flatFilteredList.length }}</b> risultati disponibili
                    </div>

                    <v-btn
                        class="mt-2"
                        variant="outlined"
                        @click="searchCloseFocus"
                    >
                        Chiudi
                    </v-btn>
                </div>
            </div>
        </focus-trap>
    </div>
</template>

<script>
import { useTabStore } from '@/stores'
import { useStorePermissions } from '@/scripts/stores/Permissions/pagePermission.js'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'

export default {
    name: 'FeaturesQuickSearch',
    props: {},
    emits: ['close-hmenu-modal'],
    setup() {
        const tabStore = useTabStore()
        const { menu } = storeToRefs(useStorePermissions())
        let searchIcon = ref(null)
        let focusTrapDialog = ref(null)
        let myTemp = ref()

        return {
            tabStore,
            menu,
            //generale,
            searchIcon,
            focusTrapDialog,
            myTemp,
        }
    },
    data() {
        return {
            showSearchBar: false,
            searchQuery: '',
            flatItems: [],
            arrActiveTabs: [],
            isActive: false,
            isAllowOutsideClick: false,
        }
    },
    computed: {
        //--- create a flat array with all menu items
        flatListValues() {
            let itemPath = ''
            let itemName = ''
            let itemKey = ''

            this.menu.forEach(model => {
                // name: Gestione
                if (model.codAutoMenu == 'A' || model.codAutoMenu == 'D') {
                    model.vociFiglie.forEach(child1 => {
                        // name: Corrente
                        if (
                            child1.vociFiglie &&
                            (child1.codAutoMenu == 'A' || child1.codAutoMenu == 'D')
                        ) {
                            child1.vociFiglie.forEach(child2 => {
                                // name: Generale
                                if (
                                    child2.vociFiglie &&
                                    (child2.codAutoMenu == 'A' || child2.codAutoMenu == 'D')
                                ) {
                                    child2.vociFiglie.forEach(child3 => {
                                        if (
                                            child3.codAutoMenu == 'A' ||
                                            child3.codAutoMenu == 'D'
                                        ) {
                                            // name: Anagrafici
                                            itemPath =
                                                model.descrizione +
                                                ' - ' +
                                                child1.descrizione +
                                                ' - ' +
                                                child2.descrizione
                                            itemName = child3.descrizione
                                            itemKey = child3.codVoceMenu
                                            // --- push to array used for filter
                                            this.flatItems.push({
                                                itemPath: itemPath,
                                                itemName: itemName,
                                                itemKey: itemKey,
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
            // ---
            return Object.values(this.flatItems)
        },

        // --- search in flat list
        flatFilteredList() {
            if (!this.searchQuery) {
                return this.flatListValues
            }

            return this.flatListValues
                .map(v => {
                    if (this.matches(v)) {
                        return v
                    }
                })
                .filter(v => v)
        },
    },
    created() {
        // abilito la visualizzazione del primo menu e delle
        //this.showSelectedMenu(this.activeItem);
        // verifico i tab attivi e disattivo le voci di menu corrispondenti. L'utente dovrà usare i tab corrispondenti per poter accedere alla voci di menu
        if (this.tabStore && this.tabStore.state.tabs.length > 0) {
            this.arrActiveTabs = [...this.tabStore.state.tabs]
        }
    },
    methods: {
        showHideSearch() {
            this.showSearchBar = !this.showSearchBar
            // set focus on search input
            this.$nextTick(() => this.$refs.searchinput.focus())

            // manage focus trap
            this.$nextTick(() => this.manageFocusTrap(this.showSearchBar))
        },
        manageFocusTrap(boolStatus) {
            if (boolStatus) {
                // activate focus trap
                this.focusTrapDialog.activate()
                this.isAllowOutsideClick = false
                // --- show
                this.isActive = true
            } else {
                //deactivate focus trap
                this.focusTrapDialog.deactivate()
                this.isAllowOutsideClick = true
                // --- hide
                this.isActive = false
            }
        },

        searchCloseFocus() {
            this.showSearchBar = false
            // reset focus trap

            //deactivate focus trap
            this.focusTrapDialog.deactivate()
            this.isAllowOutsideClick = true
            this.isActive = false

            this.$nextTick(() => {
                // this won't work because `this.$refs.typeBox` returns an array
                // this.$refs.typeBox.focus()

                //set focus on search icon (magnifyer)
                this.$refs.searchIcon.$el.focus()
            })
        },
        // --- matches elements in array
        matches(obj) {
            const term = this.searchQuery.toLowerCase()

            return (
                obj.itemName.toLowerCase().includes(term) ||
                obj.itemPath.toLowerCase().includes(term)
            )
        },
        resetSearchString() {
            this.searchQuery = ''
            // set focus on search input
            this.$nextTick(() => this.$refs.searchinput.focus())
        },
        goToPage(label, codVoceMenu) {
            const route = this.tabStore.getRoute(codVoceMenu) //this.tabStore.openTab(level4.codVoceMenu)
            if (route) {
                this.$router.replace({ name: route.name })
                this.$emit('close-hmenu-modal', true)
            }
        },
        openTab(routeName, label, codVoceMenu) {
            if (routeName && routeName != '' && routeName != null) {
                this.tabStore.addMenuVoice({ label, routeName, codVoceMenu })
                let index = this.tabStore.getLastIndex
                this.tabStore.updateActiveTab(index)
                this.$router.push({ name: routeName }).catch(() => {})
                this.$emit('close-hmenu-modal', true)
            }
        },
        findInActiveTabs(label) {
            let self = this
            let result = false
            let searchIndex = -1

            if (self.arrActiveTabs && self.arrActiveTabs.length > 0) {
                searchIndex = self.arrActiveTabs.findIndex(
                    o => o.label?.toLowerCase() == label?.toLowerCase(),
                )

                if (searchIndex > -1) {
                    result = true
                }
            }

            return result
        },
        keyEvent(event) {
            event.preventDefault()
            //WCAG: impedire la chiusura del menu dialog senza prima chiudere la finestra di ricerca avanzata
            if (event && event.code == 'Escape' && this.showSearchBar) {
                //event.preventDefault()

                //chiudo la finestra di ricerca avanzata
                this.searchCloseFocus()
            }
        },
    },
}
</script>
