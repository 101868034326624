import type { ShallowReactive } from 'vue'
import { ref, shallowReactive } from 'vue'
import { defineStore } from 'pinia'

import { useDynamicStore } from '@/composables/dynamic-store'
import { useBaseTable } from '@/composables'

const STORE_NAME = 'movimentiMese'

const useMovimenti = defineStore(
    STORE_NAME,
    () => {
        const baseTable: ShallowReactive<any> = shallowReactive({
            ...useBaseTable().baseTableOptions(),
            sortBy: [
                { key: 'codiceDitta', order: 'asc' },
                { key: 'unitaProduttiva', order: 'asc' },
            ],
            filtroDitteElaborate: true,
            filtroDitteNoDipti: false,
            filtroDitteTipo: [1, 2],
            filtroDitteUP: false,
        })
        const periodoGestione = ref()
        const selectedKeys = ref(true)
        return {
            ...useDynamicStore(),
            baseTable,
            periodoGestione,
            selectedKeys,
        }
    },
    {
        persist: false,
    },
)

export default useMovimenti
