import pagheViews from '@paghe/views'

const tabPropsDefault: TabProps = {
    CODVOCEMENU: '000006',
    GROUP: 'anagraficheStudio',
    ICON: 'fa-regular fa-briefcase',
    TITLE: 'Studio',
}

export const SETTINGS = Object.freeze({
    DETTAGLIO: {
        TITLE: 'Dettaglio Studio - Studio - Anagrafici - Gestione - Paghe',
        LABEL: 'Dettaglio studio',
        PATH: 'studio',
        NAME: 'StudioDettaglio',
    },
    GENERALE: {
        TITLE: null,
        LABEL: 'Generale',
        PATH: 'generale',
        NAME: 'StudioGenerale',
        TAB: { ...tabPropsDefault },
    },
    DESCRIZIONE_VOCI: {
        TITLE: null,
        LABEL: 'Descrizione voci',
        PATH: 'descrizioneVoci',
        NAME: 'StudioDescrizioneVoci',
        TAB: { ...tabPropsDefault },
    },
    CAMPI_PERSONALIZZATI: {
        TITLE: null,
        LABEL: 'Definizione campi personalizzati',
        PATH: 'campipersonalizzati',
        NAME: 'CampiPersonalizzati',
        TAB: { ...tabPropsDefault },
    },
    SCADENZIARIO: {
        TITLE: null,
        LABEL: 'Scadenziario',
        PATH: 'scadenziario',
        NAME: 'scadenziario',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            component: pagheViews.anagrafiche.studio.Dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: 'STUDIO',
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                iconFont: 'fa-regular fa-briefcase',
                altLabel: '',
                tabGroup: tabPropsDefault.GROUP,
            },
            children: [
                {
                    path: SETTINGS.GENERALE.PATH,
                    name: SETTINGS.GENERALE.NAME,
                    component: pagheViews.anagrafiche.studio.childs.Generale,
                    meta: {
                        group: 'StudioDettaglio',
                        permissions: {
                            codPermesso: 'STUDIO',
                            codVoceMenu: null,
                        },
                        iconFont: 'fa-regular fa-briefcase',
                        altLabel: '',
                        tabGroup: tabPropsDefault.GROUP,
                    },
                },
                {
                    path: SETTINGS.DESCRIZIONE_VOCI.PATH,
                    name: SETTINGS.DESCRIZIONE_VOCI.NAME,
                    component: pagheViews.anagrafiche.studio.childs.DescrizioneVoci,
                    meta: {
                        group: 'StudioDettaglio',
                        permissions: {
                            codPermesso: 'STUDIO',
                            codVoceMenu: null,
                        },
                        iconFont: 'fa-regular fa-briefcase',
                        altLabel: '',
                        tabGroup: tabPropsDefault.GROUP,
                    },
                },
                {
                    path: SETTINGS.CAMPI_PERSONALIZZATI.PATH,
                    name: SETTINGS.CAMPI_PERSONALIZZATI.NAME,
                    component: pagheViews.anagrafiche.studio.childs.CampiPersonalizzati,
                    meta: {
                        group: 'StudioDettaglio',
                        permissions: {
                            codPermesso: 'STUDIO',
                            codVoceMenu: null,
                        },
                        iconFont: 'fa-regular fa-briefcase',
                        altLabel: '',
                        tabGroup: tabPropsDefault.GROUP,
                    },
                },
                {
                    path: SETTINGS.SCADENZIARIO.PATH,
                    name: SETTINGS.SCADENZIARIO.NAME,
                    component: pagheViews.anagrafiche.studio.childs.Scadenziario,
                    meta: {
                        group: 'StudioDettaglio',
                        permissions: {
                            codPermesso: 'STUDIO',
                            codVoceMenu: null,
                        },
                        iconFont: 'fa-regular fa-briefcase',
                        altLabel: '',
                        tabGroup: tabPropsDefault.GROUP,
                    },
                },
            ],
        },
    ],
}
