import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'
import { BASE_URL_DIPTE } from './dipte'
const BASE_URL = '/DipteEnte'

class API {
    @call({ url: `${BASE_URL}/GetDipendentiEnte` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponsePayload<DipteEnte>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DIPTE}/SaveDipteEnte` })
    static async saveDipteEnte(
        payload: SaveDipteEntePayload,
    ): Promise<AxiosResponse<BasicResponseData<DipteEnte>> | null> {
        return null
    }
}

export default {
    get: API.get,
    saveDipteEnte: API.saveDipteEnte,
}
