import { computed, shallowReactive, ref, reactive } from 'vue'
import { defineStore } from 'pinia'

import { GLOBALS } from '@/constants'
import { useDynamicStore } from '@/composables/dynamic-store'
import { useBaseTable } from '@/composables'

const baseTable = useBaseTable()
export const NAME = 'profiliMenu'

export default defineStore(NAME, () => {
    const activeChildName = ref()
    const actualIndex = ref(0)
    const dataLookup = shallowReactive(new Map())
    const commonData = reactive({
        codice: null,
        descrizione: null,
    })
    const dynamicStore = useDynamicStore()
    const elenco = reactive({
        ...baseTable.baseTableOptions(),
    })
    const localTab = ref(0)
    const newId = ref(0)
    const state = reactive({
        changeMap: null,
        childName: null,
        data: null,
        props: null,
    })

    const dataChildFilled = computed(() => {
        return state.data && state.childName === activeChildName.value
    })

    const navLength = computed(() =>
        Array.isArray(elenco.selectedKeys) ? elenco.selectedKeys.length : 0,
    )

    async function getData(props: any) {
        // Eventuali dati da API per tutte le pagine
    }

    async function initStateData() {}

    function getPropsNavigation(idx: number) {
        try {
            return { id: (elenco.selectedKeys as any)[idx], mod: GLOBALS.DATA_MODE.MOD }
        } catch (error) {
            null
        }
    }

    function resetActiveChildName() {}

    function resetPageData(resetLookup: boolean = true, navigation: boolean) {
        commonData.codice = null
        commonData.descrizione = null
        state.childName = null
        state.data = null
        state.changeMap = null
        if (resetLookup) dataLookup.clear()
        if (!navigation) localTab.value = 0
    }

    return {
        NAME,
        ...dynamicStore,
        activeChildName,
        actualIndex,
        commonData,
        dataChildFilled,
        dataLookup,
        elenco,
        getData,
        getPropsNavigation,
        initStateData,
        localTab,
        navLength,
        newId,
        resetActiveChildName,
        resetPageData,
        state,
    }
})
