<template>
    <div
        v-if="validita"
        class="d-flex align-center"
        role="note"
        tabindex="0"
    >
        <v-icon
            aria-label="Periodo validità"
            :color="isFutura ? 'red' : ''"
            icon="fas fa-calendar-day"
            size="small"
            style="height: 21px"
        />
        <span class="ml-1">{{ validitaDalStr }}&nbsp;&nbsp;-&nbsp;&nbsp;{{ validitaAlStr }}</span>
        <v-tooltip
            activator="parent"
            location="top"
            role="tooltip"
            :text="`Periodo validità${isFutura ? ' - sono presenti validità future' : ''}`"
        />
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import utyDate from '@/scripts/services/date.js'

type Data =
    | string
    | Date
    | { year: string | number; month: string | number; day?: string | number | undefined }

export interface ValiditaProps {
    isFutura?: boolean
    validita: { fine: Data; inizio: Data; month?: boolean }
}

const props = withDefaults(defineProps<ValiditaProps>(), { isFutura: false })

const validitaFormat = computed(() => (props.validita?.month ? 'monthYear' : undefined))

const validitaDalStr = computed(() =>
    props.validita ? utyDate.format(props.validita.inizio, validitaFormat.value) : '',
)
const validitaAlStr = computed(() =>
    props.validita ? utyDate.format(props.validita.fine, validitaFormat.value) : '',
)
</script>

<style lang="scss">
@import '@/styles/global/vars';
</style>
