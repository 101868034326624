import { GLOBALS } from '@/constants'
import { defineStore } from 'pinia'
import { ShallowReactive, shallowReactive, computed, ref } from 'vue'

import moment from 'moment'
import { useGlobalStore } from '@/stores'
import dateUty from '@/scripts/services/date'

export const storeName = 'listaOperatoreStore'

const useOperatoreListStore = defineStore(
    storeName,
    () => {
        const globalStore = useGlobalStore()

        const elencoState: ShallowReactive<IElencoOperatoreState> = shallowReactive({
            filtro: '',
            selectedKeys: true, //array con gli elementi selezionati
            sortBy: [{ key: 'codice', order: 'asc' }],
            dataList: [],
            //lastIdx: -1, //index dell'ultimo elemento visualizzato ---> utilizzato per determinare il periodo e di conseguenza se riscaricare i dati delle lookup
        })

        function getData(props: DitteProps) {}

        const navLength = computed(() =>
            Array.isArray(elencoState.selectedKeys) ? elencoState.selectedKeys.length : 0,
        )

        const actualIndex = ref(0) //indice dell'elemento in elencoState.selectedKeys

        const getPeriodo = computed(() => {
            return globalStore.state.periodoElab || dateUty.toObject(moment())
        })

        function samePeriod() {
            return true
        }

        /**
         * Funzione che ricava le props per la navigazione orizzontale effettuato dalla pagina OperatoreLista.
         * Viene passato l'indice dell'elemento da visualizzare (contenuto in selectedKeys) al fine di calcolare le props di navigazione.
         * @param idx Indice dell'array degli elementi selezionati nell'elenco.
         * @returns props utilizzate per la navigazione degli operatori
         */
        function getPropsNavigation(idx: number) {
            try {
                return { id: (elencoState.selectedKeys as any)[idx], mod: GLOBALS.DATA_MODE.MOD }
            } catch (error) {
                null
            }
        }

        return {
            actualIndex,
            elencoState,
            getData,
            getPeriodo,
            getPropsNavigation,
            navLength,
            samePeriod,
        }
    },
    {
        persist: false,
    },
)

export default useOperatoreListStore
