import { http } from '@/scripts/lib/http'

const BASE_URL = '/Profile'

const payload: AuthPayload = {
    requireTotalCount: false,
    isCountQuery: false,
}

export const getProfileUrl = `${BASE_URL}/GetProfile`

export default class APIAuth {
    static async get(params?: AuthPayload) {
        try {
            return http.post(getProfileUrl, {
                ...payload,
                ...params,
            })
        } catch (error) {
            console.log(error)
        }
    }
}
