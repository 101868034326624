import { OperatoreLista } from '@/views/Paghe/Admin/Operatore/Lista'
import { OperatoreDettaglio, Childs } from '@/views/Paghe/Admin/Operatore/Dettaglio'

const tabProsDef: TabProps = {
    GROUP: 'operatore',
    ICON: 'fa-regular fa-users',
    TITLE: 'Operatori',
    CODVOCEMENU: '900004',
    CODPERMESSO: 'AMMINISTRAZIONE',
}

export const ROUTES_OPERATORE = Object.freeze({
    OPERATORE_LISTA: {
        TITLE: 'Elenco operatori - Operatore - Gestione - Paghe',
        LABEL: 'Elenco operatori',
        PATH: 'operatore',
        NAME: 'operatoreLista',
        TAB: { ...tabProsDef },
    },
    OPERATORE_DETTAGLIO: {
        TITLE: 'Dettaglio operatore - Gestione - Paghe',
        LABEL: 'Dettaglio operatore',
        PATH: 'operatore/dettaglio/:year/:month/:id/:mod',
        NAME: 'operatoreDettaglio',
        TAB: { ...tabProsDef },
    },
    OPERATORE: {
        TITLE: 'Dettaglio operatore - Gestione - Paghe',
        LABEL: 'Dettaglio operatore',
        PATH: 'operatore',
        NAME: 'operatore',
        TAB: { ...tabProsDef },
    },
})

export default [
    {
        path: ROUTES_OPERATORE.OPERATORE_LISTA.PATH,
        name: ROUTES_OPERATORE.OPERATORE_LISTA.NAME,
        component: OperatoreLista,
        meta: {
            title: ROUTES_OPERATORE.OPERATORE_LISTA.TITLE,
            permissions: {
                codPermesso: tabProsDef.CODPERMESSO,
                codVoceMenu: tabProsDef.CODVOCEMENU,
            },
            tabGroup: tabProsDef.GROUP,
        },
    },
    {
        path: ROUTES_OPERATORE.OPERATORE_DETTAGLIO.PATH,
        name: ROUTES_OPERATORE.OPERATORE_DETTAGLIO.NAME,
        props: true,
        component: OperatoreDettaglio,
        orderWizard: 0,
        meta: {
            title: ROUTES_OPERATORE.OPERATORE_DETTAGLIO.TITLE,
            permissions: {
                codPermesso: tabProsDef.CODPERMESSO,
                codVoceMenu: null,
            },
            tabGroup: tabProsDef.GROUP,
        },
        children: [
            {
                props: true,
                label: ROUTES_OPERATORE.OPERATORE.LABEL,
                path: ROUTES_OPERATORE.OPERATORE.PATH,
                name: ROUTES_OPERATORE.OPERATORE.NAME,
                tab: ROUTES_OPERATORE.OPERATORE.TAB,
                orderWizard: 0,
                component: Childs.Operatore,
                meta: {
                    group: 'operatoreDettaglio',
                    idPaginaMsg: 'OP01',
                    permissions: {
                        codPermesso: tabProsDef.CODPERMESSO,
                        codVoceMenu: null,
                    },
                    tabGroup: tabProsDef.GROUP,
                },
            },
        ],
    },
]
