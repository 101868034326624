<template>
    <v-app>
        <div class="h-100">
            <MainPage />
        </div>
    </v-app>
</template>

<script setup lang="ts">
import { inject } from 'vue'
import { Emitter } from 'mitt'

import { useGlobalStore, useTabStore } from '@/stores'
import MainPage from './components/Main.vue'

type Events = {
    AltA?: string
    AltN?: string
    AltP?: string
    AltQ?: string
    ArrowLeft?: string
    ArrowRight?: string
    CtrlP?: string
    CtrlS?: string
    CtrlI?: string
    F7?: string
    F8?: string
}

const emitter = inject('emitter') as Emitter<Events>
const globalStore = useGlobalStore()
const tabStore = useTabStore()

// Keyboard shortcuts
window.addEventListener('keydown', (event: KeyboardEvent) => {
    const lowerKey = event.key.toLowerCase()

    // CTRL
    if (event.ctrlKey) {
        if (event.altKey) {
            // +ALT+...: navigazione tra tab
            if (event.key === 'ArrowLeft') tabStore.goToTabPrev() // tab precedente
            if (event.key === 'ArrowRight') tabStore.goToTabNext() // tab successiva
            if (event.key >= '1' && event.key <= '7') {
                // tab numero [1-7]
                tabStore.goToTab(parseInt(event.key) - 1)
                event.preventDefault()
            }
        } else {
            // +...: navigazione tra sub-tab di pagina e salvataggio
            const mapKeys: { [index: string]: keyof Events } = {
                arrowleft: 'ArrowLeft', // sub-tab precedente
                arrowright: 'ArrowRight', // sub-tab successivo
                s: 'CtrlS', // salva e resta in pagina
                i: 'CtrlI', // apri modal dialog con info su comandi rapidi da tastiera
            }
            if (lowerKey in mapKeys) {
                emitter.emit(mapKeys[lowerKey])
                event.preventDefault()
            }
        }
        return
    }

    // ALT
    if (event.altKey) {
        // +...: tasti FooterBar
        if (lowerKey === 'x') {
            tabStore.closeTab()
        } else {
            const mapKeys: { [index: string]: keyof Events } = {
                a: 'AltA', // salva e chiude pagina
                n: 'AltN', // carica elemento successivo elenco
                p: 'AltP', // carica elemento precedente elenco
                q: 'AltQ', // torna a elenco
            }
            if (!(lowerKey in mapKeys)) return
            emitter.emit(mapKeys[lowerKey])
        }
        event.preventDefault()
        return
    }

    // FUNCTION KEYS
    if (event.key === 'F7' || event.key === 'F8') {
        // sub.funzione (SideBar) precedente (F7) / successiva (F8)
        emitter.emit(event.key)
        event.preventDefault()
    }
})

// Chiusura tab aperti in altra sessione
if (globalStore.state.startApp) {
    globalStore.state.startApp = false
    tabStore.deleteTab()
}
</script>

<style lang="scss" src="@/styles/app.scss"></style>
