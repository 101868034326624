import aliquota from './aliquota'
import banche from './Banche'
import comune from './comune'
import provincia from './provincia'
import vCodDes from './vCodDes'
import vInailSede from './vInailSede'
import VSedeInps from './VSedeInps'
import vInailEsazione from './VInailEsazione'
import vContrattoCO from './vContrattoCO'
import vContrattoCNEL from './vContrattoCNEL'
import vContrattoInps from './VContrattoInps'
import vCodiceUniemens from './vCodiceUniemens'
import vCostante from './vCostante'
import vQualificaInps from './vQualificaInps'
import vQualificaDipte from './vQualificaDipte'
import vTipoRapporto from './vTipoRapporto'
import vDatoFisso from './vDatoFisso'
import VMaggiorazioneSpettante from './VMaggiorazioneSpettante'
import VTipoRetribuzione from './vTipoRetribuzione'

import varie from './varie'
import vInailVoceTariffa from './vInailVoceTariffa'
import vCodiceUnimare from './vCodiceUnimare'

import vIrpefScaglImposta from './vIrpefScaglImposta'
import vIrpefDetrLavDipte from './vIrpefDetrLavDipte'

import VCaaf from './VCaaf'
import vTributo from './vTributo'
import ente from './ente'
export default {
    aliquota,
    banche,
    comune,
    provincia,
    vCodDes,
    vInailSede,
    VSedeInps,
    vCodiceUniemens,
    vCodiceUnimare,
    varie,
    vContrattoCNEL,
    vDatoFisso,
    vContrattoInps,
    vContrattoCO,
    vCostante,
    vQualificaInps,
    vQualificaDipte,
    vTipoRapporto,
    vInailEsazione,
    VMaggiorazioneSpettante,
    VTipoRetribuzione,
    vInailVoceTariffa,
    vIrpefDetrLavDipte,
    vIrpefScaglImposta,
    VCaaf,
    vTributo,
    ente,
}
