import { enmInputStatus, enmValidatorType } from '@/typescript/Enums'

export default class ValidationResult {
    message: string = ''
    value: enmInputStatus = enmInputStatus.none
    type: enmValidatorType = enmValidatorType.messageBelow

    constructor(init?: Partial<ValidationResult>) {
        Object.assign(this, init)
    }
}

// message 0 - 1
