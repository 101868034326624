import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

export const BASE_URL_SOSTITUTO = '/DittaSostituto'

class API {
    @call({ url: `${BASE_URL_SOSTITUTO}/GetDittaSostituto` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DittaSostituto>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_SOSTITUTO}/CreateDittaSostituto` })
    static async save(
        payload: CreateDittaSostituto,
    ): Promise<AxiosResponse<BasicResponsePayload<DittaSostituto & DiarioLog>> | null> {
        return null
    }
    @call({ url: `${BASE_URL_SOSTITUTO}/UpdateDittaSostituto`, method: 'put' })
    static async update(
        payload: UpdateDittaSostituto,
    ): Promise<AxiosResponse<BasicResponsePayload<DittaSostituto & DiarioLog>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
    update: API.update,
}
