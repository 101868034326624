import CollaboratoreRoutes from './route-paghe-collaboratore'
import DipendenteRoutes from './route-paghe-dipendente'
//import DittaRoutes from './route-paghe-ditta'
//import IntermediarioRoutes from './route-paghe-intermediario'
import SocioRoutes from './route-paghe-socio'
import Sommdistoutes from './route-paghe-sommdist'

export default [
    ...CollaboratoreRoutes,
    ...DipendenteRoutes,
    //...DittaRoutes,
    //...IntermediarioRoutes,
    ...SocioRoutes,
    ...Sommdistoutes,
]
