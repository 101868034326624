import { defineStore } from 'pinia'
import { reactive } from 'vue'
import { STORES_NAMES } from '@/constants'

const useLookupStore = defineStore(
    STORES_NAMES.LOOKUP,
    () => {
        const state: any = reactive({})

        return {
            state,
        }
    },
    {
        persist: false,
    },
)

export default useLookupStore
