const ContrattoStraordinario = () => import('./ContrattoStraordinario.vue')
const Contributive = () => import('./Contributive.vue')
const DivisoriOrari = () => import('./DivisoriOrari.vue')
const Elementi = () => import('./Elementi.vue')
const ElementiPaga = () => import('./ElementiPaga.vue')
const Festivita = () => import('./Festivita.vue')
const Ferie = () => import('./Ferie.vue')
const Infortunio = () => import('./Infortunio.vue')
const IstitutiContrattuali = () => import('./IstitutiContrattuali.vue')
const Malattia = () => import('./Malattia.vue')
const Maternita = () => import('./Maternita.vue')
const MensilitaAggiuntive = () => import('./MensilitaAggiuntive.vue')
const ProgRetributiva = () => import('./ProgRetributiva.vue')
const UnaTantum = () => import('./UnaTantum.vue')

export {
    ContrattoStraordinario,
    Contributive,
    DivisoriOrari,
    Elementi,
    ElementiPaga,
    Festivita,
    Ferie,
    Infortunio,
    IstitutiContrattuali,
    Malattia,
    Maternita,
    MensilitaAggiuntive,
    ProgRetributiva,
    UnaTantum,
}
