export const ViewAlterazioneName = {
    aElePaga: 'Altera_ElePaga',
    aUnaTantum: 'Altera_UnaTantum',
    aValElePaga: 'Altera_ValorizzaElePaga',
    cDivisore: 'Contratto_Divisore',
    cEnte: 'Contratto_Ente',
    cExFestRol: 'Contratto_FeExFestRol',
    cFestivita: 'Contratto_Festivita',
    cInfortunio: 'Contratto_Infortunio',
    cIstitutoContr: 'Contratto_IstitutoContr',
    cMalattia: 'Contratto_Malattia',
    cMaternita: 'Contratto_Maternita',
    cMensAgg: 'Contratto_MensAgg',
    cProgRetrib: 'Contratto_ProgrRetrib',
    cStraordinario: 'Contratto_Straordinario',
} as const

export type ViewAlterazioneNameKey = keyof typeof ViewAlterazioneName
export type ViewAlterazioneNameValue = (typeof ViewAlterazioneName)[ViewAlterazioneNameKey]

export const NomeTabellaAttiva = {
    aElePaga: 'Altera_ElePaga',
    aValElePaga: 'Altera_ValorizzaElePaga',
    cEnte: 'Contratto_Ente',
    cDivisore: 'Contratto_Divisore',
    cExFestRol: 'Contratto_FeExFestRol',
    cInfortunio: 'Contratto_Infortunio',
    cIstitutoContr: 'Contratto_IstitutoContr',
    cMalattia: 'Contratto_Malattia',
    cMaternita: 'Contratto_Maternita',
    cMensAgg: 'Contratto_MensAgg',
    cProgRetrib: 'Contratto_ProgrRetrib',
    cStraordinario: 'Contratto_Straordinario',
    dUPAttivita: 'Ditta_UPAttivita',
    dUPCampoPers: 'Ditta_UPCampoPersonalizzato',
    dUPEnte: 'Ditta_UPEnte',
    dUPEnteFisso: 'Ditta_UPEnteFisso',
    dUPFormula: 'Ditta_UPFormula',
    dUPGest: 'Ditta_UPGestione',
    dUPNotaCedo: 'Ditta_UPNotaCedoFissa',
    dUPOpzione: 'Ditta_UPOpzione',
    dUPOpzioneStampa: 'Ditta_UPOpzioneStampa',
    dUPRagioneSociale: 'Ditta_UPRagioneSociale',
    dUPStorico: 'Ditta_UPStorico',
    scadenza: 'Scadenza',
} as const

export type NomeTabellaAttivaKey = keyof typeof NomeTabellaAttiva
export type NomeTabellaAttivaValue = (typeof NomeTabellaAttiva)[NomeTabellaAttivaKey]
