// moduli
import pagheRoutes, { routes as RoutesPagheModule } from '@paghe/routes'

import Bacheca from '@/views/Paghe/Bacheca.vue'
import RoutesAdmin from './admin'
import RoutesPaghe from './route-paghe'

export const ROUTES_INDEX = Object.freeze({
    INDEX_PAGHE: {
        TITLE: null,
        LABEL: null,
        PATH: '/paghe',
        NAME: null,
    },
    INDEX_BACHECA: {
        TITLE: 'Bacheca - Paghe',
        LABEL: null,
        PATH: 'bacheca',
        NAME: 'Bacheca',
        TAB: {
            GROUP: 'bacheca',
            ICON: 'fa-regular fa-house-user',
            TITLE: 'Bacheca',
        },
    },
})

export const routes = [
    {
        path: '/',
        name: 'root',
    },
    {
        path: ROUTES_INDEX.INDEX_PAGHE.PATH,
        children: [
            {
                path: ROUTES_INDEX.INDEX_BACHECA.PATH,
                name: ROUTES_INDEX.INDEX_BACHECA.NAME,
                component: Bacheca,
                meta: {
                    title: ROUTES_INDEX.INDEX_BACHECA.TITLE,
                    permissions: {
                        codPermesso: 'ALL',
                        codVoceMenu: '000000',
                    },
                    metaTags: [
                        {
                            name: 'description',
                            content: "Gestione Paghe, home page dell'applicazione",
                        },
                        {
                            property: 'og:description',
                            content: "Gestione Paghe, home page dell'applicazione",
                        },
                    ],
                    tabGroup: ROUTES_INDEX.INDEX_BACHECA.TAB.GROUP,
                },
            },
            ...RoutesAdmin,
            ...RoutesPaghe,
            ...RoutesPagheModule,
        ],
        name: 'paghe',
    },
]

export { pagheRoutes }
