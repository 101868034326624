import { pagheViews } from '@/views'

const tabPropsDefault: TabProps = {
    GROUP: 'risultatiDipendente',
    ICON: 'fa-regular fa-buildings',
    TITLE: 'Risultati Dipendenti',
    CODPERMESSO: 'RII_DIPTE',
}

export const SETTINGS = Object.freeze({
    DIPENDENTE_LISTA: {
        TITLE: 'Elenco Dipendenti - Risultati - Dipendente - Gestione - Paghe',
        LABEL: 'RISULTATI DIPENDENTE',
        PATH: 'risDipendenti/:year/:month/:day/:dittaId/:dittaPadreId',
        NAME: 'ElencoRisultatiDipendenti',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_DETTAGLIO: {
        TITLE: 'Dettaglio risultati dipendente - Risultati - Dipendente - Gestione - Paghe',
        LABEL: 'Dettaglio dipendente',
        PATH: 'risDipendenti/:year/:month/:day/:dittaId/:dittaPadreId/:id/:mod',
        NAME: 'DipendenteRisultatiDettaglio',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_RISULTATI: {
        TITLE: '',
        LABEL: 'Risultati',
        PATH: 'risultati',
        NAME: 'DipendenteRisultati',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_ENTI: {
        TITLE: '',
        LABEL: 'Enti',
        PATH: 'enti',
        NAME: 'DipendenteRisEnti',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_VOCI: {
        TITLE: '',
        LABEL: 'Voci',
        PATH: 'voci',
        NAME: 'DipendenteRisVoci',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_CARICHI_FAMIGLIA: {
        TITLE: '',
        LABEL: 'Spettanza detrazioni fiscali',
        PATH: 'carichiFamiglia',
        NAME: 'DipendenteRisFamiglia',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_PRESTITI: {
        TITLE: '',
        LABEL: 'Finanz./Pignoram.',
        PATH: 'prestiti',
        NAME: 'DipendenteRisPrestiti',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_INAIL: {
        TITLE: '',
        LABEL: 'Dati inail',
        PATH: 'inail',
        NAME: 'DipendenteRisInail',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_CAUSALI: {
        TITLE: '',
        LABEL: 'Causali eventi',
        PATH: 'causali',
        NAME: 'DipendenteRisCausali',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.DIPENDENTE_LISTA.PATH,
            name: SETTINGS.DIPENDENTE_LISTA.NAME,
            component: pagheViews.risultati.risultatiDipendenti.listeDipendenti.ElencoDipendenti, //ElencoDipendenti,
            meta: {
                title: SETTINGS.DIPENDENTE_LISTA.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            props: true,
        },
        {
            path: SETTINGS.DIPENDENTE_DETTAGLIO.PATH,
            name: SETTINGS.DIPENDENTE_DETTAGLIO.NAME,
            props: true,
            component:
                pagheViews.risultati.risultatiDipendenti.dettaglioDipendenti
                    .RisultatiDipteDettaglio,
            meta: {
                title: SETTINGS.DIPENDENTE_DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: null,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            children: [
                {
                    label: SETTINGS.DIPENDENTE_RISULTATI.LABEL,
                    path: SETTINGS.DIPENDENTE_RISULTATI.PATH,
                    name: SETTINGS.DIPENDENTE_RISULTATI.NAME,
                    tab: SETTINGS.DIPENDENTE_RISULTATI.TAB,
                    component:
                        pagheViews.risultati.risultatiDipendenti.dettaglioDipendenti.childs
                            .RisultatiDipte,
                    meta: {
                        group: SETTINGS.DIPENDENTE_DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    orderWizard: 0,
                    props: true,
                },
                {
                    label: SETTINGS.DIPENDENTE_CARICHI_FAMIGLIA.LABEL,
                    path: SETTINGS.DIPENDENTE_CARICHI_FAMIGLIA.PATH,
                    name: SETTINGS.DIPENDENTE_CARICHI_FAMIGLIA.NAME,
                    tab: SETTINGS.DIPENDENTE_CARICHI_FAMIGLIA.TAB,
                    component:
                        pagheViews.risultati.risultatiDipendenti.dettaglioDipendenti.childs
                            .RisultatiCarichiFamiglia,
                    meta: {
                        group: SETTINGS.DIPENDENTE_DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    orderWizard: 1,
                    props: true,
                },
                {
                    label: SETTINGS.DIPENDENTE_PRESTITI.LABEL,
                    path: SETTINGS.DIPENDENTE_PRESTITI.PATH,
                    name: SETTINGS.DIPENDENTE_PRESTITI.NAME,
                    tab: SETTINGS.DIPENDENTE_PRESTITI.TAB,
                    component:
                        pagheViews.risultati.risultatiDipendenti.dettaglioDipendenti.childs
                            .RisultatiPrestiti,
                    meta: {
                        group: SETTINGS.DIPENDENTE_DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    orderWizard: 2,
                    props: true,
                },
                {
                    label: SETTINGS.DIPENDENTE_INAIL.LABEL,
                    path: SETTINGS.DIPENDENTE_INAIL.PATH,
                    name: SETTINGS.DIPENDENTE_INAIL.NAME,
                    tab: SETTINGS.DIPENDENTE_INAIL.TAB,
                    component:
                        pagheViews.risultati.risultatiDipendenti.dettaglioDipendenti.childs
                            .RisultatiInail,
                    meta: {
                        group: SETTINGS.DIPENDENTE_DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    orderWizard: 3,
                    props: true,
                },
                {
                    label: SETTINGS.DIPENDENTE_ENTI.LABEL,
                    path: SETTINGS.DIPENDENTE_ENTI.PATH,
                    name: SETTINGS.DIPENDENTE_ENTI.NAME,
                    tab: SETTINGS.DIPENDENTE_ENTI.TAB,
                    component:
                        pagheViews.risultati.risultatiDipendenti.dettaglioDipendenti.childs
                            .RisultatiEnti,
                    meta: {
                        group: SETTINGS.DIPENDENTE_DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    orderWizard: 4,
                    props: true,
                },
                {
                    label: SETTINGS.DIPENDENTE_VOCI.LABEL,
                    path: SETTINGS.DIPENDENTE_VOCI.PATH,
                    name: SETTINGS.DIPENDENTE_VOCI.NAME,
                    tab: SETTINGS.DIPENDENTE_VOCI.TAB,
                    component:
                        pagheViews.risultati.risultatiDipendenti.dettaglioDipendenti.childs
                            .RisultatiVoci,
                    meta: {
                        group: SETTINGS.DIPENDENTE_DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    orderWizard: 5,
                    props: true,
                },
                {
                    label: SETTINGS.DIPENDENTE_CAUSALI.LABEL,
                    path: SETTINGS.DIPENDENTE_CAUSALI.PATH,
                    name: SETTINGS.DIPENDENTE_CAUSALI.NAME,
                    tab: SETTINGS.DIPENDENTE_CAUSALI.TAB,
                    component:
                        pagheViews.risultati.risultatiDipendenti.dettaglioDipendenti.childs.Causali,
                    meta: {
                        group: SETTINGS.DIPENDENTE_DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    orderWizard: 6,
                    props: true,
                },
            ],
        },
    ],
}
