const RisultatiDipte = () => import('./RisultatiDipte.vue')
const RisultatiEnti = () => import('./RisultatiEnti.vue')
const RisultatiVoci = () => import('./RisultatiVoci.vue')
const RisultatiCarichiFamiglia = () => import('./RisultatiCarichiFamiglia.vue')
const RisultatiPrestiti = () => import('./RisultatiPrestiti.vue')
const RisultatiInail = () => import('./RisultatiInail.vue')
const Causali = () => import('./Causali.vue')

export {
    Causali,
    RisultatiCarichiFamiglia,
    RisultatiDipte,
    RisultatiEnti,
    RisultatiInail,
    RisultatiPrestiti,
    RisultatiVoci,
}
