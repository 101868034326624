import { get } from 'lodash'

import { http } from '@/scripts/lib/http'
import { useGlobalStore } from '@/stores'
import { getDataDb } from '@/composables/indexDB'
import { INDEXED_DB } from '@/constants/index'

const BASE_URL = '/Ente'

const payload: Payload = {
    requireTotalCount: false,
    isCountQuery: false,
}

export default class APIEnte {
    static async get(params?: Payload, force: boolean = false) {
        function getAPI() {
            const globalStore = useGlobalStore()
            return http.post(`${BASE_URL}/GetEnti`, {
                ...payload,
                ...{
                    periodoDiRiferimento: globalStore.state.periodoElab,
                },
                ...params,
            })
        }
        if (force) {
            return getAPI()
        } else {
            const res: any = await getDataDb(INDEXED_DB.STORES.ENTI, params)
            if (res?.responseStatus?.isSuccessful === true)
                return get(res, `${INDEXED_DB.STORES.ENTI.DATA_ROOT}`, [])
            return await get(await getAPI(), `data.${INDEXED_DB.STORES.ENTI.DATA_ROOT}`, [])
        }
    }
}
