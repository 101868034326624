import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'
import { BASE_URL_DIPTE } from './dipte'

const BASE_URL = '/DipteCollocamento'

class API {
    @call({ url: `${BASE_URL}/GetDipendentiCollocamento` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DipteCollocamento>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/UpdateDipteCollocamento`, method: 'put' })
    static async update(
        payload: UpdateDipteCollocamentoPayload,
    ): Promise<AxiosResponse<BasicResponseData<DipteCollocamento>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/CreateDipteCollocamento` })
    static async save(
        payload: UpdateDipteCollocamentoPayload,
    ): Promise<AxiosResponse<BasicResponseData<DipteCollocamento>> | null> {
        return null
    }
}

export default {
    get: API.get,
    update: API.update,
    save: API.save,
}
