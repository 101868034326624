import { call } from '@/scripts/lib/http'

const BASE_URL = '/EseguiExport'

const defaultPayload: EseguiExportPayload = {
    requireTotalCount: false,
    isCountQuery: false,
}

class API {
    @call({ url: `${BASE_URL}/ExportDipendenteJobExcel`, defaultPayload })
    static dipendente<T>(payload: T): any {}

    @call({ url: `${BASE_URL}/ExportDittaJobExcel`, defaultPayload })
    static ditta<T>(payload: T): any {}
}

export default {
    dipendente: API.dipendente<EseguiExportPayload>,
    ditta: API.ditta<EseguiExportPayload>,
}
