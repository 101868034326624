import pagheViews from '@paghe/views'
import { RouteLocation } from 'vue-router'

const tabPropsDefault: TabProps = {
    TITLE: 'Intermediari',
    ICON: 'fa-regular fa-users-line',
    GROUP: 'anagraficaIntermediari',
    CODVOCEMENU: '000005',
}

export const SETTINGS = Object.freeze({
    INTERMEDIARIO_ANAGRAFICA: {
        TITLE: 'Anagrafica intermediario - Intermediario - Anagrafici - Gestione - Paghe',
        PATH: 'intermediario/:id/:mod',
        NAME: 'IntermediarioAnagrafica',
        TAB: { ...tabPropsDefault },
    },
    INTERMEDIARIO_FISCALE: {
        TITLE: 'Intermediario Fiscale - Intermediario - Anagrafici - Gestione - Paghe',
        PATH: 'intermediarioFiscale/:intermediarioId/:id/:mod',
        NAME: 'IntermediarioFiscale',
        TAB: { ...tabPropsDefault },
    },
    INTERMEDIARIO_INAIL_LUL: {
        TITLE: 'Intermediario Inail / Lul - Intermediario - Anagrafici - Gestione - Paghe',
        PATH: 'intermediarioInailLul/:intermediarioId/:id/:mod',
        NAME: 'IntermediarioInailLul',
        TAB: { ...tabPropsDefault },
    },
    INTERMEDIARIO_LISTA: {
        TITLE: 'Elenco intermediari - Intermediario - Anagrafici - Gestione - Paghe',
        LABEL: 'Elenco intermediari',
        PATH: 'intermediario',
        NAME: 'IntermediarioLista',
        TAB: { ...tabPropsDefault },
    },
    INTERMEDIARIO_UNIEMENS: {
        TITLE: 'Intermediario Uniemens - Intermediario - Anagrafici - Gestione - Paghe',
        PATH: 'intermediarioUniemens/:intermediarioId/:id/:mod',
        NAME: 'IntermediarioUniemens',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.INTERMEDIARIO_ANAGRAFICA.PATH,
            name: SETTINGS.INTERMEDIARIO_ANAGRAFICA.NAME,
            component: pagheViews.anagrafiche.intermediari.dettaglio.Anagrafica,
            meta: {
                title: SETTINGS.INTERMEDIARIO_ANAGRAFICA.TITLE,
                permissions: {
                    codPermesso: 'INTERMEDIARIO',
                },
                iconFont: 'fa-regular fa-users-line',
                altLabel: '',
                tabGroup: tabPropsDefault.GROUP,
            },
            props: (route: RouteLocation) => ({
                id: Number(route.params.id),
                mod: route.params.mod,
            }),
        },
        {
            path: SETTINGS.INTERMEDIARIO_FISCALE.PATH,
            name: SETTINGS.INTERMEDIARIO_FISCALE.NAME,
            component: pagheViews.anagrafiche.intermediari.dettaglio.Fiscale,
            meta: {
                title: SETTINGS.INTERMEDIARIO_FISCALE.TITLE,
                permissions: {
                    codPermesso: 'INTERMEDIARIO',
                    codVoceMenu: null,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            props: (route: RouteLocation) => ({
                id: Number(route.params.id),
                intermediarioId: Number(route.params.intermediarioId),
                mod: route.params.mod,
            }),
        },
        {
            path: SETTINGS.INTERMEDIARIO_INAIL_LUL.PATH,
            name: SETTINGS.INTERMEDIARIO_INAIL_LUL.NAME,
            component: pagheViews.anagrafiche.intermediari.dettaglio.InailLul,
            meta: {
                title: SETTINGS.INTERMEDIARIO_INAIL_LUL.TITLE,
                permissions: {
                    codPermesso: 'INTERMEDIARIO',
                    codVoceMenu: null,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            props: (route: RouteLocation) => ({
                id: Number(route.params.id),
                intermediarioId: Number(route.params.intermediarioId),
                mod: route.params.mod,
            }),
        },
        {
            path: SETTINGS.INTERMEDIARIO_LISTA.PATH,
            name: SETTINGS.INTERMEDIARIO_LISTA.NAME,
            component: pagheViews.anagrafiche.intermediari.Lista,
            meta: {
                title: SETTINGS.INTERMEDIARIO_LISTA.TITLE,
                permissions: {
                    codPermesso: 'INTERMEDIARIO',
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
        },
        {
            path: SETTINGS.INTERMEDIARIO_UNIEMENS.PATH,
            name: SETTINGS.INTERMEDIARIO_UNIEMENS.NAME,
            component: pagheViews.anagrafiche.intermediari.dettaglio.Uniemens,
            meta: {
                title: SETTINGS.INTERMEDIARIO_UNIEMENS.TITLE,
                permissions: {
                    codPermesso: 'INTERMEDIARIO',
                    codVoceMenu: null,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            props: (route: RouteLocation) => ({
                id: Number(route.params.id),
                intermediarioId: Number(route.params.intermediarioId),
                mod: route.params.mod,
            }),
        },
    ],
}
