<template>
    <div
        class="mixin-is-elaborato"
        tabindex="0"
    >
        <v-icon
            class="icon-green"
            icon="fas fa-calendar-check"
        />
        <v-tooltip
            activator="parent"
            location="top"
            role="tooltip"
            text="Periodo già elaborato"
        />
    </div>
</template>

<script lang="ts" setup></script>

<style lang="scss">
@import '@/styles/global/vars';
.mixin-is-elaborato {
    .icon-green {
        color: var(--color-icon-green);
    }
}
</style>
