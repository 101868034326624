import { defineStore } from 'pinia'
import { computed, ref, shallowReactive } from 'vue'
import type { ShallowReactive, Ref } from 'vue'
import { useRoute } from 'vue-router'

import dateUty from '@/scripts/services/date'
import { useDynamicStore } from '@/composables/dynamic-store'
import { useGlobalStore } from '@/stores'

export const storeName: string = 'ditteElencoStore'
//function prova (_pagestore: _UnwrapAll<DitteElencoStore>) ..... esempio uso esterno

export default defineStore(
    storeName,
    (): DitteElencoStore => {
        const dynamicStore = useDynamicStore()
        const globalStore = useGlobalStore()
        const route = useRoute()
        const actualIndex = ref(0)
        const periodoGestione: Ref<PeriodoElab | undefined> = ref()
        const unitaProduttiva = ref(false)

        const state: ShallowReactive<DitteElencoStatus> = shallowReactive({
            filtro: '',
            selectedKeys: true,
            sortBy: [
                { key: 'codiceDitta', order: 'asc' },
                { key: 'unitaProduttiva', order: 'asc' },
            ],
            lastIdx: -1,
            filtroDitteGestite: true,
            filtroDitteTipo: [1, 2],
            filtroDitteUP: unitaProduttiva.value,
        })

        //#region GETTERs
        const navLength = computed(() => {
            return typeof state.selectedKeys != 'boolean' ? state.selectedKeys.length : 0
        })

        const getUnitaProduttiva = computed(() => {
            return unitaProduttiva.value
        })
        //#endregion

        //#region ACTIONs
        function getData(props: DitteProps): void {
            let element = dynamicStore
                .getState('dto.data', [])
                .find((item: any) => item.id == props.id)
            if (element?.idPadre > 0) {
                const padre = dynamicStore
                    .getState('dto.data', [])
                    .find((item: any) => item.id == element.idPadre)

                if (padre?.dittaUPOpzione) {
                    element.padre = {
                        dittaUPOpzione: {
                            isDiario: padre.dittaUPOpzione.isDiario,
                        },
                    }
                }
            }
            return element
        }

        /**
         * Controlla se la ditta da cui si proviene (durante la navigazione) ha lo stesso periodo della ditta che si vuole visualizzare.
         * @returns true se il periodo e' il medesimo, altrimenti false.
         */
        function samePeriod(): boolean {
            if (state.lastIdx === -1) return false

            let previousPeriod = getPeriodoDitta(state.lastIdx, true)
            let actualPeriod = getPeriodoDitta(actualIndex.value, true)

            if (
                previousPeriod?.month === actualPeriod?.month &&
                previousPeriod?.year === actualPeriod?.year
            )
                return true

            return false
        }

        /**
         * Restituisce il periodo attuale di elaborazione di una ditta.
         * @param id id della Ditta dalla quale ottenere il periodo
         * @param isIndex indica se l'id passato e' l'indice della lista di elementi (selectedKeys) selezionati nell'elenco
         * @returns il periodo della ditta oppure null | undefined se non viene trovato
         */
        function getPeriodoDitta(id: number, isIndex: boolean = false) {
            if (route.params.gestione !== 'crr') return periodoGestione.value
            const element = dynamicStore
                .getState('dto.data', [])
                .find(
                    (item: any) =>
                        item.id ==
                        (isIndex && typeof state.selectedKeys != 'boolean'
                            ? state.selectedKeys[id]
                            : id),
                )
            const periodoElab = element?.dataPeriodoElaborazione
            if (periodoElab) {
                return dateUty.toObject(periodoElab)
            } else {
                // se UP prendere il periodo della ditta principale
                if (element.idPadre) return getPeriodoDitta(element.idPadre)
            }
            return globalStore.state.periodoElab
        }

        /**
         * Restituisce i parametri di navigazione relativi a una data Ditta.
         * @param id id della Ditta dalla quale ottenere il periodo
         * @param isIndex indica se l'id passato e' l'indice della lista di elementi (selectedKeys) selezionati nell'elenco
         * @returns
         */
        function getPropsNavigation(id: number, isIndex: boolean = false) {
            const element = dynamicStore
                .getState('dto.data', [])
                .find(
                    (item: any) =>
                        item.id ==
                        (isIndex && typeof state.selectedKeys != 'boolean'
                            ? state.selectedKeys[id]
                            : id),
                )
            return { ...getPeriodoDitta(id, isIndex), idPadre: +element.idPadre }
        }

        /**
         * Restituisce il periodo della ditta attualmente visualizzata.
         * @returns periodo attualmente utilizzato
         */
        function getPeriodo() {
            return {
                year: state.props?.year,
                month: state.props?.month,
            }
        }

        //#endregion

        return {
            ...dynamicStore,
            actualIndex,
            getData,
            getPeriodo,
            getPeriodoDitta,
            getPropsNavigation,
            navLength,
            samePeriod,
            state,
            periodoGestione,
            unitaProduttiva,
            getUnitaProduttiva,
        }
    },
    {
        persist: {
            paths: ['periodoGestione'],
        },
    },
)
