import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'
import { BASE_URL_DITTA } from './ditta'

const BASE_URL_APPALTO = '/DittaGruppoAlterazione'

class API {
    @call({ url: `${BASE_URL_APPALTO}/GetDitteGruppoAlterazione` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DittaGruppoAlterazione>> | null> {
        return null
    }
    @call({ url: `${BASE_URL_DITTA}/SaveDittaGruppoAlterazione` })
    static async save(
        payload: SaveDittaGruppoAlterazione,
    ): Promise<AxiosResponse<BasicResponseData<DittaGruppoAlterazione>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
