<template>
    <div class="base-input-text-area">
        <v-textarea
            v-bind="attrs"
            ref="baseInputTextArea"
            clearable
            clear-icon="far fa-times"
            :class="{ 'v-input--warning': isWarning }"
            counter
            density="compact"
            :messages="messages"
            :model-value="modelValue"
            no-resize
            persistent-placeholder
            variant="outlined"
            :tabindex="attrs.readonly ? '-1' : null"
            @update:model-value="updateValue"
        />
    </div>
</template>

<script setup lang="ts">
import { useAttrs, computed, ref } from 'vue'
import { castArray } from 'lodash'

export interface IBaseInputTextArea {
    warningMessages?: string | string[]
}

const props = withDefaults(defineProps<IBaseInputTextArea>(), {
    warningMessages: () => [],
})

const attrs: any = useAttrs()

const baseInputTextArea = ref()
const emits = defineEmits(['change'])

const isWarning = computed(() => !attrs.errorMessages?.length && !!props.warningMessages.length)

const modelValue = computed(() => attrs.modelValue || attrs['model-value'])

const messages = computed(() => {
    let messages: string[] = castArray(props.warningMessages).slice(0, 1)
    if (!messages.length && attrs.messages) {
        messages = castArray(attrs.messages).slice(0, 1) as typeof messages
    }
    return messages
})

function updateValue(value: any) {
    emits('change', { currentValue: value, oldValue: modelValue.value })
}
</script>

<style lang="scss">
@import '@/styles/global/mixins';

.base-input-text-area {
    @include input_field;
}
</style>
