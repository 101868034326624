import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'
import { BASE_URL_DITTA } from './ditta'

const BASE_URL_FORMULA = '/DittaUPFormula'

class API {
    @call({ url: `${BASE_URL_FORMULA}/GetDittaUPFormula` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DittaUPFormula>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DITTA}/SaveDittaUPFormula` })
    static async save(
        payload: SaveDittaUPFormulaPayload,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveDittaUPFormulaPayload>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
