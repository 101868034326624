import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'
const BASE_URL_FONDO_AGRICOLO = '/DittaFondoAgricolo'

class API {
    @call({ url: `${BASE_URL_FONDO_AGRICOLO}/GetDittaFondoAgricolo` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DittaFondoAgricolo>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_FONDO_AGRICOLO}/SaveDittaFondoAgricolo` })
    static async save(
        payload: SaveDittaFondoAgricoloPayload,
    ): Promise<AxiosResponse<BasicDittaResponsePayload<DittaFondoAgricolo>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
