const banche = () => import('./Banche.vue')
const codiceDescrizione = () => import('./CodiceDescrizione.vue')
const comuni = () => import('./Comuni.vue')
const CodiciContrattoCNEL = () => import('./CodiciContrattoCNEL.vue')
const CodiciContrattoCO = () => import('./CodiciContrattoCO.vue')
const contrattoInps = () => import('./ContrattoInps.vue')
const qualificheInps = () => import('./QualificheInps.vue')
const codiciEsazione = () => import('./CodiciEsazione.vue')
const codiciTributo = () => import('./CodiciTributo.vue')
const Caaf = () => import('./Caaf.vue')
const maggiorazioniSpettanti = () => import('./MaggiorazioniSpettanti.vue')
const uniemens = () => import('./Uniemens.vue')
const vociDiTariffa = () => import('./VociDiTariffa.vue')
const unimare = () => import('./Unimare.vue')
const province = () => import('./Province.vue')
const tipoRetribuzione = () => import('./TipoRetribuzione.vue')
const irpefDetrazioniFigli = () => import('./IrpefDetrazioneFigli.vue')
const codiciOutput = () => import('./CodiciOutput.vue')
const irpefScaglioniImposta = () => import('./IrpefScaglioniImposta.vue')
const irpefDetrazione = () => import('./IrpefDetrazione.vue')
export {
    banche,
    codiceDescrizione,
    CodiciContrattoCNEL,
    CodiciContrattoCO,
    contrattoInps,
    codiciEsazione,
    codiciOutput,
    comuni,
    codiciTributo,
    irpefDetrazioniFigli,
    maggiorazioniSpettanti,
    qualificheInps,
    unimare,
    uniemens,
    vociDiTariffa,
    Caaf,
    province,
    tipoRetribuzione,
    irpefScaglioniImposta,
    irpefDetrazione,
}
