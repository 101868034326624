import { castArray, isEqual, values } from 'lodash'
import { RouteLocationNormalized, createRouter, createWebHistory } from 'vue-router'
import { useTabStore } from '@/stores'
import { routes } from '@/routes'
import { ROUTES_INDEX, pagheRoutes } from '@/routes'
import { MessageBox } from '@/scripts/services/utility.js'
import { MAX_TABS } from '@/stores/TabStore'
import { useStorePermissions } from '@/scripts/stores/Permissions/pagePermission'

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach(async (to, from, next) => {
    if (to.path === '/') {
        if (!from.name) {
            //ripristino route in caso di refresh applicativo
            const tabStore = useTabStore()
            const navProps = tabStore.getTabNavigationProps()
            if (navProps) next(navProps)
        }
        next({ name: ROUTES_INDEX.INDEX_BACHECA.NAME })
    }

    //controllo se la rotta voluta esiste altrimenti callback in bacheca
    if (!to?.name || !router.hasRoute(to.name)) {
        next({ name: ROUTES_INDEX.INDEX_BACHECA.NAME })
    }

    //controllo permesso operatore
    if (to.name !== 'Bacheca' && !checkPermessi(to, from)) {
        next({ name: ROUTES_INDEX.INDEX_BACHECA.NAME })
    }

    //Controllo per impedire di aprire piu di un certo numero di tab (fissato nel tabStore)
    if (!checkTab(to, from)) next(from.path)

    next()
})

export type CodTipoPermesso = 'A' | 'C' | 'N' | null
export type VoceMenu = {
    codAutoMenu: CodTipoPermesso
    codVoceMenu: string
    descrizione: string
    vociFiglie: VoceMenu[] | null
}

export const checkPermessi = (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    function findVoceMenu(codVoceMenu: string, voceMenu: VoceMenu[] | null): boolean {
        return (
            voceMenu?.some((vm: VoceMenu) => {
                return vm.vociFiglie
                    ? findVoceMenu(codVoceMenu, vm.vociFiglie)
                    : vm.codVoceMenu === codVoceMenu
            }) || false
        )
    }

    const pagePermission = useStorePermissions()
    const permissions = to.meta?.permissions as {
        codPermesso: string
        codVoceMenu: string | string[]
    }
    const codPermesso = permissions.codPermesso || null
    if (codPermesso === 'ALL') return true

    if (permissions) {
        // >>> BUG #27070: messo in postponedTo in quanto codVoceMenu ad oggi non è su tutte le pagine
        // controlla se pagina richiesta è in menù
        // if (permissions?.codVoceMenu) {
        //     const ok = castArray(permissions.codVoceMenu).some(cvm =>
        //         findVoceMenu(cvm, pagePermission.menu as VoceMenu[]),
        //     )
        //     if (ok) {
        // <<< BUG #27070
        // controlla se pagina richiesta è accessibile
        const codTipoPermesso = pagePermission.getPermissionType(codPermesso)
        if (
            typeof codTipoPermesso === 'object' &&
            values(codTipoPermesso).some(a => a === 'A' || a === 'C')
        ) {
            //se almeno un elemento e' diverso da 'N' torno true
            return true
        }
        if (['A', 'C'].includes(codTipoPermesso)) return true
        // }
        // }
    }

    // Annulla redirect
    MessageBox.ErrorMessage({
        html: 'Utente con credenziali di accesso insufficienti.',
        showCancelButton: false,
    })
    return false
}

const checkTab = (to: any, from: any) => {
    function showWarningMessage(message: string) {
        MessageBox.WarningMessage({
            html: message,
            showCancelButton: false,
        })
    }

    // Reload page (F5)
    if (!from.name) return true

    // Bloccata la navigazione se raggiunto il limite massimo di TAB aperte
    const tabStore = useTabStore()
    const toElementGroup = tabStore.groupInTabs(to.meta.tabGroup)
    if (!toElementGroup.result) {
        if (tabStore.grantOpenTab) return true
        showWarningMessage(`Non è possibile aprire più di ${MAX_TABS} tab.`)
        return false
    }

    // Bloccata la navigazione se la pagina richiesta è diversa da quella già aperta nella TAB
    if (from.meta?.tabGroup !== to.meta?.tabGroup) {
        const toProps = toElementGroup.element.currentTabRoute?.props
        if (toProps && !isEqual(to.params, toProps)) {
            const title = toElementGroup.element.TITLE?.toUpperCase()
            showWarningMessage(`Tab <b>${title}</b> già aperta.`)
            return false
        }
    }

    return true
}

export default router
