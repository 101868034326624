import { defineStore } from 'pinia'
import { createPromiseDialog } from 'vue-promise-dialogs'

import SceltaOperatore from '@/components/SceltaOperatore.vue'
import { APIAuth } from '@/api'
import { useGlobalStore } from '@/stores'
import { setDataDb } from '@/composables/indexDB'

export const useStorePermissions = defineStore({
    id: 'profile',
    state: () => ({
        pagePermissions: [],
        menu: [],
        operatoreAccessoDittaList: [],
        codTipoOperatore: '',
        user: {},
        operatori: [],
        idStudio: 0,
    }),
    getters: {
        operatoreAttivoStr() {
            const operatore = this.operatori.find(operatore => operatore.attivo)
            return operatore ? `${operatore?.codice} - ${operatore?.userName}` : ''
        },
        getIdStudio() {
            return this.idStudio
        },
    },
    actions: {
        getPagePermissions() {
            const data = JSON.parse(JSON.stringify(this.pagePermissions))
            return data
        },
        findPagePermission(codPermesso) {
            return this.pagePermissions.findIndex(el => {
                return el.codPermesso == codPermesso
            })
        },
        getPermissionTypeByIndex(index) {
            return this.pagePermissions[index].codTipoPermesso
        },
        getPermissionType(codPermesso) {
            if (codPermesso == 'ALL') {
                return 'A'
            }
            if (Array.isArray(codPermesso)) {
                /** per ora solo tabelle - aliquote
                 * in caso codPermesso e' un array torno un oggetto del tipo
                 * {
                 *  codPermesso1: valore
                 *  codPermesso2: valore
                 * }
                 **/
                const permessi = codPermesso.reduce((a, b) => {
                    return (a[b] = this.getPermissionTypeByIndex(this.findPagePermission(b))), a
                }, {})

                return permessi
            }
            const index = this.findPagePermission(codPermesso)
            return this.getPermissionTypeByIndex(index)
        },
        getCodiceDittaList() {
            let listaDitta = this.operatoreAccessoDittaList.filter(el => {
                return el.isEscludi
            })
            let listaCodici = listaDitta.map(el => el.codDitta)

            return listaCodici
        },

        async getProfile() {
            const globalStore = useGlobalStore()
            const self = this
            let response = await APIAuth.get()
            if (response.status === 406) {
                const endpoint =
                    location.origin === import.meta.env.VITE_LOGIN_ENDPOINT
                        ? import.meta.env.VITE_LOGIN_ENDPOINT_MP
                        : import.meta.env.VITE_LOGIN_ENDPOINT
                if (endpoint) location.replace(endpoint)
            } else {
                if (response.data.responseStatus.isSuccessful) {
                    self.getProfilePermissions(response.data.payload)
                    globalStore.state.codOperatore = response.data.payload.codiceOperatore
                    globalStore.state.codService = response.data.payload.codDistributore
                    globalStore.state.codTeamAzienda = response.data.payload.codTeamAzienda
                    globalStore.state.codStudio =
                        response.data.payload.codiceDistributoreStudio.slice(3)
                    if (self.codTipoOperatore === 'A' || self.codTipoOperatore === 'E') {
                        await setDataDb(['VCodDes'])
                    } else {
                        await setDataDb()
                    }
                    this.operatori.push({
                        attivo: true,
                        codice: response.data.payload.codiceOperatore,
                        userName: response.data.payload.userName,
                        id: response.data.payload.idOperatore,
                    })
                    return true
                } else {
                    response.data.data.forEach(operatore => {
                        this.operatori.push({
                            attivo: false,
                            codice: operatore.codice,
                            userName: operatore.userName,
                        })
                    })
                    const operatore = await createPromiseDialog(SceltaOperatore)()
                    if (operatore) {
                        return await this.getProfile()
                    }
                }
            }
            return false
        },

        getProfilePermissions(permissions) {
            const pagePermissions = el => {
                return {
                    codPermesso: el.codPermesso,
                    codTipoPermesso: el.codTipoPermesso,
                }
            }
            this.pagePermissions =
                permissions.profiloPermesso.profiloPermessoElementoList.map(pagePermissions)
            this.menu = permissions.voci.filter(el => {
                return el.codAutoMenu == 'A' || el.codAutoMenu == 'D'
            })
            this.operatoreAccessoDittaList = permissions.operatoreAccessoDittaList
            this.codTipoOperatore = permissions.codTipoOperatore
            this.getCodiceDittaList()
            this.idStudio = permissions.idStudio
        },

        getOperatore() {
            const codice = this.operatori.find(item => item.attivo).codice
            const operatore = this.operatori.find(item => {
                return item.codice == codice
            }) //?.operatore
            return operatore
        },
    },
    persist: true,
})
