import { ProfiliPermessiLista } from '@/views/Paghe/Admin/ProfiloPermessi/Lista'
import { profiliPermessiDettaglio, Childs } from '@/views/Paghe/Admin/ProfiloPermessi/Dettaglio'

const tabProsDef: TabProps = {
    GROUP: 'profiliPermessi',
    ICON: 'fa-regular fa-user-shield',
    TITLE: 'Profili permessi',
    CODVOCEMENU: '900006',
}

export const ROUTES_PROFILI_PERMESSI = Object.freeze({
    LISTA: {
        // TITLE: 'Elenco profili menù',
        NAME: 'ProfiliPermessiLista',
        PATH: 'profiliPermessi',
        TAB: { ...tabProsDef },
    },
    DETTAGLIO: {
        NAME: 'ProfiliPermessiDettaglio',
        PATH: 'profiliPermessi/:id/:mod',
    },
    GENERALE: {
        LABEL: 'Profilo',
        NAME: 'ProfiloPermessi',
        PATH: 'profilo',
        TAB: { ...tabProsDef, TITLE: 'Definizione profilo' },
    },
})

export default [
    {
        path: ROUTES_PROFILI_PERMESSI.LISTA.PATH,
        name: ROUTES_PROFILI_PERMESSI.LISTA.NAME,
        component: ProfiliPermessiLista,
        meta: {
            title: ROUTES_PROFILI_PERMESSI.LISTA.TITLE,
            permissions: {
                codPermesso: 'AMMINISTRAZIONE',
                codVoceMenu: tabProsDef.CODVOCEMENU,
            },
            tabGroup: tabProsDef.GROUP,
        },
    },
    {
        path: ROUTES_PROFILI_PERMESSI.DETTAGLIO.PATH,
        name: ROUTES_PROFILI_PERMESSI.DETTAGLIO.NAME,
        props: true,
        component: profiliPermessiDettaglio,
        meta: {
            title: ROUTES_PROFILI_PERMESSI.DETTAGLIO.TITLE,
            permissions: {
                codPermesso: 'AMMINISTRAZIONE',
                codVoceMenu: null,
            },
            tabGroup: tabProsDef.GROUP,
        },
        children: [
            {
                label: ROUTES_PROFILI_PERMESSI.GENERALE.LABEL,
                path: ROUTES_PROFILI_PERMESSI.GENERALE.PATH,
                name: ROUTES_PROFILI_PERMESSI.GENERALE.NAME,
                tab: ROUTES_PROFILI_PERMESSI.GENERALE.TAB,
                component: Childs.ProfiloPermessi,
                meta: {
                    group: ROUTES_PROFILI_PERMESSI.DETTAGLIO.NAME,
                    permissions: {
                        codPermesso: 'AMMINISTRAZIONE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabProsDef.GROUP,
                },
                orderWizard: 0,
                props: true,
            },
        ],
    },
]
