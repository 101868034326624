import * as childs from './childs'
const dettaglio = () => import('./Dettaglio.vue')
const ParametriModal = () => import('./ParametriModal.vue')
const RightHeader = () => import('./RightHeader.vue')

export type BaseProps = {
    year: string
    month: string
    day?: string
    attualeCheck: string | number
    allValidate: string | number
    codTable?: string | null
}

export const baseProps: BaseProps = {
    year: '',
    month: '',
    day: '1',
    attualeCheck: 0,
    allValidate: 0,
    codTable: '',
}
export default { childs, dettaglio, ParametriModal, RightHeader }
