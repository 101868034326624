import { defineStore } from 'pinia'
import {
    ref,
    reactive,
    Ref,
    shallowRef,
    computed,
    shallowReactive,
    ShallowReactive,
    ComputedRef,
} from 'vue'
import { pagheAPI } from '@/api'
import { pagheRoutes } from '@/routes'
import type { NoteProps } from '@/components/Note.vue'

interface statusDettaglioState {
    data: Partial<DittaResponse> | null
    props: DitteProps | null
    routeList: any
}
interface statusDettaglioDataChild {
    name: string | null
    origin: object | null
    extraData: object | null
    codMultiCc: string
}
interface statusDettaglioStore {
    activeChildName: Ref<string | null>
    dataChildFilled: ComputedRef<boolean>
    dataChild: ShallowReactive<statusDettaglioDataChild>
    dataLookup: ShallowReactive<Map<string, any>>
    getData: (props: DitteProps) => Promise<void>

    getPeriodo: ComputedRef<{
        periodoDiRiferimento: {
            year: number | null
            month: number | null
        }
    }>
    getTitle: ComputedRef<(string | null | undefined)[]>

    isUpNote: Ref<boolean>
    localTab: Ref<number>
    noteContext: ComputedRef<NoteProps>
    resetActiveChildName: () => void
    resetPageData: (resetLookup: boolean, navigation: boolean) => void
    resetBaseData: (resetLookup: boolean, navigation: boolean) => void
    state: statusDettaglioState
}
export const storeName: string = 'statusDettaglioStore'

const useStatusDettaglioStore = defineStore(
    storeName,
    (): statusDettaglioStore => {
        const activeChildName: Ref<string | null> = shallowRef(null) //Nome del dettaglio attivo
        const dataLookup = reactive(new Map()) //Dati delle lookup (ref poiche con shallowRef non aggiorna le lookup)
        const isUpNote = ref(false)

        const state: statusDettaglioState = reactive({
            data: null,
            props: null,
            routeList: pagheRoutes.anagrafiche.status.SETTINGS.STATUS_LISTA,
        })

        //#region Dati Griglia
        const localTab = ref(0) //Indica il subTab attivo nella pagina
        //#endregion

        const dataChild: ShallowReactive<statusDettaglioDataChild> = shallowReactive({
            name: null, //Nome del dettaglio a cui appartengono i dati in dataChild
            origin: null, //Dati da ripristinare al cambio TAB
            extraData: null,
            codMultiCc: 'TOTALE', //per tutte le pagine dei risultati
        })

        //#region GETTERs
        const getPeriodo = computed(() => {
            return {
                periodoDiRiferimento: {
                    year: state.props!.year as number,
                    month: state.props!.month as number,
                },
            }
        })

        const dataChildFilled = computed(() => {
            return !!dataChild.origin && dataChild.name === activeChildName.value
        })

        const ragSociale = computed(
            () =>
                state.data?.dittaUPRagioneSociale?.denominazioneAggiuntiva ||
                state.data?.dittaUPRagioneSociale?.ragioneSociale ||
                '',
        )

        const noteContext: ComputedRef<NoteProps> = computed(() => ({
            entityId: state.data!.id as number,
            entityType: 'DITTA',
            isNote: isUpNote,
            title: `ditta: ${state.data!?.codiceDitta} - ${ragSociale.value}`,
            vCodDes: 'TAG-ALERT-DITTA',
        }))

        //#endregion

        //#region ACTIONs - FUNZIONI

        async function getData(props: any) {
            const params: DittaPayload = {
                requireHasFutureValidity: false,
                orderByKey: true,
                periodoDiRiferimento: { year: Number(props.year), month: Number(props.month) },
                filter: `['id', '=', '${props.id}']`,
                select: JSON.stringify([
                    'id',
                    'codiceDitta',
                    'unitaProduttiva',
                    'dittaUPRagioneSociale.ragioneSociale',
                    'dittaUPRagioneSociale.denominazioneAggiuntiva',
                    'containsDitteUPNoteCalc',
                ]),
            }

            try {
                const response = await pagheAPI.anagrafiche.ditte.ditta.get(params)
                const data = response?.data.data ?? []
                const responseStatus = response?.data.responseStatus
                if (responseStatus?.isSuccessful) {
                    state.data = data[0]
                    isUpNote.value = !!state.data?.containsDitteUPNoteCalc
                }
            } catch (err) {
                console.error('Errore durante la chiamata: ', err)
            }
        }
        const getTitle = computed(() => {
            return [
                state?.data?.codiceDitta,
                state?.data?.unitaProduttiva,
                state?.data?.dittaUPRagioneSociale?.ragioneSociale,
                state?.data?.dittaUPRagioneSociale?.denominazioneAggiuntiva,
            ]
        })

        function resetActiveChildName() {
            //TODO: mettere nome prima pagina del elenco
            activeChildName.value = pagheRoutes.anagrafiche.status.SETTINGS.STATUS.NAME
        }

        function resetBaseData(
            resetLookup: boolean = true,
            oNavigation: boolean = false,
            vNavigation: boolean = false,
        ) {
            dataChild.name = null
            dataChild.origin = null
            if (resetLookup) {
                dataLookup.clear()
            }
            if (!oNavigation) {
                //operazioni da fare solo se non si sta navigando
                localTab.value = 0
                dataChild.extraData = null //solo se non sto navigando
            }
            if (!vNavigation) {
                //DIVERSO DIPTE, IN TUTTI I CASI TRANNE IN VERTICALE
                dataChild.codMultiCc = 'TOTALE'
            }
        }

        function resetPageData(
            resetLookup: boolean = true,
            oNavigation: boolean = false,
            vNavigation: boolean = false,
        ) {
            resetBaseData(resetLookup, oNavigation, vNavigation)
        }

        return {
            activeChildName,
            dataChildFilled,
            dataChild,
            dataLookup,
            getData,
            getPeriodo,
            getTitle,
            isUpNote,
            localTab,
            noteContext,
            resetActiveChildName,
            resetBaseData,
            resetPageData,
            state,
        }
    },
    {
        persist: false,
    },
)

export default useStatusDettaglioStore
