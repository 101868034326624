import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

const BASE_URL_UP_GESTIONE = '/DittaUPGestione'

class API {
    @call({ url: `${BASE_URL_UP_GESTIONE}/GetDitteUPGestione` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DittaUPGestione>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_UP_GESTIONE}/CreateDittaUPGestione` })
    static async save(
        payload: CreateDittaUPGestione,
    ): Promise<AxiosResponse<BasicResponsePayload<DittaUPGestione & DiarioLog>> | null> {
        return null
    }
    @call({ url: `${BASE_URL_UP_GESTIONE}/UpdateDittaUPGestione`, method: 'put' })
    static async update(
        payload: UpdateDittaUpGeStione,
    ): Promise<AxiosResponse<BasicResponsePayload<DittaUPGestione & DiarioLog>> | null> {
        return null
    }
    @call({ url: `${BASE_URL_UP_GESTIONE}/DeleteDittaUPGestione`, method: 'delete' })
    static async delete(
        payload: BaseDeletePayload,
    ): Promise<AxiosResponse<BaseDeleteResponse> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
    update: API.update,
    delete: API.delete,
}
