import { http } from '@/scripts/lib/http'

interface PeriodoElaborazioneParams {
    requireTotalCount: boolean
    isCountQuery: boolean
}

const endpoints: string = '/PeriodoElaborazione/GetPeriodoElaborazione'
const payload: PeriodoElaborazioneParams = {
    requireTotalCount: true,
    isCountQuery: false,
}

export default class APIPeriodoElaborazione {
    static async get() {
        return http.post(endpoints, payload)
    }
}
