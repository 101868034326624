<template>
    <v-container
        class="pa-0"
        fluid
    >
        <v-dialog
            class="info-modal-dialog"
            fullscreen
            :model-value="show"
            :scrim="false"
            transition="dialog-bottom-transition"
            @update:model-value="(value: boolean) => emit('update', value)"
        >
            <v-card>
                <v-toolbar
                    dark
                    color="dark"
                >
                    <v-toolbar-title>COMANDI RAPIDI DA TASTIERA</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                            icon="fa:fas fa-xmark"
                            color="dark"
                            aria-label="Chiudi la finestra modale"
                            @click="emit('update', false)"
                        ></v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <main class="container">
                    <h1 class="sr-only">Elenco comandi rapidi da tastiera</h1>
                    <div class="row">
                        <div
                            v-for="(group, idx1) in shortKeys"
                            :key="idx1"
                            class="col-12 col-lg-4"
                        >
                            <v-list
                                lines="two"
                                subheader
                            >
                                <v-list-subheader>
                                    <h3>{{ group.header }}</h3>
                                </v-list-subheader>
                                <div
                                    v-for="(item, idx2) in group.items"
                                    :key="idx2"
                                >
                                    <v-divider
                                        v-if="item.divider"
                                        class="my-4"
                                    />
                                    <v-list-subheader v-if="typeof item.divider === 'string'">
                                        <h3>{{ item.divider }}</h3>
                                    </v-list-subheader>
                                    <v-list-item
                                        :class="`fw-bold ${item.class}`"
                                        :subtitle="item.subtitle"
                                        :title="item.title"
                                        :value="item.title"
                                    >
                                        <span
                                            v-for="(key, idx3) in item.keys"
                                            :key="idx3"
                                        >
                                            <span
                                                v-if="key.startsWith('$')"
                                                class="mx-3"
                                            >
                                                {{ key.slice(1) }}
                                            </span>
                                            <template v-else>
                                                <span
                                                    v-if="
                                                        idx3 &&
                                                        !item.keys.at(idx3 - 1)?.startsWith('$')
                                                    "
                                                >
                                                    +
                                                </span>
                                                <kbd>{{ key }}</kbd>
                                            </template>
                                        </span>
                                    </v-list-item>
                                </div>
                            </v-list>
                        </div>
                    </div>
                </main>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script setup lang="ts">
export interface IInfoHotKeysProps {
    show: boolean
}

withDefaults(defineProps<IInfoHotKeysProps>(), {
    show: false,
})

const emit = defineEmits(['update'])

const shortKeys = [
    {
        header: 'CTRL+... / CTRL+ALT+...',
        items: [
            {
                class: 'keyCtrlAltLeft',
                keys: ['CTRL', 'ALT', 'ARROWLEFT'],
                title: 'CTRL+ALT+ARROWLEFT',
                subtitle:
                    'Per scorrere agilmente i tab di navigazione principale da destra a sinistra',
            },
            {
                class: 'keyCtrlAltRight',
                keys: ['CTRL', 'ALT', 'ARROWRIGHT'],
                subtitle:
                    'Per scorrere agilmente i tab di navigazione principale da sinistra a destra',
                title: 'CTRL+ALT+ARROWRIGHT',
            },
            {
                class: 'keyCtrlAltNum',
                keys: ['CTRL', 'ALT', 'K'],
                subtitle: 'Per sbloccare le date di validità nelle tebelle di dominio',
                title: 'CTRL+ALT+K',
            },
            {
                class: 'keyCtrlAltNum',
                keys: ['CTRL', 'ALT', 'I'],
                subtitle: 'Per mostrare il codice di riferimento della lookup VCodDes',
                title: 'CTRL+ALT+I',
            },
            {
                class: 'keyCtrlAltNum',
                keys: ['CTRL', 'ALT', '1', '$...', 'CTRL', 'ALT', '7'],
                subtitle: 'Attiva un singolo tab dalla posizione 1 alla posizione 7',
                title: 'CTRL+ALT+1 ... CTRL+ALT+7',
            },
            {
                class: 'keyCtrlLeft',
                divider: true,
                keys: ['CTRL', 'ARROWLEFT'],
                subtitle: 'Per scorrere agilmente i tab di pagina da destra a sinistra',
                title: 'CTRL+ARROWLEFT',
            },
            {
                class: 'keyCtrlRight',
                keys: ['CTRL', 'ARROWRIGHT'],
                subtitle: 'Per scorrere agilmente i tab di pagina da destra a sinistra',
                title: 'CTRL+ARROWRIGHT',
            },
            {
                class: 'keyCtrlSave',
                keys: ['CTRL', 'S'],
                divider: true,
                subtitle: 'Salva le modifiche apportate alla pagina',
                title: 'CTRL+S',
            },
            {
                class: 'keyCtrlI',
                keys: ['CTRL', 'I'],
                subtitle: 'Apri pagina con elenco dei comandi rapidi da tastiera',
                title: 'CTRL+I',
            },
        ],
    },
    {
        header: 'ALT+...',
        items: [
            {
                class: 'keyAltX',
                keys: ['ALT', 'X'],
                title: 'ALT+X',
                subtitle: 'Chiudi il tab di navigazione attivo a la relativa pagina',
            },
            {
                class: 'keyAltA',
                keys: ['Alt', 'A'],
                title: 'Alt+A',
                subtitle: 'Salva le modifiche apportate e chiudi la pagina',
            },
            {
                class: 'keyAltQ',
                keys: ['Alt', 'Q'],
                title: 'Alt+Q',
                subtitle: 'Torna alla pagina di elenco senza salvare',
            },
            {
                class: 'keyAltN',
                keys: ['Alt', 'N'],
                title: 'Alt+N',
                subtitle: 'Carica elemento successivo',
            },
            {
                class: 'keyAltP',
                keys: ['ALT', 'P'],
                title: 'ALT+P',
                subtitle: 'Carica elemento precedente',
            },
        ],
    },
    {
        header: 'F...',
        items: [
            {
                class: 'keyCtrlAltLeft',
                keys: ['F7'],
                title: 'F7',
                subtitle: 'Sidebar di sinistra: vai alla sub.funzione precedente',
            },
            {
                class: 'keyCtrlAltRight',
                keys: ['F8'],
                title: 'F8',
                subtitle: 'Sidebar di sinistra: vai alla sub.funzione successiva',
            },
            {
                class: 'keyEsc',
                divider: 'ESC',
                keys: ['ESC'],
                title: 'ESC',
                subtitle: 'Chiudi finestra modali e overlay(bottoni)',
            },
        ],
    },
]
</script>

<style lang="scss">
.info-modal-dialog {
    .v-list {
        h3 {
            margin-bottom: 1.5rem;
        }

        .v-list-item-title {
            font-weight: 600 !important;

            &::before {
                font-family: Fontawesome;
                content: '\f11c';
                margin-right: 1rem;
                color: var(--bg-sidebar-item-active-border);
            }
        }
    }
}
</style>
