import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'
import { BASE_URL_DITTA } from './ditta'

const BASE_URL_APPALTO = '/DittaAppalto'

class API {
    @call({ url: `${BASE_URL_APPALTO}/GetDitteAppalto` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DittaAppalto>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DITTA}/SaveDittaAppalto` })
    static async save(
        payload: SaveDittaAppaltoPayload,
    ): Promise<AxiosResponse<BasicDittaResponsePayload<DittaAppalto>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
