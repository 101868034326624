import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'
/* import { BASE_URL_DITTA } from './ditta' */

const BASE_URL_730 = '/Dipte730'

class API {
    @call({ url: `${BASE_URL_730}/GetDipendenti730` })
    static async get(payload: Payload): Promise<AxiosResponse<BasicResponseData<Dipte730>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_730}/UpdateDipte730` })
    static async save(
        payload: saveDipte730Payload,
    ): Promise<AxiosResponse<BasicResponseData<Dipte730>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_730}/CreateDipte730` })
    static async create(
        payload: saveDipte730Payload,
    ): Promise<AxiosResponse<BasicResponseData<Dipte730>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
    create: API.create,
}
