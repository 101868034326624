import { http } from '@/scripts/lib/http'
import { useGlobalStore } from '@/stores'

const BASE_URL = '/RaccordoPaginaHelp'

const httpMethods = new Map([
    ['Create', http.post],
    ['Get', http.post],
    ['Update', http.put],
])

function getPayload(payload: HelpPayload): HelpPayload {
    const globalStore = useGlobalStore()
    const _payload = { isNuovaValidita: true, ...payload }
    if (_payload.id) _payload.periodoDiRiferimento = { ...globalStore.state.periodoElab }
    return {
        isNuovaValidita: true,
        ...payload,
    }
}

function call(urlPrefix: string) {
    return function (target: any, key: string, descriptor: PropertyDescriptor) {
        descriptor.value = async (payload: Payload = {}) => {
            try {
                const _http = httpMethods.get(urlPrefix)
                return _http!(`${BASE_URL}/${urlPrefix}RaccordoPaginaHelp`, getPayload(payload))
            } catch (error) {
                console.log(error)
            }
        }
        return descriptor
    }
}

export class APIHelp {
    @call('Create')
    static add(payload: HelpPayload): any {}

    @call('Get')
    static get(payload: HelpPayload): any {}

    @call('Update')
    static save(payload: HelpPayload): any {}
}
